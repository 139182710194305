unit UCheckSichFrage;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  WEBLib.StdCtrls,
  XData.Web.Client,
  WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.ExtCtrls;

type
  TCheckSichFrage = class(TForm)
    WebLabel4: TLabel;
    WebPanel1: TPanel;
    Check_WebLabel_10: TLabel;
    WebEdit2: TEdit;
    WebMemo1: TMemo;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    AktFNo : integer;
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  end;

var
  CheckSichFrage: TCheckSichFrage;
  SichFragen_Modus : integer;
  Neues_Konto_Erstellt : boolean;



implementation







procedure TCheckSichFrage.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TCheckSichFrage.WebButton2Click(Sender: TObject);
var hst,hst2,NLK : String;
begin

{  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Antwort muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;
}

  NLK := IntToStr(AktFirmaNo);

  DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.RequestEMailPW', [NLK,AktKDName, IntToStr(AktFNo),hst],
      procedure(Response: TXDataClientResponse)
      var hst : String;
      begin
        hst := JS.ToString(JS.ToObject(Response.Result)['value']);
        if hst = '0' then begin
          MessageDlg('Email wurde verschickt, prüfen Sie Ihre Mails (ggf. auch Spam-Ordner)', mtInformation, [], nil);
          AktKDName := '';
          Close;
        end
        else if hst = '1' then begin
          MessageDlg('Falsche Antwort', mtError, [], nil);
          WebEdit2.SetFocus;
          exit;
        end
        else if hst = '2' then begin
          MessageDlg('Probleme beim EMail-Versand', mtError, [], nil);
          WebEdit2.SetFocus;
          exit;
        end;
      end,

      procedure(Error: TXDataClientError)
      begin
        MessageDlg('Keine Verbindung zum Server, prüfen Sie Ihre Internet-Verbindung', mtError, [], nil);
      end
     );


end;

procedure TCheckSichFrage.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TCheckSichFrage.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure TCheckSichFrage.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TCheckSichFrage.WebFormShow(Sender: TObject);
var i,j,j2 : integer;
    hst,hst2 : String;
begin
//  Resize_Top_Panel_2(self,WebPanel1,'Bitte beantworten Sie die Sicherheitsfrage');
  Resize_Top_Panel_2(self,WebPanel1,'Kennwort vergessen');

  Check_WebLabel_10.Caption := 'Klicken Sie auf Email-Anfordern';


  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebMemo1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,Check_WebLabel_10,WebEdit2,nil);
  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

  WebLabel4.Visible := false;
  WebMemo1.Visible := false;
  WebEdit2.Visible := false;


  hst := Akt_SFrage;
  i := pos(';',hst);
  asm debugger; end;
  if i > 0 then begin
    hst2 := Copy(hst,1,i-1);
    system.Delete(hst,1,i);
    if Copy(hst2,1,1) = '$' then begin
      system.Delete(hst2,1,1);
      j := StrToInt(hst2);
      AktFNo := j;
      if hst <> '' then begin
        j := StrToInt(hst);
      end;
      hst := SFA[j];
    end
    else begin
      AktFNo := 3;
      hst := hst2;
    end;
  end
  else begin
  end;
  WebMemo1.Text := hst;
  WebEdit2.Text := '';

end;

procedure TCheckSichFrage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel4 := TLabel.Create(Self);
  Check_WebLabel_10 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel4.BeforeLoadDFMValues;
  Check_WebLabel_10.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'CheckSichFrage';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 116;
    WebLabel4.Width := 93;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Sicherheitsfrage';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    Check_WebLabel_10.SetParentComponent(Self);
    Check_WebLabel_10.Name := 'Check_WebLabel_10';
    Check_WebLabel_10.Left := 8;
    Check_WebLabel_10.Top := 246;
    Check_WebLabel_10.Width := 44;
    Check_WebLabel_10.Height := 16;
    Check_WebLabel_10.Caption := 'Antwort';
    Check_WebLabel_10.Color := clBtnFace;
    Check_WebLabel_10.Font.Charset := DEFAULT_CHARSET;
    Check_WebLabel_10.Font.Color := clWindowText;
    Check_WebLabel_10.Font.Height := -13;
    Check_WebLabel_10.Font.Name := 'Arial';
    Check_WebLabel_10.Font.Style := [];
    Check_WebLabel_10.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 265;
    WebEdit2.Width := 269;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 16;
    WebEdit2.Text := 'WebEdit2';
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 8;
    WebMemo1.Top := 138;
    WebMemo1.Width := 312;
    WebMemo1.Height := 95;
    WebMemo1.TabStop := False;
    WebMemo1.AutoSize := False;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 5;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 0;
    WebButton2.Top := 19;
    WebButton2.Width := 234;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Email  anfordern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 7;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 240;
    WebButton1.Top := 19;
    WebButton1.Width := 80;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.TabOrder := 8;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel4.AfterLoadDFMValues;
    Check_WebLabel_10.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.