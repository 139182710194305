unit UEditKonto4;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  WEBLib.StdCtrls,
  XData.Web.Client,
  WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.ExtCtrls;

type
  TEditKonto4 = class(TForm)
    WebLabel3: TLabel;
    WebLabel2: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebDBEdit1: TDBEdit;
    WebLabel11: TLabel;
    WebDBComboBox1: TDBComboBox;
    WebDBEdit2: TDBEdit;
    WebDBEdit3: TDBEdit;
    WebDBEdit4: TDBEdit;
    WebDBEdit5: TDBEdit;
    WebDBEdit6: TDBEdit;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    PushC_Liste : TStringList;

  end;

var
  EditKonto4: TEditKonto4;



procedure Do_Edit_Konto4;


implementation



procedure Do_Edit_Konto4;
var
  newform: TEditKonto4;
begin
//  if Create_Form(TEditKonto4,NewForm) then ;


  newform := TEditKonto4.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TEditKonto4).Caption := 'Konto bearbeiten';
    end
  );
//  newForm.Caption := 'Neues Konto';
  newform.Border := fbSingle;

  newform.ShowModal(procedure(AValue: TModalResult)
  begin
//
  end
  );




end;





procedure TEditKonto4.WebButton1Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  DataModule1.KundenDataSet.Cancel;
  Close;
end;

procedure TEditKonto4.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  if WebDBCombobox1.ItemIndex = -1 then begin
    MessageDlg('Anrede muß erfasst werden', mtError, [], nil);
    WebDBCombobox1.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit2.Text);
  WebDBEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Name muß erfasst werden', mtError, [], nil);
    WebDBEdit2.SetFocus;
    exit;
  end;
  hst := Trim(WebDBEdit3.Text);
  WebDBEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('Vorname muß erfasst werden', mtError, [], nil);
    WebDBEdit3.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit4.Text);
  WebDBEdit4.Text := hst;
  if hst = '' then begin
    MessageDlg('Strasse muß erfasst werden', mtError, [], nil);
    WebDBEdit4.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit5.Text);
  WebDBEdit5.Text := hst;
  if hst = '' then begin
    MessageDlg('PLZ muß erfasst werden', mtError, [], nil);
    WebDBEdit5.SetFocus;
    exit;
  end;

  hst := Trim(WebDBEdit6.Text);
  WebDBEdit6.Text := hst;
  if hst = '' then begin
    MessageDlg('Ort muß erfasst werden', mtError, [], nil);
    WebDBEdit6.SetFocus;
    exit;
  end;
  Push_Controls(self,PushC_Liste);

  try
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    Pop_Controls(self,PushC_Liste);
    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;



end;

procedure TEditKonto4.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;

end;

procedure TEditKonto4.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  PushC_Liste := TStringList.Create;

end;

procedure TEditKonto4.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEditKonto4.WebFormShow(Sender: TObject);
begin
  DataModule1.KundenDataSet.Edit;
  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel3,WebDBComboBox1,nil);
  Zeilen_Liste_Set_2(Zeilen_Liste,WebLabel3,WebLabel11,WebDBComboBox1,WebDBEdit1);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebDBEdit3,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebDBEdit2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel5,WebDBEdit4,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel6,WebDBEdit5,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel7,WebDBEdit6,nil);
  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);


end;

procedure TEditKonto4.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel3 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebDBEdit1 := TDBEdit.Create(Self);
  WebDBComboBox1 := TDBComboBox.Create(Self);
  WebDBEdit2 := TDBEdit.Create(Self);
  WebDBEdit3 := TDBEdit.Create(Self);
  WebDBEdit4 := TDBEdit.Create(Self);
  WebDBEdit5 := TDBEdit.Create(Self);
  WebDBEdit6 := TDBEdit.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebDBEdit1.BeforeLoadDFMValues;
  WebDBComboBox1.BeforeLoadDFMValues;
  WebDBEdit2.BeforeLoadDFMValues;
  WebDBEdit3.BeforeLoadDFMValues;
  WebDBEdit4.BeforeLoadDFMValues;
  WebDBEdit5.BeforeLoadDFMValues;
  WebDBEdit6.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'EditKonto4';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 95;
    WebLabel3.Width := 41;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Anrede';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 194;
    WebLabel2.Width := 62;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Nachname';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 144;
    WebLabel4.Width := 51;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Vorname';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 8;
    WebLabel5.Top := 241;
    WebLabel5.Width := 151;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Strasse und Hausnummer';
    WebLabel5.Color := clBtnFace;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 8;
    WebLabel6.Top := 291;
    WebLabel6.Width := 68;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Postleitzahl';
    WebLabel6.Color := clBtnFace;
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -13;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [];
    WebLabel6.ParentFont := False;
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 8;
    WebLabel7.Top := 339;
    WebLabel7.Width := 18;
    WebLabel7.Height := 16;
    WebLabel7.Caption := 'Ort';
    WebLabel7.Color := clBtnFace;
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -13;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [];
    WebLabel7.ParentFont := False;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 120;
    WebLabel11.Top := 95;
    WebLabel11.Width := 24;
    WebLabel11.Height := 16;
    WebLabel11.Caption := 'Titel';
    WebLabel11.Color := clBtnFace;
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -13;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Style := [];
    WebLabel11.ParentFont := False;
    WebDBEdit1.SetParentComponent(Self);
    WebDBEdit1.Name := 'WebDBEdit1';
    WebDBEdit1.Left := 120;
    WebDBEdit1.Top := 117;
    WebDBEdit1.Width := 136;
    WebDBEdit1.Height := 22;
    WebDBEdit1.ChildOrder := 28;
    WebDBEdit1.ElementClassName := 'form-control';
    WebDBEdit1.TabOrder := 1;
    WebDBEdit1.Text := 'WebDBEdit1';
    WebDBEdit1.DataField := 'AKTITEL';
    WebDBEdit1.DataSource := DataModule1.KundenDataSource;
    WebDBComboBox1.SetParentComponent(Self);
    WebDBComboBox1.Name := 'WebDBComboBox1';
    WebDBComboBox1.Left := 8;
    WebDBComboBox1.Top := 117;
    WebDBComboBox1.Width := 80;
    WebDBComboBox1.Height := 22;
    WebDBComboBox1.ItemIndex := -1;
    WebDBComboBox1.Items.BeginUpdate;
    try
      WebDBComboBox1.Items.Clear;
      WebDBComboBox1.Items.Add('Herr');
      WebDBComboBox1.Items.Add('Frau');
    finally
      WebDBComboBox1.Items.EndUpdate;
    end;
    WebDBComboBox1.DataField := 'ANREDE';
    WebDBComboBox1.DataSource := DataModule1.KundenDataSource;
    WebDBEdit2.SetParentComponent(Self);
    WebDBEdit2.Name := 'WebDBEdit2';
    WebDBEdit2.Left := 8;
    WebDBEdit2.Top := 213;
    WebDBEdit2.Width := 248;
    WebDBEdit2.Height := 22;
    WebDBEdit2.ChildOrder := 28;
    WebDBEdit2.ElementClassName := 'form-control';
    WebDBEdit2.TabOrder := 3;
    WebDBEdit2.Text := 'WebDBEdit1';
    WebDBEdit2.DataField := 'NAME';
    WebDBEdit2.DataSource := DataModule1.KundenDataSource;
    WebDBEdit3.SetParentComponent(Self);
    WebDBEdit3.Name := 'WebDBEdit3';
    WebDBEdit3.Left := 8;
    WebDBEdit3.Top := 166;
    WebDBEdit3.Width := 248;
    WebDBEdit3.Height := 22;
    WebDBEdit3.ChildOrder := 28;
    WebDBEdit3.ElementClassName := 'form-control';
    WebDBEdit3.TabOrder := 2;
    WebDBEdit3.Text := 'WebDBEdit1';
    WebDBEdit3.DataField := 'NAME2';
    WebDBEdit3.DataSource := DataModule1.KundenDataSource;
    WebDBEdit4.SetParentComponent(Self);
    WebDBEdit4.Name := 'WebDBEdit4';
    WebDBEdit4.Left := 8;
    WebDBEdit4.Top := 263;
    WebDBEdit4.Width := 248;
    WebDBEdit4.Height := 22;
    WebDBEdit4.ChildOrder := 28;
    WebDBEdit4.ElementClassName := 'form-control';
    WebDBEdit4.TabOrder := 4;
    WebDBEdit4.Text := 'WebDBEdit1';
    WebDBEdit4.DataField := 'STRASSE';
    WebDBEdit4.DataSource := DataModule1.KundenDataSource;
    WebDBEdit5.SetParentComponent(Self);
    WebDBEdit5.Name := 'WebDBEdit5';
    WebDBEdit5.Left := 8;
    WebDBEdit5.Top := 311;
    WebDBEdit5.Width := 248;
    WebDBEdit5.Height := 22;
    WebDBEdit5.ChildOrder := 28;
    WebDBEdit5.ElementClassName := 'form-control';
    WebDBEdit5.TabOrder := 5;
    WebDBEdit5.Text := 'WebDBEdit1';
    WebDBEdit5.DataField := 'PLZ';
    WebDBEdit5.DataSource := DataModule1.KundenDataSource;
    WebDBEdit6.SetParentComponent(Self);
    WebDBEdit6.Name := 'WebDBEdit6';
    WebDBEdit6.Left := 8;
    WebDBEdit6.Top := 361;
    WebDBEdit6.Width := 248;
    WebDBEdit6.Height := 22;
    WebDBEdit6.ChildOrder := 28;
    WebDBEdit6.ElementClassName := 'form-control';
    WebDBEdit6.TabOrder := 6;
    WebDBEdit6.Text := 'WebDBEdit1';
    WebDBEdit6.DataField := 'ORT';
    WebDBEdit6.DataSource := DataModule1.KundenDataSource;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 22;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 19;
    WebButton2.Width := 177;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 7;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 191;
    WebButton1.Top := 19;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.TabOrder := 8;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebDBEdit1.AfterLoadDFMValues;
    WebDBComboBox1.AfterLoadDFMValues;
    WebDBEdit2.AfterLoadDFMValues;
    WebDBEdit3.AfterLoadDFMValues;
    WebDBEdit4.AfterLoadDFMValues;
    WebDBEdit5.AfterLoadDFMValues;
    WebDBEdit6.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.