unit Udatamodule;

interface

uses

  SysUtils, Classes, JS, Web, WEBLib.Forms,WEBLib.Modules, DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection, WEBLib.DB,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Crypto,

  WEBLib.WebTools,
  WEBLib.Dialogs,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  WEBLib.CDS, WEBLib.SQLCDS, XData.Web.Client,
  UGlobvar,
  UEditStrTouchPW,
  UEditStrTouch;

const
  KDDataset_CheckEmail = 1;
  KDDataset_PasswordReset = 2;
  KDDataset_FindKey = 3;

  KEY_FIRST = '{"alg":"A128GCM","ext":true,"k":"CQ4H9zufO9lxBhecfx-aXQ","key_ops":["encrypt","decrypt"],"kty":"oct"}';
  KEY_SECOND = '{"alg":"A128GCM","ext":true,"k":"TC04MT3Ooc-Zx8ZvIMi9nA","key_ops":["encrypt","decrypt"],"kty":"oct"}';
  KEY_THIRD = '{"alg":"A128GCM","ext":true,"k":"g2EBDfZygljOSKGgEmzgYg","key_ops":["encrypt","decrypt"],"kty":"oct"}';





  WF_Edit = 1;
  WF_Menu = 2;
  WF_Login = 3;
  WF_Grid = 4;

type



  TDataModule1 = class(TDataModule)
    XDataWebConnection1: TXDataWebConnection;
    KundenDataSet: TXDataWebDataSet;
    KundenDataSource: TDataSource;
    XDataClient: TXDataWebClient;
    KundenDataSetID: TIntegerField;
    KundenDataSetNL_KEY: TIntegerField;
    KundenDataSetKDNO: TIntegerField;
    KundenDataSetNAME: TStringField;
    KundenDataSetNAME2: TStringField;
    KundenDataSetSTRASSE: TStringField;
    KundenDataSetPLZ: TStringField;
    KundenDataSetORT: TStringField;
    KundenDataSetTELEFON: TStringField;
    KundenDataSetHANDY: TStringField;
    KundenDataSetEMAILADR: TStringField;
    KundenDataSetKENNWORT: TStringField;
    KundenDataSetDATUHR: TDateTimeField;
    KundenDataSetCONFIRMED: TDateTimeField;
    KundenDataSetUPC_EMAIL: TStringField;
    KundenDataSetDATUHR2: TDateTimeField;
    KundenDataSetBESTAETIGUNG: TStringField;
    KundenDataSetAKTIV: TStringField;
    KundenDataSetSTATUS: TIntegerField;
    KundenDataSetANREDE: TStringField;
    KundenDataSetAKTITEL: TStringField;
    KundenDataSetIBAN: TStringField;
    KundenDataSetBIC: TStringField;
    KundenDataSetBANK: TStringField;
    KundenDataSetKZBERECHTIGUNG: TIntegerField;
    TeilnehmerDataSource: TDataSource;
    TeilnehmerDataSet: TXDataWebDataSet;
    TeilnehmerDataSetID: TIntegerField;
    TeilnehmerDataSetNL_KEY: TIntegerField;
    TeilnehmerDataSetKDID: TIntegerField;
    TeilnehmerDataSetLFDNO: TIntegerField;
    TeilnehmerDataSetNAME: TStringField;
    XDataWebClient1: TXDataWebClient;
    TeilnehmerDataSetEINRICHTUNG: TIntegerField;
    TeilnehmerDataSetGESCHLECHT: TStringField;
    TeilnehmerDataSetGEBURTSDATUM: TDateTimeField;
    TeilnehmerDataSetLKZ: TStringField;
    TeilnehmerDataSetLOESCHUNG: TDateTimeField;
    TeilnehmerDataSetPLAN1: TIntegerField;
    TeilnehmerDataSetPLAN2: TIntegerField;
    TeilnehmerDataSetPLAN3: TIntegerField;
    TeilnehmerDataSetPLAN4: TIntegerField;
    TeilnehmerDataSetPLAN5: TIntegerField;
    TeilnehmerDataSetPLAN6: TIntegerField;
    TeilnehmerDataSetPLAN7: TIntegerField;
    TeilnehmerDataSetEINRICHTUNGBEZ: TStringField;
    TeilnehmerDataSetTT: TIntegerField;
    TeilnehmerDataSetMM: TIntegerField;
    TeilnehmerDataSetJJ: TIntegerField;
    TeilnehmerDataSetKZ_BERECHTIGUNG: TIntegerField;
    EinrichtungSource: TDataSource;
    EinrichtungDataSet: TXDataWebDataSet;
    AllergieDataSet: TXDataWebDataSet;
    AllergieDataSetTEILNEHMERID: TIntegerField;
    AllergieDataSetALLERGENID: TIntegerField;
    VerpflegungsartSource: TDataSource;
    VerpflegungsArtDataSet: TXDataWebDataSet;
    VerpflegungsArtDataSetID: TIntegerField;
    VerpflegungsArtDataSetNL_KEY: TIntegerField;
    VerpflegungsArtDataSetEID: TIntegerField;
    VerpflegungsArtDataSetLFDNO: TIntegerField;
    VerpflegungsArtDataSetBEZEICHNUNG: TStringField;
    VerpflegungsArtDataSetREIHENFOLGE: TIntegerField;
    VerpflegungsArtDataSetKUERZEL: TStringField;
    VariantenSource: TDataSource;
    VariantenDataSet: TXDataWebDataSet;
    IntegerField1: TIntegerField;
    IntegerField2: TIntegerField;
    IntegerField3: TIntegerField;
    IntegerField4: TIntegerField;
    StringField1: TStringField;
    IntegerField5: TIntegerField;
    StringField2: TStringField;
    TeilnehmerDataSetVARIANTE: TIntegerField;
    KundenDataSetADMINLEVEL: TIntegerField;
    EinrichtungDataSetID: TIntegerField;
    EinrichtungDataSetNL_KEY: TIntegerField;
    EinrichtungDataSetNUMMER: TIntegerField;
    EinrichtungDataSetBEZEICHNUNG: TStringField;
    XDataWebClient2: TXDataWebClient;
    EinrichtungDataSetEMAIL: TStringField;
    WebClientConnection1: TClientConnection;
    SpeiseplanSource: TDataSource;
    Speiseplandataset: TXDataWebDataSet;
    TeilnehmerDataSetTID: TIntegerField;
    TeilnehmerDataSetCHIPID: TStringField;
    TeilnehmerDataSetAKTIV: TIntegerField;
    KundenDataSetKDGRUPPE: TIntegerField;
    KundenDataSetZAHLBED: TIntegerField;
    KundenDataSetABRECHNUNGSART: TIntegerField;
    KundenDataSetCHIPID: TStringField;
    KundenDataSetPREISLISTE: TIntegerField;
    KundenDataSetKONTOINHABER: TStringField;
    SpeiseplandatasetTID: TIntegerField;
    SpeiseplandatasetDATUM: TDateTimeField;
    SpeiseplandatasetSPTID: TIntegerField;
    SpeiseplandatasetARTIKEL: TIntegerField;
    SpeiseplandatasetBEZEICHNUNG: TStringField;
    SpeiseplandatasetBEZEICHNUNG2: TStringField;
    SpeiseplandatasetKZ_BESTELLT: TIntegerField;
    SpeiseplandatasetBESTELLT: TDateTimeField;
    XDataWebClientAuth: TXDataWebClient;
    XDataWebConnection2: TXDataWebConnection;
    KundenDataSetSF_1: TIntegerField;
    KundenDataSetSF_2: TIntegerField;
    KundenDataSetSF_3: TIntegerField;
    KundenDataSetESF: TStringField;
    KundenDataSetSFA_1: TStringField;
    KundenDataSetSFA_2: TStringField;
    KundenDataSetSFA_3: TStringField;
    SicherheitsfragenDataSet: TXDataWebDataSet;
    SicherheitsfragenDataSetID: TIntegerField;
    SicherheitsfragenDataSetNUMMER: TIntegerField;
    SicherheitsfragenDataSetFRAGE: TStringField;
    XDataWebConnection3: TXDataWebConnection;
    XDataWebClientApiService: TXDataWebClient;
    TeilnehmerDataSetKZ_SUBACCOUNT: TIntegerField;
    TeilnehmerDataSetEMAILSUBACCOUNT: TStringField;
    TeilnehmerDataSetKENNWORT: TStringField;
    TeilnehmerDataSetUPC_NAME: TStringField;
    SpeiseplandatasetAUSGABE: TDateTimeField;
    SpeiseplandatasetKZ_AUSGABE: TIntegerField;
    EInrichtungSummenSource: TDataSource;
    EinrichtungsummenDataSet: TXDataWebDataSet;
    EinrichtungsummenDataSetID: TIntegerField;
    EinrichtungsummenDataSetDATUM: TDateTimeField;
    EinrichtungsummenDataSetARTIKEL: TIntegerField;
    EinrichtungsummenDataSetBEZEICHNUNG: TStringField;
    EinrichtungsummenDataSetBESTELLT: TIntegerField;
    EinrichtungsummenDataSetGELIEFERT: TIntegerField;
    EinrichtungsummenDataSetAUSGABE: TIntegerField;
    SpeiseplandatasetNL_KEY: TIntegerField;
    SpeiseplandatasetEID: TIntegerField;
    SpeiseplandatasetENO: TIntegerField;
    SpeiseplandatasetSTORNO: TDateTimeField;
    SpeiseplandatasetSTORNO_KZ: TIntegerField;
    SpeiseplandatasetDatumD: TStringField;
    SpeiseplandatasetDatumD2: TStringField;
    SpeiseplandatasetStatus: TStringField;
    EinrichtungDataSetTELEFON: TStringField;
    EinrichtungDataSetBESTELL_ENDE: TDateTimeField;
    EinrichtungDataSetBESTELL_ENDE_ART: TIntegerField;
    ArtikelDataSource: TDataSource;
    ArtikelDataSet: TXDataWebDataSet;
    ArtikelDataSetID: TIntegerField;
    ArtikelDataSetNL_KEY: TIntegerField;
    ArtikelDataSetNUMMER: TIntegerField;
    ArtikelDataSetBEZEICHNUNG: TStringField;
    ArtikelDataSetBEZEICHNUNG2: TStringField;
    ArtikelDataSetART: TIntegerField;
    ArtikelDataSetWG: TIntegerField;
    ArtikelDataSetME: TIntegerField;
    ArtikelDataSetKZ_KALK: TIntegerField;
    ArtikelDataSetMWST: TIntegerField;
    ArtikelDataSetBRUTTO: TFloatField;
    ArtikelDataSetNETTO: TFloatField;
    ArtikelDataSetMATPREIS: TFloatField;
    ArtikelDataSetLOHNKOSTEN: TFloatField;
    ArtikelDataSetSONSTIGEKOSTEN: TFloatField;
    ArtikelDataSetKCAL: TFloatField;
    ArtikelDataSetKJOULE: TFloatField;
    ArtikelDataSetEIWEISS: TFloatField;
    ArtikelDataSetKOHLENHYDRATE: TFloatField;
    ArtikelDataSetZUCKER: TFloatField;
    ArtikelDataSetFETT: TFloatField;
    ArtikelDataSetFETTGESAETTIGT: TFloatField;
    ArtikelDataSetSALZ: TFloatField;
    ArtikelDataSetKZ_MINDESTABSTAND: TIntegerField;
    ArtikelDataSetBezGesamt: TStringField;
    BilderDataSet: TXDataWebDataSet;
    BilderDataSource: TDataSource;
    BilderDataSetID: TIntegerField;
    BilderDataSetART: TIntegerField;
    BilderDataSetID2: TIntegerField;
    BilderDataSetLFDNO: TIntegerField;
    BilderDataSetBILD: TBlobField;
    BilderDataSetURL_PFAD: TStringField;
    BilderDataSetDATEITYP: TStringField;
    BilderDataSetBEMERKUNG: TStringField;
    SpeiseplandatasetGRUPPE: TIntegerField;
    SpeiseplandatasetKZ_AUSWAHL: TIntegerField;
    SpeiseplandatasetARTIKELID: TIntegerField;
    Artikel_LTDataSet: TXDataWebDataSet;
    Artikel_LTSource: TDataSource;
    Artikel_LTDataSetID: TIntegerField;
    Artikel_LTDataSetTXT: TBlobField;
    SpeiseplandatasetBEWERTUNG1: TIntegerField;
    SpeiseplandatasetBEWERTUNG2: TIntegerField;
    SpeiseplandatasetBEWERTUNG3: TIntegerField;
    SpeiseplandatasetBEMERKUNG: TStringField;
    SpeiseplandatasetBezGes: TStringField;
    SpeiseplandatasetBildUrl: TStringField;
    MWSTDataSet: TXDataWebDataSet;
    procedure KundenDataSetAfterOpen(DataSet: TDataSet);
    procedure WebDataModuleCreate(Sender: TObject);
    procedure WebClientDataSet2PictureGetText(Sender: TField;var Text: string; DisplayText: Boolean);
    procedure WebClientDataSet2URLGetText(Sender: TField; var Text: string;DisplayText: Boolean);
    procedure XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
    procedure XDataWebConnection3Request(Args: TXDataWebConnectionRequest);
    procedure SpeiseplandatasetCalcFields(DataSet: TDataSet);
    procedure ArtikelDataSetCalcFields(DataSet: TDataSet);
    procedure XDataWebConnection1Error(Error: TXDataWebConnectionError);

    procedure Standard_Edit_Click(Sender: TObject);
    procedure Impressum_Click(Sender: TObject);
    procedure AGB_Click(Sender: TObject);
    procedure DSE_Click(Sender: TObject);
    procedure XDataWebConnection3Error(Error: TXDataWebConnectionError);
    procedure Hilfe_Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;

  public
    { Public-Deklarationen }
    aesGCM: TAESEncryption;
    CD_Ergebnis,
    CD_Ergebnis2 : String;

    procedure CryptoError(AError: string);
    procedure CryptoKeyCreated(Sender: TObject);
    procedure MessageDecrypted(AData: string);
    procedure MessageEncrypted(ADataAsArrayBuffer: TJSArrayBuffer);

  end;




var
  DataModule1: TDataModule1;

  KundenDataSet_Mode : integer;
  LD3_Fertig : boolean;


function Get_Device_Typ : integer;

procedure Resize_Top_Panel(F : TForm;P : TPanel);

procedure Resize_Top_Panel_2(F : TForm;P : TPanel;Header : String);

procedure Resize_Top_Panel_3(F : TForm;P : TPanel;Header,Header2 : String);

procedure Resize_Top_Panel_4(F : TForm;P : TPanel;Header,Header2,Header3 : String);

procedure Resize_Bottom_Panel(F : TForm;P : TPanel);

procedure Resize_Bottom_Panel_2(F : TForm;P : TPanel);


procedure Zeilen_Liste_Add(var TS : TStringList;var Zeilen_Count : integer;LabelC,EditC,ZusatzLabel : TControl);

procedure Zeilen_Liste_Add_Menu(var TS : TStringList;var Zeilen_Count : integer;Btn : TControl);

procedure Zeilen_Liste_Add_Hinweis(var TS : TStringList;var Zeilen_Count : integer;LabelC : TControl);



procedure Zeilen_Liste_Set(var TS : TStringList;EditC1,EditC2 : TControl);

procedure Zeilen_Liste_Set_2(var TS : TStringList;EditC1,EditC2,EditC3,EditC4 : TControl);

procedure Resize_Edit_Controls(Art : integer;F : TForm;TS : TStringList;TopPanel,BottomPanel : TControl);

procedure Resize_Top_Panel_5(F : TForm;P : TImageControl);

procedure Resize_Top_Panel_6(F : TForm;P : TImageControl);



//function Convert_Datum(hst2 : String) : String;

function Convert_Datum_2(D : TDateTime) : String;

function Do_IntToStr(i : integer) : String;

function Do_Trim(st : String) : String;


function AFZ(st : String) : String;

function AFZ2(st : String) : String;

function AFZ_Now(st : String) : String;



function Lade_Daten(var DS : TXDataWebDataSet;AEntity,Qst : String) : boolean;

function Lade_Daten_2(var DS : TXDataWebDataSet;AEntity,Qst,OrderStr : String) : boolean;

function Lade_Daten_3(f : TForm;var TS : TStringList;var DS : TXDataWebDataSet;AEntity,Qst : String;var fst : String) : boolean;


function GetKundebyEmail(Modus : integer;Eadr : String) : boolean;

function Do_Post(DS : TXDataWebDataSet) : integer;


function Do_Find(DS : TXDataWebDataSet;FName,V : String) : boolean;


function Get_AktKDID(NLK : integer;eAdr : String) : integer;

function Get_AktEinrichtungID(NLK : integer;Nummer : integer) : integer;

//function Create_Form(AForm : TWebForm;
//function Create_Form(InstanceClass: TComponentClass; var Reference) : boolean;

procedure GetEditStrTouch(Header,st : String;pw : boolean;c: TControl);

procedure GetEditStrTouchPW(Header,st,st2 : String;c,c2: TControl);


procedure Push_Controls(f : TForm;var TS : TStringList);

procedure Pop_Controls(f : TForm;var TS : TStringList);


function CryptString(s : String) : String;

function DeCryptString(s : String) : String;



implementation

uses UAnzPDF;


{%CLASSGROUP 'Vcl.Controls.TControl'}

{
function Convert_Datum(hst2 : String) : String;
var hst : String;
begin
  hst := Copy(hst2,9,2) + '.' + Copy(hst2,6,2) + '.' + Copy(hst2,1,4);
  Convert_Datum := hst;
end;
}

function Convert_Datum_2(D : TDateTime) : String;
var hst : String;
    JJ,MM,TT : word;
    JJStr,
    MMStr,
    TTStr : String;
    i : integer;
begin
  Decodedate(D,JJ,MM,TT);
  Str(JJ:4,JJStr);
  Str(MM:2,MMStr);
  Str(TT:2,TTStr);
  hst := TTStr + '.' + MMStr+ '.' + JJStr;
  for i := 1 to length(hst) do begin
    if hst[i] = ' '  then hst[i] := '0';
  end;
  Convert_Datum_2 := hst;
end;


function Do_IntToStr(i : integer) : String;
begin
  Do_IntToStr := IntToStr(i);
end;


function Do_Trim(st : String) : String;
var hst : String;
begin
  hst := Trim(st);
  Do_Trim := hst;
end;



function AFZ(st : String) : String;
begin
  AFZ := ''''+st+''''+',';
end;

function AFZ2(st : String) : String;
begin
  AFZ2 := ''''+st+'''';
end;

function AFZ_Now(st : String) : String;
begin
  AFZ_Now := ''''+DateTimeToStr(Now)+''''+',';
end;





function Do_Post(DS : TXDataWebDataSet) : integer;
begin
  Do_Post := 0;
  if DS.State in [dsEdit,dsInsert] then begin
    try
      DS.Post;
      DS.ApplyUpdates;
    except
      Do_Post := -1;
    end;
  end;
end;


function GetKundebyEmail(Modus : integer;Eadr : String) : boolean;
var hst : String;
begin
  GetKundebyEmail := false;
  KundenDataSet_Mode := Modus;



  Datamodule1.XDataWebConnection1.Connected := true;
  DataModule1.KundenDataset.Active := false;
  hst := Trim(Uppercase(Eadr));
//  DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
  DataModule1.KundenDataset.QueryString := '$filter=UPC_EMAIL eq '+''''+hst+'''';
//  DataModule1.KundenDataset.Active := true;
  DataModule1.KundenDataset.Load;

  GetKundebyEmail := true;
end;


function Do_Find(DS : TXDataWebDataSet;FName,V : String) : boolean;
begin
  DS.Close;  // Active := false;
  DS.QueryString := '$filter=('+Fname+' eq '+''''+V+''''+')';
  DS.Load;
//  Do_Find := not DS.FieldByName(FName).IsNull;
end;


function Get_AktKDID(NLK : integer;eAdr : String) : integer;
var hst,hst2 : String;
begin
  Get_AktKDID := -1;
  hst2 := 'Select ID FROM KUNDEN where NL_Key='+IntToStr(NLK)+' and UPC_EMail='+''''+uppercase(eAdr) + '''';
  DataModule1.XDataClient.RawInvoke('IApiService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i > 0 then begin
          AktKDID := i;
        end
        else begin
          MessageDlg('Kunden-ID nicht gefunden', mtError, [], nil);
        end;
      end
      else begin
        MessageDlg('Kunden-ID nicht gefunden', mtError, [], nil);
      end;
    end
  );
end;



procedure TDataModule1.ArtikelDataSetCalcFields(DataSet: TDataSet);
begin
  if Dataset.FieldByName('Bezeichnung2').AsString = '' then begin
    Dataset.FieldByName('BezGesamt').AsString := Dataset.FieldByName('Bezeichnung').AsString;
  end
  else begin
    Dataset.FieldByName('BezGesamt').AsString := Dataset.FieldByName('Bezeichnung').AsString + ' '
                                                 + Dataset.FieldByName('Bezeichnung2').AsString;
  end;

end;

procedure TDataModule1.KundenDataSetAfterOpen(DataSet: TDataSet);
begin
  case KundenDataSet_Mode of
    KDDataset_PasswordReset : begin
                             if KundenDataSet.RecordCount = 0 then begin
                               MessageDlg('Kein Kundenkonto mit dieser Email-Adresse gefunden', mtError, [], nil);
                             end

                           end;

  end;

end;

procedure TDataModule1.WebDataModuleCreate(Sender: TObject);
begin
//  Datamodule1.WebClientDataSet2.Active := true;
  aesGCM := TAESEncryption.Create(aetGCM);
  aesGCM.OnKeyCreated := CryptoKeyCreated; //Change key to a predefined one when class is ready
  aesGCM.OnEncrypted := MessageEncrypted;
  aesGCM.OnDecryptedString := MessageDecrypted;
  aesGCM.OnError := CryptoError;
  aesGCM.ImportKey(KEY_FIRST);

end;


procedure TDataModule1.MessageDecrypted(AData: string);
begin
  asm debugger; end;
  CD_Ergebnis := AData;
  MessageDlg('Decrypt : '+CD_Ergebnis, mtError, [], nil);
end;

procedure TDataModule1.MessageEncrypted(ADataAsArrayBuffer: TJSArrayBuffer);
var
  s: string;
begin
  asm debugger; end;
  CD_Ergebnis2 := ABToBinaryString(ADataAsArrayBuffer);
  MessageDlg('Encrypt : '+CD_Ergebnis2, mtError, [], nil);
end;


procedure TDataModule1.CryptoError(AError: string);
begin
  MessageDlg(AError, mtError, [], nil);
end;

procedure TDataModule1.CryptoKeyCreated(Sender: TObject);
begin
  aesGCM.ImportKey(KEY_FIRST);
end;




procedure TDataModule1.XDataWebConnection1Error(
  Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'API-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
  MessageDlg(hst, mtError, [], nil);

end;

procedure TDataModule1.XDataWebConnection1Request(
  Args: TXDataWebConnectionRequest);
begin
  Args.Request.Headers.SetValue('Authorization', 'Bearer ' + Akt_JWToken);
end;

procedure TDataModule1.XDataWebConnection3Error(
  Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Service-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
  MessageDlg(hst, mtError, [], nil);

end;

procedure TDataModule1.XDataWebConnection3Request(
  Args: TXDataWebConnectionRequest);
begin
  Args.Request.Headers.SetValue('Authorization', 'Bearer ' + Akt_JWToken);
end;

procedure TDataModule1.SpeiseplandatasetCalcFields(DataSet: TDataSet);
var hst,hst2 : String;
    i : integer;
begin
  hst2 := Convert_Datum_2(Dataset.FieldByName('Datum').AsdateTime);
//  hst := Copy(hst2,9,2) + '.' + Copy(hst2,6,2) + '.' + Copy(hst2,1,4);
  hst := hst2;
  Dataset.FieldByName('DatumD').AsString := hst2;
  i := DayOfWeek(Dataset.FieldByName('Datum').AsdateTime);
  case i of
    1 : hst := 'So.';
    2 : hst := 'Mo.';
    3 : hst := 'Di.';
    4 : hst := 'Mi.';
    5 : hst := 'Do.';
    6 : hst := 'Fr.';
    7 : hst := 'Sa.';
  end;
  hst := hst + Copy(hst2,1,6);
  Dataset.FieldByName('DatumD2').AsString := hst;
  hst := '';
  if Dataset.FieldByName('KZ_Bestellt').AsInteger = 1 then begin
    hst := 'Bestellt';
    Dataset.FieldByName('BildUrl').AsString := 'images/OK.PNG';
  end
  else if Dataset.FieldByName('KZ_Bestellt').AsInteger = 9 then begin
    hst := 'Storno';
    Dataset.FieldByName('BildUrl').AsString := 'images/Cancel.PNG';
  end
  else begin
    hst := '';
    Dataset.FieldByName('BildUrl').AsString := '';
  end;
  Dataset.FieldByName('Status').AsString := hst;
  if Dataset.FieldByName('Bezeichnung2').AsString <> '' then begin
    Dataset.FieldByName('BezGes').AsString := Dataset.FieldByName('Bezeichnung').AsString
      + ' ' + Dataset.FieldByName('Bezeichnung2').AsString;
  end
  else begin
    Dataset.FieldByName('BezGes').AsString := Dataset.FieldByName('Bezeichnung').AsString;
  end;

  Dataset.FieldByName('BezGes').AsString := Dataset.FieldByName('Bezeichnung').AsString;


end;

procedure TDataModule1.WebClientDataSet2PictureGetText(Sender: TField;
  var Text: string; DisplayText: Boolean);
begin
  Text := 'https://download.tmssoftware.com/tmsweb/demos/TMSWEB_ResponsiveGrid/img/' + StringReplace(Sender.AsString, '.jpg', '_96.jpg', [rfReplaceAll]);
end;

procedure TDataModule1.WebClientDataSet2URLGetText(Sender: TField; var Text: string;
  DisplayText: Boolean);
begin
  Text := 'https://' + Sender.AsString;
end;



function Lade_Daten(var DS : TXDataWebDataSet;AEntity,Qst : String) : boolean;
var Qst2 : String;

  procedure OnSuccess(Response: TXDataClientResponse);
  begin
    DS.SetJsonData(Response.Result);
    DS.Open;
  end;


  procedure OnError(Error: TXDataClientError);
  begin
    WriteLn('Error on request: ' + Error.ErrorMessage);
  end;


begin
  Lade_Daten := false;

  DS.Close;
  DataModule1.XDataWebClient1.List(AEntity,Qst,@OnSuccess,@OnError);

//
//  TeilnehmerDataSet.Load;
end;


function Lade_Daten_3(f : TForm;var TS : TStringList;var DS : TXDataWebDataSet;AEntity,Qst : String;var fst : String) : boolean;

var Qst2,Est2 : String;
    Ist_Geladen : boolean;
    hst : String;

  procedure OnSuccess(Response: TXDataClientResponse);
  begin
  asm debugger; end;
    Ist_Geladen := true;
    DS.SetJsonData(Response.Result);
    LD3_Fertig := true;
    DS.Open;

  end;


  procedure OnError(Error: TXDataClientError);
  begin
  asm debugger; end;
    fst := 'API-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    LD3_Fertig := true;
    Pop_Controls(f,TS);
    MessageDlg(fst, mtError, [], nil);

  end;


begin
  Ist_Geladen := false;
  fst := '';
  if Qst <> '' then Qst2 :=  Qst
  else Qst2 := DS.QueryString;
  if AEntity <> '' then Est2 :=  AEntity
  else Est2 := DS.EntitySetName;

  DS.Close;
  asm debugger; end;
  DataModule1.XDataWebClient1.List(Est2,Qst2,@OnSuccess,@OnError);
  Lade_Daten_3 := true;
{
  LD3_Fertig := false;
  hst := '';
  repeat
    hst := hst + '1';
    if length(hst) > 255 then hst := '';
  until LD3_Fertig;
  asm debugger; end;
  if not Ist_Geladen then begin
    Lade_Daten_3 := false;
  end
  else begin
    Lade_Daten_3 := true;
    DS.Open;

  end;
}

//
//  TeilnehmerDataSet.Load;
end;





function Lade_Daten_2(var DS : TXDataWebDataSet;AEntity,Qst,OrderStr : String) : boolean;


  procedure OnSuccess(Response: TXDataClientResponse);
  begin
    DS.SetJsonData(Response.Result);
    DS.Open;
  end;

begin

  DS.Close;
  DataModule1.XDataWebClient2.List(AEntity,Qst,@OnSuccess);

//
//  TeilnehmerDataSet.Load;
end;



function Get_AktEinrichtungID(NLK : integer;Nummer : integer) : integer;
var hst,hst2 : String;
begin
  Get_AktEinrichtungID := -1;
  hst2 := 'Select ID FROM Einrichtungen where NL_Key='+IntToStr(NLK)+' and Nummer='+IntToStr(Nummer)+ '''';
  DataModule1.XDataClient.RawInvoke('IApiService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i > 0 then begin
          AktEinrichtungID := i;
        end
        else begin
          MessageDlg('Einrichtung-ID nicht gefunden', mtError, [], nil);
        end;
      end
      else begin
        MessageDlg('Einrichtung-ID nicht gefunden', mtError, [], nil);
      end;
    end
  );
end;


function Create_Form(InstanceClass: TComponentClass; var Reference) : boolean;
begin
  Create_Form := false;

  Reference := TFormClass(InstanceClass).CreateNew(procedure(AForm: TObject)
    begin
      TForm(Reference).Caption := 'Speiseplan';
    end
  );
  TForm(Reference).Border := fbSingle;

  TForm(Reference).ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );


 {
  Application.CreateForm(TWebFormClass(InstanceClass), Reference);
  TWebForm(Reference).ShowModal;
//  TWebForm(Reference).Free;
}

  Create_Form := true;
end;


procedure Resize_Top_Panel(F : TForm;P : TPanel);
var E : TLabel;
    Img : TImageControl;
    k : integer;
begin
    P.Height := Top_Panel1_Height;
    E := TLabel.Create(F);
    E.Name := F.name + '_FirmaLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := 2;
    E.Font.Size := Firma_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := AktFirmaName;
    k := E.Height;

    E := TLabel.Create(F);
    E.Name := F.name + '_KundenLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := k;
    E.Font.Size := Kunden_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := 'Benutzer: '+AktKDName;
    k := k + E.Height;
    Mit_Hilfe_Button := false;
    if Mit_Hilfe_Button then begin
      Img := TImageControl.Create(F);
      Img.Name := F.name + '_Help_img';
      Img.Parent := P;
      Img.Top := k;
      Img.Left := 240;
      Img.Height := 30;
      Img.Width := 30;
      Img.Url := 'images/hilfe.png';
      Img.Tag := Get_Help_Context(F.Name);
      Img.Onclick := Datamodule1.Hilfe_Click;
    end;
end;

procedure Resize_Top_Panel_2(F : TForm;P : TPanel;Header : String);
var E : TLabel;
    Img : TImageControl;
    k : integer;
begin

    P.Height := Top_Panel2_Height;
    E := TLabel.Create(F);
    E.Name := F.name + '_FirmaLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := 2;
    E.Font.Size := Firma_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := AktFirmaName;
    k := E.Height;

    E := TLabel.Create(F);
    E.Name := F.name + '_KundenLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := k;
    E.Font.Size := Kunden_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := Header;
    k := k + E.Height;
    Mit_Hilfe_Button := false;
    if Mit_Hilfe_Button then begin
      Img := TImageControl.Create(F);
      Img.Name := F.name + '_Help_img';
      Img.Parent := P;
      Img.Top := k;
      Img.Left := 240;
      Img.Height := 30;
      Img.Width := 30;
      Img.Url := 'images/hilfe.png';
      Img.Tag := Get_Help_Context(F.Name);
      Img.Onclick := Datamodule1.Hilfe_Click;
    end;
end;


procedure Resize_Top_Panel_3(F : TForm;P : TPanel;Header,Header2 : String);
var E : TLabel;
    Img : TImageControl;
    k : integer;

begin
    P.Height := Top_Panel3_Height;
    E := TLabel.Create(F);
    E.Name := F.name + '_FirmaLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := 2;
    E.Font.Size := Firma_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := AktFirmaName;
    k := E.Height;

    E := TLabel.Create(F);
    E.Name := F.name + '_KundenLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := k;
    E.Font.Size := Kunden_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := Header;
    k := k + E.Height;

    E := TLabel.Create(F);
    E.Name := F.name + '_HeaderLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := k;
    E.Font.Size := Top_Panel_Zusatz_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := Header2;
    k := k + E.Height;
    Mit_Hilfe_Button := false;
    if Mit_Hilfe_Button then begin
      Img := TImageControl.Create(F);
      Img.Name := F.name + '_Help_img';
      Img.Parent := P;
      Img.Top := k;
      Img.Left := 240;
      Img.Height := 30;
      Img.Width := 30;
      Img.Url := 'images/hilfe.png';
      Img.Tag := Get_Help_Context(F.Name);
      Img.Onclick := Datamodule1.Hilfe_Click;
    end;
end;

procedure Resize_Top_Panel_4(F : TForm;P : TPanel;Header,Header2,Header3 : String);
var E : TLabel;
    Img : TImageControl;
    k : integer;

begin
    P.Height := Top_Panel4_Height;
    E := TLabel.Create(F);
    E.Name := F.name + '_FirmaLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := 2;
    E.Font.Size := Firma_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := AktFirmaName;
    k := E.Height;

    E := TLabel.Create(F);
    E.Name := F.name + '_KundenLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := k;
    E.Font.Size := Kunden_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := Header;
    k := k + E.Height;

    E := TLabel.Create(F);
    E.Name := F.name + '_HeaderLabel';
    E.Parent := P;
    E.Left := 5;
    E.Top := k;
    E.Font.Size := Top_Panel_Zusatz_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := Header2;
    k := k + E.Height;

    E := TLabel.Create(F);
    E.Name := F.name + '_HeaderLabel2';
    E.Parent := P;
    E.Left := 5;
    E.Top := k;
    E.Font.Size := Top_Panel_Zusatz_Font_Size;
    E.Font.Style := [fsBold];
    E.Caption := Header3;
    k := k + E.Height;
    Mit_Hilfe_Button := false;
    if Mit_Hilfe_Button then begin
      Img := TImageControl.Create(F);
      Img.Name := F.name + '_Help_img';
      Img.Parent := P;
      Img.Top := k;
      Img.Left := 240;
      Img.Height := 30;
      Img.Width := 30;
      Img.Url := 'images/hilfe.png';
      Img.Tag := Get_Help_Context(F.Name);
      Img.Onclick := Datamodule1.Hilfe_Click;
    end;
end;


procedure Resize_Top_Panel_5(F : TForm;P : TImageControl);
begin
  P.Height := Top_Panel5_Height;
end;


procedure Resize_Top_Panel_6(F : TForm;P : TImageControl);
begin
//
end;


procedure Resize_Bottom_Panel(F : TForm;P : TPanel);
var i,j,BH : integer;
    L,L2,L3 : TLabel;
begin
  P.Height := Bottom_Panel_Height+Caption_Font_Size;
  BH := BP_Btn_Height- 15;
  for i := 0 to P.ControlCount - 1 do begin
    if P.Controls[i] is TButton then begin
      P.Controls[i].Top := 5;
      P.Controls[i].Height := BH;
      P.Font.Size := BP_Btn_Font_Size;
      TButton(P.Controls[i]).ElementClassname := 'btn btn-primary';
    end;
  end;
  if Mit_AGB_etc then begin
    L := TLabel.Create(F);
    L.Name := 'ImpLabel_'+F.Name;
    L.Parent := P;
    L.Left := 5;
    L.Top := P.Height - Caption_Font_Size - 15;
    L.Font.Size := Caption_Font_Size;
    L.Font.Style := [fsBold,fsUnderline];
    L.Font.Color := clBlue;
    L.Caption := 'Impressum';
    L.Onclick := Datamodule1.Impressum_Click;


    L2 := TLabel.Create(F);
    L2.Name := 'AGBLabel_'+F.Name;
    L2.Parent := P;
    L2.Left := 5+L.Width+15;
    L2.Top := L.Top;
    L2.Font.Size := Caption_Font_Size;
    L2.Font.Style := [fsBold,fsUnderline];
    L2.Font.Color := clBlue;
    L2.Caption := 'AGB´s';
    L2.Onclick := Datamodule1.AGB_Click;

    L3 := TLabel.Create(F);
    L3.Name := 'DSVOLabel_'+F.Name;
    L3.Parent := P;
    L3.Left := L2.Left+L2.Width+15;
    L3.Top := L.Top;
    L3.Font.Size := Caption_Font_Size;
    L3.Font.Style := [fsBold,fsUnderline];
    L3.Font.Color := clBlue;
    L3.Caption := 'Datenschutzerklärung';
    L3.Onclick := Datamodule1.DSE_Click;
  end;
end;

procedure Resize_Bottom_Panel_2(F : TForm;P : TPanel);
var i,j,BH : integer;
    L,L2,L3 : TLabel;
begin

  P.Height := Edit_Height;
  L := TLabel.Create(F);
  L.Name := 'ImpLabel_'+F.Name;
  L.Parent := P;
  L.Left := 5;
  L.Top := 5;
  L.Font.Size := Caption_Font_Size;
  L.Font.Style := [fsBold,fsUnderline];
  L.Font.Color := clBlue;
  L.Caption := 'Impressum';
  L.Onclick := Datamodule1.Impressum_Click;


  L2 := TLabel.Create(F);
  L2.Name := 'AGBLabel_'+F.Name;
  L2.Parent := P;
  L2.Left := 5+L.Width+15;
  L2.Top := 5;
  L2.Font.Size := Caption_Font_Size;
  L2.Font.Style := [fsBold,fsUnderline];
  L2.Font.Color := clBlue;
  L2.Caption := 'AGB´s';
  L2.Onclick := Datamodule1.AGB_Click;

  L3 := TLabel.Create(F);
  L3.Name := 'DSVOLabel_'+F.Name;
  L3.Parent := P;
  L3.Left := L2.Left+L2.Width+15;
  L3.Top := 5;
  L3.Font.Size := Caption_Font_Size;
  L3.Font.Style := [fsBold,fsUnderline];
  L3.Font.Color := clBlue;
  L3.Caption := 'Datenschutzerklärung';
  L3.Onclick := Datamodule1.DSE_Click;

end;


procedure Zeilen_Liste_Add(var TS : TStringList;var Zeilen_Count : integer;LabelC,EditC,ZusatzLabel : TControl);
var hst,hst2,hst3 : String;
begin
  Zeilen_Count := Zeilen_Count + 1;
  if LabelC <> nil then begin
    hst := ' ;'+LabelC.Name + ';'
         + IntToStr(LabelC.Left) + ';'
         + IntToStr(LabelC.Top) + ';';
  end
  else begin
    hst := ' ;;;;';
  end;
  if EditC <> nil then begin
    hst2 := Uppercase(EditC.Owner.Name + ';' + EditC.Name);
    if LabelC <> nil then begin
      hst3 := TLabel(LabelC).Caption;
    end
    else begin
      hst3 := '';
    end;
    if Caption_List1.IndexOf(hst2) = -1 then begin
      Caption_List1.Add(hst2);
      Caption_List2.Add(hst3);
    end;

    hst := hst
         + EditC.Name + ';'
         + IntToStr(EditC.Left) + ';'
         + IntToStr(EditC.Top) + ';';

    if Mit_On_Click then begin
      if EditC is TDBEdit then begin
        TDBEdit(EditC).OnClick := Datamodule1.Standard_Edit_Click;
      end
      else if EditC is TEdit then begin
        TEdit(EditC).OnClick := Datamodule1.Standard_Edit_Click;
      end;
    end;
  end
  else begin
    hst := hst + ';;;';
  end;
  if ZusatzLabel <> nil then begin
    hst := hst
         + ZusatzLabel.Name + ';'
         + IntToStr(ZusatzLabel.Left) + ';'
         + IntToStr(ZusatzLabel.Top) + ';';

  end
  else begin
    hst := hst + ';;;';
  end;

  TS.Add(hst);

end;



procedure Zeilen_Liste_Add_Menu(var TS : TStringList;var Zeilen_Count : integer;Btn : TControl);
var hst,hst2,hst3 : String;
begin
  Zeilen_Count := Zeilen_Count + 1;
  hst := Uppercase(Btn.Name);
  TS.Add(hst);

end;





procedure Zeilen_Liste_Add_Hinweis(var TS : TStringList;var Zeilen_Count : integer;LabelC : TControl);
var hst : String;
begin
  Zeilen_Count := Zeilen_Count + 1;
  if LabelC <> nil then begin
    hst := 'H;'+LabelC.Name + ';'
         + IntToStr(LabelC.Left) + ';'
         + IntToStr(LabelC.Top) + ';';
  end
  else begin
    hst := ' ;;;;';
  end;
  hst := hst + ';;;';
  hst := hst + ';;;';

  TS.Add(hst);

end;



procedure Zeilen_Liste_Set(var TS : TStringList;EditC1,EditC2 : TControl);
var hst : String;
begin
  hst := TS[TS.Count -1];
  EditC2.Top := EditC1.Top;
  hst[1] := '*';
  hst := hst + EditC2.Name + ';'
         + IntToStr(EditC2.Left) + ';'
         + IntToStr(EditC2.Top) + ';';
  TS[TS.Count -1] := hst;
end;

procedure Zeilen_Liste_Set_2(var TS : TStringList;EditC1,EditC2,EditC3,EditC4 : TControl);
var hst,hst2,hst3 : String;
    i : integer;
begin
  hst := TS[TS.Count -1];
  EditC2.Top := EditC1.Top;
  EditC4.Top := EditC3.Top;

  hst := '*;'+EditC2.Name + ';'
         + IntToStr(EditC2.Left) + ';'
         + IntToStr(EditC2.Top) + ';'
         + EditC4.Name + ';'
         + IntToStr(EditC4.Left) + ';'
         + IntToStr(EditC4.Top) + ';';

  hst2 := Uppercase(EditC4.Owner.Name + ';' + EditC4.Name);
  if EditC2 <> nil then begin
    hst3 := TLabel(EditC2).Caption;
  end
  else begin
    hst3 := '';
  end;
  if Caption_List1.IndexOf(hst2) = -1 then begin
    Caption_List1.Add(hst2);
    Caption_List2.Add(hst3);
  end;
  if Mit_On_Click then begin
    if EditC4 is TDBEdit then begin
      TDBEdit(EditC4).OnClick := Datamodule1.Standard_Edit_Click;
    end
    else if EditC4 is TEdit then begin
      TEdit(EditC4).OnClick := Datamodule1.Standard_Edit_Click;
    end
  end;



  TS[TS.Count -1] := hst;
end;

{
procedure Resize_Edit_Controls(Art : integer;F : TWebForm;TS : TStringList;TopPanel,BottomPanel : TControl);
var i,j,k,H1,H2,i2,j2 : integer;
    hst,hst2,hst3,hst4 : String;
    c : TControl;
    ec : TControl;
    Repos : boolean;
begin
  if TS.Count = 0 then exit;
  if TopPanel <> nil then begin
    H1 := TopPanel.Height;
  end
  else begin
    H1 := 0;
  end;
  if BottomPanel <> nil then begin
    H2 := BottomPanel.Top;
  end
  else begin
    H2 := F.Height;
  end;
  j := (H2 - H1) div (TS.Count+2);
  if j > 50 then j := 50;

  k := j+H1;

  for i := 0 to TS.Count - 1 do begin
    hst := TS[i];
    ec := nil;
    Repos := false;
    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    if hst2 = ' ' then begin
      Repos := true;
      system.delete(hst,1,i2);
    end
    else begin
      Repos := false;
      system.delete(hst,1,i2);
    end;

    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst3 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst4 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    if hst2 <> '' then begin
      for j2 := 0 to F.ControlCount - 1 do begin
        if F.Controls[j2].Name = hst2 then begin
          F.Controls[j2].Top := k;
        end;
      end;
    end;

    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst3 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst4 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    if hst2 <> '' then begin
      for j2 := 0 to F.ControlCount - 1 do begin
        if F.Controls[j2].Name = hst2 then begin
          if F.Controls[j2] is TWebMemo then begin
            F.Controls[j2].Top := k + 25;
            ec := F.Controls[j2];
          end
          else begin
            if (F.Controls[j2] is TWebEdit) or (F.Controls[j2] is TWebDBEdit) then begin
              ec := F.Controls[j2];
            end;
            if Akt_OS = OS_IOS then begin
              if (F.Controls[j2] is TWebEdit) or (F.Controls[j2] is TWebDBEdit) then begin
                F.Controls[j2].Height := 35;
                F.Controls[j2].Top := k + 45;

              end
              else begin
                F.Controls[j2].Top := k + 25;
              end;
            end
            else begin
              F.Controls[j2].Top := k + 25;
            end;
          end;
        end;
      end;
    end;

    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst3 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst4 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    if hst2 <> '' then begin
      for j2 := 0 to F.ControlCount - 1 do begin
        if F.Controls[j2].Name = hst2 then begin
          F.Controls[j2].Top := k + 25;
        end;
      end;
    end;
    hst := Trim(hst);
    repeat
      if hst <> '' then begin
        i2 := pos(';',hst);
        hst2 := Copy(hst,1,i2-1);
        system.delete(hst,1,i2);
        i2 := pos(';',hst);
        hst3 := Copy(hst,1,i2-1);
        system.delete(hst,1,i2);
        i2 := pos(';',hst);
        hst4 := Copy(hst,1,i2-1);
        system.delete(hst,1,i2);
        if hst2 <> '' then begin
          for j2 := 0 to F.ControlCount - 1 do begin
            if F.Controls[j2].Name = hst2 then begin
              F.Controls[j2].Top := k + 25;
            end;
          end;
        end;
      end;
    until hst = '';

    if ec <> nil then begin
      k := k + j + ec.Height;
    end
    else begin
      k := k + j;
    end;
  end;
end;

}



procedure Resize_Edit_Controls(Art : integer;F : TForm;TS : TStringList;TopPanel,BottomPanel : TControl);
var i,j,k,k2,H1,H2,i2,j2,l_k,bh : integer;
    hst,hst2,hst3,hst4 : String;
    c : TControl;
    btn : TButton;
    ec : TControl;
    hwl,
    Repos : boolean;
begin
  asm debugger; end;
  if TS.Count = 0 then exit;
  l_k := -1;
  if TopPanel <> nil then begin
    H1 := TopPanel.Height;
  end
  else begin
    H1 := 0;
  end;
  if BottomPanel <> nil then begin
    H2 := BottomPanel.Top;
  end
  else begin
    H2 := F.Height;
  end;

  j := (H2 - H1) div (TS.Count+2);
  if j > 50 then j := 50;

  k := H1 + 10;

  if Art = WF_Menu then begin
    bh := (H2 - H1) div (TS.Count+2);
    k := H1 + 10;
    if bh > Max_Menu_Height then bh := Max_Menu_Height;

    for i := 0 to TS.Count - 1 do begin
      hst := TS[i];
      k2 := -1;
      for j2 := 0 to F.ControlCount - 1 do begin
        if Uppercase(F.Controls[j2].Name) = hst then begin
          k2 := j2;
          break;
        end;
      end;
      if k2 <> -1 then begin
        btn := TButton(F.Controls[k2]);
        btn.Left := 10;
        btn.Top := k;
        btn.height := bh;
        btn.ElementClassname := 'btn btn-primary';
        k := k + btn.Height + Dist_Edit_Cap;
      end;
    end;
    exit;
  end;


  for i := 0 to TS.Count - 1 do begin
    hst := TS[i];
    ec := nil;
    Repos := false;
    hwl := false;
    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    if hst2 = ' ' then begin
      Repos := true;
      system.delete(hst,1,i2);
    end
    else if hst2 = 'H' then begin
      Repos := true;
      hwl := true;
      system.delete(hst,1,i2);
    end
    else begin
      Repos := false;
      system.delete(hst,1,i2);
    end;

    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst3 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst4 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    if hst2 <> '' then begin
      for j2 := 0 to F.ControlCount - 1 do begin
        if F.Controls[j2].Name = hst2 then begin
          F.Controls[j2].Top := k;
          if not hwl then begin
            Tlabel(F.Controls[j2]).Font.Size := Caption_Font_Size;
            Tlabel(F.Controls[j2]).Height := Caption_Height;
            Tlabel(F.Controls[j2]).Color := clWhite;

            k := k + Tlabel(F.Controls[j2]).Height + Dist_Cap_Edit;
          end
          else begin
            Tlabel(F.Controls[j2]).Font.Size := Hinweis_Font_Size;
            Tlabel(F.Controls[j2]).Height := Caption_Height*2;
            Tlabel(F.Controls[j2]).Color := clWhite;

            k := k + Tlabel(F.Controls[j2]).Height + Dist_Cap_Edit;
          end;
        end;
      end;
    end
    else begin

    end;

    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst3 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst4 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    if hst2 <> '' then begin
      for j2 := 0 to F.ControlCount - 1 do begin
        if F.Controls[j2].Name = hst2 then begin
          if F.Controls[j2] is TMemo then begin
            l_k := k;
            F.Controls[j2].Top := k;
            ec := F.Controls[j2];
            k := k + TMemo(F.Controls[j2]).Height + Dist_Edit_Cap;

          end
          else begin
            if (F.Controls[j2] is TEdit) then begin
              l_k := k;
              ec := F.Controls[j2];
              TEdit(F.Controls[j2]).Top := k;
              TEdit(F.Controls[j2]).Font.Size := Edit_Font_Size;
              TEdit(F.Controls[j2]).Height := Edit_Height;
              k := k + TEdit(F.Controls[j2]).Height + Dist_Edit_Cap;
            end

            else if (F.Controls[j2] is TDBEdit) then begin
              l_k := k;
              ec := F.Controls[j2];
              TDBEdit(F.Controls[j2]).Top := k;
              TDBEdit(F.Controls[j2]).Font.Size := Edit_Font_Size;
              TDBEdit(F.Controls[j2]).Height := Edit_Height;
              k := k + TDBEdit(F.Controls[j2]).Height + Dist_Edit_Cap;
            end
            else if (F.Controls[j2] is TDBComboBox) then begin
              l_k := k;
              ec := F.Controls[j2];
              TDBComboBox(F.Controls[j2]).Top := k;
              TDBComboBox(F.Controls[j2]).Font.Size := Edit_Font_Size;
              TDBComboBox(F.Controls[j2]).Height := Edit_Height;
              k := k + TDBComboBox(F.Controls[j2]).Height + Dist_Edit_Cap;
            end
            else if (F.Controls[j2] is TComboBox) then begin
              l_k := k;
              ec := F.Controls[j2];
              TComboBox(F.Controls[j2]).Top := k;
              TComboBox(F.Controls[j2]).Font.Size := Edit_Font_Size;
              TComboBox(F.Controls[j2]).Height := Edit_Height;
              k := k + TComboBox(F.Controls[j2]).Height + Dist_Edit_Cap;
            end
            else if (F.Controls[j2] is TDateTimePicker)  then begin
              l_k := k;
              ec := F.Controls[j2];
              TDateTimePicker(F.Controls[j2]).Top := k;
              TDateTimePicker(F.Controls[j2]).Font.Size := Edit_Font_Size;
              TDateTimePicker(F.Controls[j2]).Height := Edit_Height;
              k := k + TDateTimePicker(F.Controls[j2]).Height + Dist_Edit_Cap;
            end

          end;
        end;
      end;
    end;

    i2 := pos(';',hst);
    hst2 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst3 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    i2 := pos(';',hst);
    hst4 := Copy(hst,1,i2-1);
    system.delete(hst,1,i2);
    if hst2 <> '' then begin
      for j2 := 0 to F.ControlCount - 1 do begin
        if F.Controls[j2].Name = hst2 then begin
          F.Controls[j2].Top := l_k;
        end;
      end;
    end;
    hst := Trim(hst);
    repeat
      if hst <> '' then begin
        i2 := pos(';',hst);
        hst2 := Copy(hst,1,i2-1);
        system.delete(hst,1,i2);
        i2 := pos(';',hst);
        hst3 := Copy(hst,1,i2-1);
        system.delete(hst,1,i2);
        i2 := pos(';',hst);
        hst4 := Copy(hst,1,i2-1);
        system.delete(hst,1,i2);
        if hst2 <> '' then begin
          for j2 := 0 to F.ControlCount - 1 do begin
            if F.Controls[j2].Name = hst2 then begin
              F.Controls[j2].Top := l_k;
              if F.Controls[j2] is TMemo then begin
              end
              else begin
                if (F.Controls[j2] is TEdit) then begin
                  TEdit(F.Controls[j2]).Font.Size := Edit_Font_Size;
                  TEdit(F.Controls[j2]).Height := Edit_Height;
                end
                else if (F.Controls[j2] is TDBEdit) then begin
                  TDBEdit(F.Controls[j2]).Font.Size := Edit_Font_Size;
                  TDBEdit(F.Controls[j2]).Height := Edit_Height;
                end
                else if (F.Controls[j2] is TComboBox) then begin
                  TComboBox(F.Controls[j2]).Font.Size := Edit_Font_Size;
                  TComboBox(F.Controls[j2]).Height := Edit_Height;
                end
                else if (F.Controls[j2] is TDBComboBox) then begin
                  TDBComboBox(F.Controls[j2]).Font.Size := Edit_Font_Size;
                  TDBComboBox(F.Controls[j2]).Height := Edit_Height;
                end
              end;
            end;
          end;
        end;
      end;
    until hst = '';
  end;
end;




function Get_Device_Typ : integer;
var hst,hst2 : String;
    i,j,k,w,h,GT : integer;
begin
  hst := window.navigator.userAgent;
  w := StrToInt(string(window.screen['width']));
  h := StrToInt(string(window.screen['height']));
  GT := Unknown_Device;
  Akt_OS := OS_Unknown;

  hst2 := Uppercase(hst);
  i := pos('(',hst2);
  if i > 0 then begin
    system.delete(hst2,1,i);
    if Copy(hst2,1,length('IPHONE;')) = 'IPHONE;' then begin
      Akt_OS := OS_IOS;
      Device_Ist_Handy := true;
      if (w = 320) and (h = 480) then begin
        GT := IPhone_4;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;


        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;



      end
      else if (w = 320) and (h = 568) then begin
        GT := IPhone_5;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;



      end
      else if (w = 375) and (h = 667) then begin
        GT := IPhone_7_8;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;



      end
      else if (w = 414) and (h = 736) then begin
        GT := IPhone_7_8_Plus;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;


      end
      else if (w = 375) and (h = 812) then begin
        GT := IPhone_X;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;


      end
      else if (w = 414) and (h = 896) then begin
        GT := IPhone_X_Max;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;

      end
      else if (w = 414) and (h = 496) then begin
        GT := IPhone_X_R;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;

      end
      else begin
        GT := IPhone_4;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;


        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 85;
        Top_Panel4_Height := 85;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;

      end;
    end
    else if Copy(hst2,1,length('LINUX; ANDROID')) = 'LINUX; ANDROID' then begin
      Akt_OS := OS_Android;
      Device_Ist_Handy := true;
      if (w = 320) and (h = 512) then begin



        GT := Android_320_512;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 11;
        Edit_Height := 25;
        Combo_Font_Size := 11;
        Combo_Height     := 32;
        Dist_Cap_Edit := 2;
        Dist_Edit_Cap := 4;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 105;
        Top_Panel4_Height := 125;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;
      end
      else begin




        GT := Android_undef;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 25;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 105;
        Top_Panel4_Height := 125;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;
      end
    end
    else if Copy(hst2,1,length('WINDOWS')) = 'WINDOWS' then begin
      Akt_OS := OS_Windows;
      Device_Ist_Handy := false;
      if (w = 1600) and (h = 900) then begin
        GT := Windows_1600_900;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 105;
        Top_Panel4_Height := 125;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;
      end
      else if (w = 1280) and (h = 800) then begin
        GT := Windows_1280_800;
        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 105;
        Top_Panel4_Height := 125;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;
      end


      else begin
        GT := Windows_undef;

        Caption_Font_Size := 10;
        Caption_Height := 16;
        Edit_Font_Size := 12;
        Edit_Height := 35;
        Combo_Font_Size := 12;
        Combo_Height     := 22;
        Dist_Cap_Edit := 3;
        Dist_Edit_Cap := 6;
        Max_Menu_Height := 70;

        Bottom_Panel_Height := 65;
        BP_Btn_Height := 55;
        Top_Panel1_Height := 85;
        Top_Panel2_Height := 85;
        Top_Panel3_Height := 105;
        Top_Panel4_Height := 125;
        Top_Panel5_Height := 85;
        Firma_Font_Size := 16;
        Kunden_Font_Size := 13;
        Top_Panel_Zusatz_Font_Size := 12;
        BP_Btn_Font_Size := 12;



      end;
    end;
  end;
  Get_Device_Typ := GT;
end;

procedure GetEditStrTouch(Header,st : String;pw : boolean;c: TControl);
begin
  if not Device_Ist_Handy then exit;

  Edit_Str_Touch(Header,st,pw,c);
end;


procedure GetEditStrTouchPW(Header,st,st2 : String;c,c2: TControl);
begin
  if not Device_Ist_Handy then exit;

  Edit_Str_Touch_PW(Header,st,st2,c,c2);
end;

procedure TDataModule1.Standard_Edit_Click(Sender: TObject);
var hst,hst2,st : String;
    i : integer;
    pw : boolean;
begin
  if not Device_Ist_Handy then exit;

  if Sender is TEdit then begin
  asm debugger; end;
    hst := Uppercase(TEdit(Sender).Owner.name + ';' + TEdit(Sender).Name);
    i := Caption_List1.IndexOf(hst);
    if i > -1 then begin
      hst2 := Caption_List2[i];
    end
    else begin
      hst2 := '';
    end;
    pw := TEdit(Sender).PasswordChar <> #0;
    st := TEdit(Sender).Text;
    Edit_Str_Touch(hst2,st,pw,TEdit(Sender));
  end
  else if Sender is TDBEdit then begin
  asm debugger; end;
    hst := Uppercase(TDBEdit(Sender).Owner.name + ';' + TDBEdit(Sender).Name);
    i := Caption_List1.IndexOf(hst);
    if i > -1 then begin
      hst2 := Caption_List2[i];
    end
    else begin
      hst2 := '';
    end;
    pw := TDBEdit(Sender).PasswordChar <> #0;
    st := TDBEdit(Sender).Text;
    Edit_Str_Touch(hst2,st,pw,TDBEdit(Sender));
  end



//
end;



procedure Push_Controls(f : TForm;var TS : TStringList);
var i : integer;
    hst,hst2,hst3 : String;
    p : PPushControl_Stack_Rec;

begin


  TS.Clear;
  for i := 0 to f.ControlCount - 1 do begin
    hst := Uppercase(f.Controls[i].Name);
    if f.Controls[i] is TDBEdit then begin
      if TDBEdit(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      if TDBEdit(f.Controls[i]).Readonly then hst3 := '1'
      else hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TDBEdit(f.Controls[i]).Enabled := false;
      TDBEdit(f.Controls[i]).ReadOnly := true;

    end
    else if f.Controls[i] is TEdit then begin
      if TEdit(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      if TEdit(f.Controls[i]).Readonly then hst3 := '1'
      else hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TEdit(f.Controls[i]).Enabled := false;
      TEdit(f.Controls[i]).ReadOnly := true;

    end
    else if f.Controls[i] is TDBComboBox then begin
      if TDBComboBox(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TDBComboBox(f.Controls[i]).Enabled := false;
    end
    else if f.Controls[i] is TComboBox then begin
      if TComboBox(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TComboBox(f.Controls[i]).Enabled := false;
    end
    else if f.Controls[i] is TButton then begin
      if TButton(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TButton(f.Controls[i]).Enabled := false;
    end
    else if f.Controls[i] is TLabel then begin
      if TLabel(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TLabel(f.Controls[i]).Enabled := false;
    end
    else if f.Controls[i] is TImageControl then begin
      if TImageControl(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TImageControl(f.Controls[i]).Enabled := false;
    end
    else if f.Controls[i] is TDBGrid then begin
      if TDBGrid(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TDBGrid(f.Controls[i]).Enabled := false;
    end
    else if f.Controls[i] is TDBCheckBox then begin
      if TDBCheckBox(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TDBCheckBox(f.Controls[i]).Enabled := false;
    end
    else if f.Controls[i] is TCheckBox then begin
      if TCheckBox(f.Controls[i]).Enabled then hst2 := '1'
      else hst2 := '0';
      hst3 := '0';
      TS.Add(hst+';'+hst2+';'+hst3);
      TCheckBox(f.Controls[i]).Enabled := false;
    end;

  end;

  new(p);
  p^.F := f;
  p^.TS := TS;
  PushControl_Liste.Add(p);

end;


procedure Pop_Controls(f : TForm;var TS : TStringList);
var i,j,k : integer;
    hst,hst2,hst3,hst4 : String;
    b_Enabled,
    b_Readonly : boolean;
begin
  if TS.Count = 0 then exit;

  asm debugger; end;
  for i := 0 to TS.Count - 1 do begin
    hst := TS[i];
    j := pos(';',hst);
    hst2 := Copy(hst,1,j-1);
    system.delete(hst,1,j);
    j := pos(';',hst);
    hst3 := Copy(hst,1,j-1);
    system.delete(hst,1,j);
    if hst3 = '1' then b_Enabled := true
    else b_Enabled := false;
    if hst = '1' then b_Readonly := true
    else b_ReadOnly := false;
    k := -1;
    for j := 0 to f.ControlCount - 1 do begin
      hst4 := Uppercase(f.Controls[j].Name);
      if hst4 = hst2 then begin
        k := j;
        break;
      end;
    end;
    if k <> -1 then begin
      if f.Controls[k] is TDBEdit then begin
        TDBEdit(f.Controls[k]).Enabled := b_Enabled;
        TDBEdit(f.Controls[k]).ReadOnly := b_ReadOnly;
      end
      else if f.Controls[k] is TEdit then begin
        TEdit(f.Controls[k]).Enabled := b_Enabled;
        TEdit(f.Controls[k]).ReadOnly := b_ReadOnly;
      end
      else if f.Controls[k] is TDBComboBox then begin
        TDBComboBox(f.Controls[k]).Enabled := b_Enabled;
      end
      else if f.Controls[k] is TComboBox then begin
        TComboBox(f.Controls[k]).Enabled := b_Enabled;
      end
      else if f.Controls[k] is TButton then begin
        TButton(f.Controls[k]).Enabled := b_Enabled;
      end
      else if f.Controls[k] is TLabel then begin
        TLabel(f.Controls[k]).Enabled := b_Enabled;
      end
      else if f.Controls[k] is TImageControl then begin
        TImageControl(f.Controls[k]).Enabled := b_Enabled;
      end
      else if f.Controls[k] is TDBGrid then begin
        TDBGrid(f.Controls[k]).Enabled := b_Enabled;
      end
      else if f.Controls[k] is TDBCheckBox then begin
        TDBCheckBox(f.Controls[k]).Enabled := b_Enabled;
      end
      else if f.Controls[k] is TCheckBox then begin
        TCheckBox(f.Controls[k]).Enabled := b_Enabled;
      end;

    end;
  end;
  TS.Clear;
  i := PushControl_Liste.Count - 1;
  if PPushControl_Stack_Rec(PushControl_Liste[i])^.F = f then begin
    dispose(PPushControl_Stack_Rec(PushControl_Liste[i]));
    PushControl_Liste.delete(i);
  end
  else begin
    MessageDlg('Control-Stack-Fehler', mtError, [], nil);
  end;


end;


procedure TDataModule1.Impressum_Click(Sender: TObject);
begin
  Anzeige_PDF('Impressum','Impressum.pdf');

//
end;

procedure TDataModule1.AGB_Click(Sender: TObject);
begin
  Anzeige_PDF('Allgemeine Geschäftsbedingungen','AGB.pdf');

//
end;

procedure TDataModule1.DSE_Click(Sender: TObject);
begin
  Anzeige_PDF('Datenschutzerklärung','Datenschutzerklaerung.pdf');


end;

procedure TDataModule1.Hilfe_Click(Sender: TObject);
var i : integer;
begin
  i := TImageControl(Sender).Tag;
  Anzeige_PDF('Hilfe '+IntToStr(i),'Hilfe_'+IntToStr(i)+'.pdf');


end;



function CryptString(s : String) : String;
begin

  asm debugger; end;
  DataModule1.aesGCM.Encrypt(s);
//  Crypt_String := DataModule1.CD_Ergebnis;
end;

function DeCryptString(s : String) : String;
var
  b : TJSArrayBuffer;
begin
  asm debugger; end;
  b := BinaryStringToAB(s);
  DataModule1.aesGCM.Decrypt(b, drtString);
//  DeCrypt_String := DataModule1.CD_Ergebnis;
end;


procedure TDataModule1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  XDataWebConnection1 := TXDataWebConnection.Create(Self);
  KundenDataSet := TXDataWebDataSet.Create(Self);
  KundenDataSetID := TIntegerField.Create(Self);
  KundenDataSetNL_KEY := TIntegerField.Create(Self);
  KundenDataSetKDNO := TIntegerField.Create(Self);
  KundenDataSetNAME := TStringField.Create(Self);
  KundenDataSetNAME2 := TStringField.Create(Self);
  KundenDataSetSTRASSE := TStringField.Create(Self);
  KundenDataSetPLZ := TStringField.Create(Self);
  KundenDataSetORT := TStringField.Create(Self);
  KundenDataSetTELEFON := TStringField.Create(Self);
  KundenDataSetHANDY := TStringField.Create(Self);
  KundenDataSetEMAILADR := TStringField.Create(Self);
  KundenDataSetKENNWORT := TStringField.Create(Self);
  KundenDataSetDATUHR := TDateTimeField.Create(Self);
  KundenDataSetCONFIRMED := TDateTimeField.Create(Self);
  KundenDataSetUPC_EMAIL := TStringField.Create(Self);
  KundenDataSetDATUHR2 := TDateTimeField.Create(Self);
  KundenDataSetBESTAETIGUNG := TStringField.Create(Self);
  KundenDataSetAKTIV := TStringField.Create(Self);
  KundenDataSetSTATUS := TIntegerField.Create(Self);
  KundenDataSetANREDE := TStringField.Create(Self);
  KundenDataSetAKTITEL := TStringField.Create(Self);
  KundenDataSetIBAN := TStringField.Create(Self);
  KundenDataSetBIC := TStringField.Create(Self);
  KundenDataSetBANK := TStringField.Create(Self);
  KundenDataSetKZBERECHTIGUNG := TIntegerField.Create(Self);
  KundenDataSetADMINLEVEL := TIntegerField.Create(Self);
  KundenDataSetKDGRUPPE := TIntegerField.Create(Self);
  KundenDataSetZAHLBED := TIntegerField.Create(Self);
  KundenDataSetABRECHNUNGSART := TIntegerField.Create(Self);
  KundenDataSetCHIPID := TStringField.Create(Self);
  KundenDataSetPREISLISTE := TIntegerField.Create(Self);
  KundenDataSetKONTOINHABER := TStringField.Create(Self);
  KundenDataSetSF_1 := TIntegerField.Create(Self);
  KundenDataSetSF_2 := TIntegerField.Create(Self);
  KundenDataSetSF_3 := TIntegerField.Create(Self);
  KundenDataSetESF := TStringField.Create(Self);
  KundenDataSetSFA_1 := TStringField.Create(Self);
  KundenDataSetSFA_2 := TStringField.Create(Self);
  KundenDataSetSFA_3 := TStringField.Create(Self);
  KundenDataSource := TDataSource.Create(Self);
  XDataClient := TXDataWebClient.Create(Self);
  TeilnehmerDataSource := TDataSource.Create(Self);
  TeilnehmerDataSet := TXDataWebDataSet.Create(Self);
  TeilnehmerDataSetID := TIntegerField.Create(Self);
  TeilnehmerDataSetNL_KEY := TIntegerField.Create(Self);
  TeilnehmerDataSetKDID := TIntegerField.Create(Self);
  TeilnehmerDataSetLFDNO := TIntegerField.Create(Self);
  TeilnehmerDataSetNAME := TStringField.Create(Self);
  TeilnehmerDataSetEINRICHTUNG := TIntegerField.Create(Self);
  TeilnehmerDataSetGESCHLECHT := TStringField.Create(Self);
  TeilnehmerDataSetGEBURTSDATUM := TDateTimeField.Create(Self);
  TeilnehmerDataSetLKZ := TStringField.Create(Self);
  TeilnehmerDataSetLOESCHUNG := TDateTimeField.Create(Self);
  TeilnehmerDataSetPLAN1 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN2 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN3 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN4 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN5 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN6 := TIntegerField.Create(Self);
  TeilnehmerDataSetPLAN7 := TIntegerField.Create(Self);
  TeilnehmerDataSetEINRICHTUNGBEZ := TStringField.Create(Self);
  TeilnehmerDataSetTT := TIntegerField.Create(Self);
  TeilnehmerDataSetMM := TIntegerField.Create(Self);
  TeilnehmerDataSetJJ := TIntegerField.Create(Self);
  TeilnehmerDataSetKZ_BERECHTIGUNG := TIntegerField.Create(Self);
  TeilnehmerDataSetVARIANTE := TIntegerField.Create(Self);
  TeilnehmerDataSetTID := TIntegerField.Create(Self);
  TeilnehmerDataSetCHIPID := TStringField.Create(Self);
  TeilnehmerDataSetAKTIV := TIntegerField.Create(Self);
  TeilnehmerDataSetKZ_SUBACCOUNT := TIntegerField.Create(Self);
  TeilnehmerDataSetEMAILSUBACCOUNT := TStringField.Create(Self);
  TeilnehmerDataSetKENNWORT := TStringField.Create(Self);
  TeilnehmerDataSetUPC_NAME := TStringField.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);
  EinrichtungSource := TDataSource.Create(Self);
  EinrichtungDataSet := TXDataWebDataSet.Create(Self);
  EinrichtungDataSetID := TIntegerField.Create(Self);
  EinrichtungDataSetNL_KEY := TIntegerField.Create(Self);
  EinrichtungDataSetNUMMER := TIntegerField.Create(Self);
  EinrichtungDataSetBEZEICHNUNG := TStringField.Create(Self);
  EinrichtungDataSetEMAIL := TStringField.Create(Self);
  EinrichtungDataSetTELEFON := TStringField.Create(Self);
  EinrichtungDataSetBESTELL_ENDE := TDateTimeField.Create(Self);
  EinrichtungDataSetBESTELL_ENDE_ART := TIntegerField.Create(Self);
  AllergieDataSet := TXDataWebDataSet.Create(Self);
  AllergieDataSetTEILNEHMERID := TIntegerField.Create(Self);
  AllergieDataSetALLERGENID := TIntegerField.Create(Self);
  VerpflegungsartSource := TDataSource.Create(Self);
  VerpflegungsArtDataSet := TXDataWebDataSet.Create(Self);
  VerpflegungsArtDataSetID := TIntegerField.Create(Self);
  VerpflegungsArtDataSetNL_KEY := TIntegerField.Create(Self);
  VerpflegungsArtDataSetEID := TIntegerField.Create(Self);
  VerpflegungsArtDataSetLFDNO := TIntegerField.Create(Self);
  VerpflegungsArtDataSetBEZEICHNUNG := TStringField.Create(Self);
  VerpflegungsArtDataSetREIHENFOLGE := TIntegerField.Create(Self);
  VerpflegungsArtDataSetKUERZEL := TStringField.Create(Self);
  VariantenSource := TDataSource.Create(Self);
  VariantenDataSet := TXDataWebDataSet.Create(Self);
  IntegerField1 := TIntegerField.Create(Self);
  IntegerField2 := TIntegerField.Create(Self);
  IntegerField3 := TIntegerField.Create(Self);
  IntegerField4 := TIntegerField.Create(Self);
  StringField1 := TStringField.Create(Self);
  IntegerField5 := TIntegerField.Create(Self);
  StringField2 := TStringField.Create(Self);
  XDataWebClient2 := TXDataWebClient.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);
  SpeiseplanSource := TDataSource.Create(Self);
  Speiseplandataset := TXDataWebDataSet.Create(Self);
  SpeiseplandatasetTID := TIntegerField.Create(Self);
  SpeiseplandatasetDATUM := TDateTimeField.Create(Self);
  SpeiseplandatasetSPTID := TIntegerField.Create(Self);
  SpeiseplandatasetARTIKEL := TIntegerField.Create(Self);
  SpeiseplandatasetBEZEICHNUNG := TStringField.Create(Self);
  SpeiseplandatasetBEZEICHNUNG2 := TStringField.Create(Self);
  SpeiseplandatasetKZ_BESTELLT := TIntegerField.Create(Self);
  SpeiseplandatasetBESTELLT := TDateTimeField.Create(Self);
  SpeiseplandatasetAUSGABE := TDateTimeField.Create(Self);
  SpeiseplandatasetKZ_AUSGABE := TIntegerField.Create(Self);
  SpeiseplandatasetNL_KEY := TIntegerField.Create(Self);
  SpeiseplandatasetEID := TIntegerField.Create(Self);
  SpeiseplandatasetENO := TIntegerField.Create(Self);
  SpeiseplandatasetSTORNO := TDateTimeField.Create(Self);
  SpeiseplandatasetSTORNO_KZ := TIntegerField.Create(Self);
  SpeiseplandatasetDatumD := TStringField.Create(Self);
  SpeiseplandatasetDatumD2 := TStringField.Create(Self);
  SpeiseplandatasetStatus := TStringField.Create(Self);
  SpeiseplandatasetGRUPPE := TIntegerField.Create(Self);
  SpeiseplandatasetKZ_AUSWAHL := TIntegerField.Create(Self);
  SpeiseplandatasetARTIKELID := TIntegerField.Create(Self);
  SpeiseplandatasetBEWERTUNG1 := TIntegerField.Create(Self);
  SpeiseplandatasetBEWERTUNG2 := TIntegerField.Create(Self);
  SpeiseplandatasetBEWERTUNG3 := TIntegerField.Create(Self);
  SpeiseplandatasetBEMERKUNG := TStringField.Create(Self);
  SpeiseplandatasetBezGes := TStringField.Create(Self);
  SpeiseplandatasetBildUrl := TStringField.Create(Self);
  XDataWebClientAuth := TXDataWebClient.Create(Self);
  XDataWebConnection2 := TXDataWebConnection.Create(Self);
  SicherheitsfragenDataSet := TXDataWebDataSet.Create(Self);
  SicherheitsfragenDataSetID := TIntegerField.Create(Self);
  SicherheitsfragenDataSetNUMMER := TIntegerField.Create(Self);
  SicherheitsfragenDataSetFRAGE := TStringField.Create(Self);
  XDataWebConnection3 := TXDataWebConnection.Create(Self);
  XDataWebClientApiService := TXDataWebClient.Create(Self);
  EInrichtungSummenSource := TDataSource.Create(Self);
  EinrichtungsummenDataSet := TXDataWebDataSet.Create(Self);
  EinrichtungsummenDataSetID := TIntegerField.Create(Self);
  EinrichtungsummenDataSetDATUM := TDateTimeField.Create(Self);
  EinrichtungsummenDataSetARTIKEL := TIntegerField.Create(Self);
  EinrichtungsummenDataSetBEZEICHNUNG := TStringField.Create(Self);
  EinrichtungsummenDataSetBESTELLT := TIntegerField.Create(Self);
  EinrichtungsummenDataSetGELIEFERT := TIntegerField.Create(Self);
  EinrichtungsummenDataSetAUSGABE := TIntegerField.Create(Self);
  ArtikelDataSource := TDataSource.Create(Self);
  ArtikelDataSet := TXDataWebDataSet.Create(Self);
  ArtikelDataSetID := TIntegerField.Create(Self);
  ArtikelDataSetNL_KEY := TIntegerField.Create(Self);
  ArtikelDataSetNUMMER := TIntegerField.Create(Self);
  ArtikelDataSetBEZEICHNUNG := TStringField.Create(Self);
  ArtikelDataSetBEZEICHNUNG2 := TStringField.Create(Self);
  ArtikelDataSetART := TIntegerField.Create(Self);
  ArtikelDataSetWG := TIntegerField.Create(Self);
  ArtikelDataSetME := TIntegerField.Create(Self);
  ArtikelDataSetKZ_KALK := TIntegerField.Create(Self);
  ArtikelDataSetMWST := TIntegerField.Create(Self);
  ArtikelDataSetBRUTTO := TFloatField.Create(Self);
  ArtikelDataSetNETTO := TFloatField.Create(Self);
  ArtikelDataSetMATPREIS := TFloatField.Create(Self);
  ArtikelDataSetLOHNKOSTEN := TFloatField.Create(Self);
  ArtikelDataSetSONSTIGEKOSTEN := TFloatField.Create(Self);
  ArtikelDataSetKCAL := TFloatField.Create(Self);
  ArtikelDataSetKJOULE := TFloatField.Create(Self);
  ArtikelDataSetEIWEISS := TFloatField.Create(Self);
  ArtikelDataSetKOHLENHYDRATE := TFloatField.Create(Self);
  ArtikelDataSetZUCKER := TFloatField.Create(Self);
  ArtikelDataSetFETT := TFloatField.Create(Self);
  ArtikelDataSetFETTGESAETTIGT := TFloatField.Create(Self);
  ArtikelDataSetSALZ := TFloatField.Create(Self);
  ArtikelDataSetKZ_MINDESTABSTAND := TIntegerField.Create(Self);
  ArtikelDataSetBezGesamt := TStringField.Create(Self);
  BilderDataSet := TXDataWebDataSet.Create(Self);
  BilderDataSetID := TIntegerField.Create(Self);
  BilderDataSetART := TIntegerField.Create(Self);
  BilderDataSetID2 := TIntegerField.Create(Self);
  BilderDataSetLFDNO := TIntegerField.Create(Self);
  BilderDataSetBILD := TBlobField.Create(Self);
  BilderDataSetURL_PFAD := TStringField.Create(Self);
  BilderDataSetDATEITYP := TStringField.Create(Self);
  BilderDataSetBEMERKUNG := TStringField.Create(Self);
  BilderDataSource := TDataSource.Create(Self);
  Artikel_LTDataSet := TXDataWebDataSet.Create(Self);
  Artikel_LTDataSetID := TIntegerField.Create(Self);
  Artikel_LTDataSetTXT := TBlobField.Create(Self);
  Artikel_LTSource := TDataSource.Create(Self);
  MWSTDataSet := TXDataWebDataSet.Create(Self);

  XDataWebConnection1.BeforeLoadDFMValues;
  KundenDataSet.BeforeLoadDFMValues;
  KundenDataSetID.BeforeLoadDFMValues;
  KundenDataSetNL_KEY.BeforeLoadDFMValues;
  KundenDataSetKDNO.BeforeLoadDFMValues;
  KundenDataSetNAME.BeforeLoadDFMValues;
  KundenDataSetNAME2.BeforeLoadDFMValues;
  KundenDataSetSTRASSE.BeforeLoadDFMValues;
  KundenDataSetPLZ.BeforeLoadDFMValues;
  KundenDataSetORT.BeforeLoadDFMValues;
  KundenDataSetTELEFON.BeforeLoadDFMValues;
  KundenDataSetHANDY.BeforeLoadDFMValues;
  KundenDataSetEMAILADR.BeforeLoadDFMValues;
  KundenDataSetKENNWORT.BeforeLoadDFMValues;
  KundenDataSetDATUHR.BeforeLoadDFMValues;
  KundenDataSetCONFIRMED.BeforeLoadDFMValues;
  KundenDataSetUPC_EMAIL.BeforeLoadDFMValues;
  KundenDataSetDATUHR2.BeforeLoadDFMValues;
  KundenDataSetBESTAETIGUNG.BeforeLoadDFMValues;
  KundenDataSetAKTIV.BeforeLoadDFMValues;
  KundenDataSetSTATUS.BeforeLoadDFMValues;
  KundenDataSetANREDE.BeforeLoadDFMValues;
  KundenDataSetAKTITEL.BeforeLoadDFMValues;
  KundenDataSetIBAN.BeforeLoadDFMValues;
  KundenDataSetBIC.BeforeLoadDFMValues;
  KundenDataSetBANK.BeforeLoadDFMValues;
  KundenDataSetKZBERECHTIGUNG.BeforeLoadDFMValues;
  KundenDataSetADMINLEVEL.BeforeLoadDFMValues;
  KundenDataSetKDGRUPPE.BeforeLoadDFMValues;
  KundenDataSetZAHLBED.BeforeLoadDFMValues;
  KundenDataSetABRECHNUNGSART.BeforeLoadDFMValues;
  KundenDataSetCHIPID.BeforeLoadDFMValues;
  KundenDataSetPREISLISTE.BeforeLoadDFMValues;
  KundenDataSetKONTOINHABER.BeforeLoadDFMValues;
  KundenDataSetSF_1.BeforeLoadDFMValues;
  KundenDataSetSF_2.BeforeLoadDFMValues;
  KundenDataSetSF_3.BeforeLoadDFMValues;
  KundenDataSetESF.BeforeLoadDFMValues;
  KundenDataSetSFA_1.BeforeLoadDFMValues;
  KundenDataSetSFA_2.BeforeLoadDFMValues;
  KundenDataSetSFA_3.BeforeLoadDFMValues;
  KundenDataSource.BeforeLoadDFMValues;
  XDataClient.BeforeLoadDFMValues;
  TeilnehmerDataSource.BeforeLoadDFMValues;
  TeilnehmerDataSet.BeforeLoadDFMValues;
  TeilnehmerDataSetID.BeforeLoadDFMValues;
  TeilnehmerDataSetNL_KEY.BeforeLoadDFMValues;
  TeilnehmerDataSetKDID.BeforeLoadDFMValues;
  TeilnehmerDataSetLFDNO.BeforeLoadDFMValues;
  TeilnehmerDataSetNAME.BeforeLoadDFMValues;
  TeilnehmerDataSetEINRICHTUNG.BeforeLoadDFMValues;
  TeilnehmerDataSetGESCHLECHT.BeforeLoadDFMValues;
  TeilnehmerDataSetGEBURTSDATUM.BeforeLoadDFMValues;
  TeilnehmerDataSetLKZ.BeforeLoadDFMValues;
  TeilnehmerDataSetLOESCHUNG.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN1.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN2.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN3.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN4.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN5.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN6.BeforeLoadDFMValues;
  TeilnehmerDataSetPLAN7.BeforeLoadDFMValues;
  TeilnehmerDataSetEINRICHTUNGBEZ.BeforeLoadDFMValues;
  TeilnehmerDataSetTT.BeforeLoadDFMValues;
  TeilnehmerDataSetMM.BeforeLoadDFMValues;
  TeilnehmerDataSetJJ.BeforeLoadDFMValues;
  TeilnehmerDataSetKZ_BERECHTIGUNG.BeforeLoadDFMValues;
  TeilnehmerDataSetVARIANTE.BeforeLoadDFMValues;
  TeilnehmerDataSetTID.BeforeLoadDFMValues;
  TeilnehmerDataSetCHIPID.BeforeLoadDFMValues;
  TeilnehmerDataSetAKTIV.BeforeLoadDFMValues;
  TeilnehmerDataSetKZ_SUBACCOUNT.BeforeLoadDFMValues;
  TeilnehmerDataSetEMAILSUBACCOUNT.BeforeLoadDFMValues;
  TeilnehmerDataSetKENNWORT.BeforeLoadDFMValues;
  TeilnehmerDataSetUPC_NAME.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  EinrichtungSource.BeforeLoadDFMValues;
  EinrichtungDataSet.BeforeLoadDFMValues;
  EinrichtungDataSetID.BeforeLoadDFMValues;
  EinrichtungDataSetNL_KEY.BeforeLoadDFMValues;
  EinrichtungDataSetNUMMER.BeforeLoadDFMValues;
  EinrichtungDataSetBEZEICHNUNG.BeforeLoadDFMValues;
  EinrichtungDataSetEMAIL.BeforeLoadDFMValues;
  EinrichtungDataSetTELEFON.BeforeLoadDFMValues;
  EinrichtungDataSetBESTELL_ENDE.BeforeLoadDFMValues;
  EinrichtungDataSetBESTELL_ENDE_ART.BeforeLoadDFMValues;
  AllergieDataSet.BeforeLoadDFMValues;
  AllergieDataSetTEILNEHMERID.BeforeLoadDFMValues;
  AllergieDataSetALLERGENID.BeforeLoadDFMValues;
  VerpflegungsartSource.BeforeLoadDFMValues;
  VerpflegungsArtDataSet.BeforeLoadDFMValues;
  VerpflegungsArtDataSetID.BeforeLoadDFMValues;
  VerpflegungsArtDataSetNL_KEY.BeforeLoadDFMValues;
  VerpflegungsArtDataSetEID.BeforeLoadDFMValues;
  VerpflegungsArtDataSetLFDNO.BeforeLoadDFMValues;
  VerpflegungsArtDataSetBEZEICHNUNG.BeforeLoadDFMValues;
  VerpflegungsArtDataSetREIHENFOLGE.BeforeLoadDFMValues;
  VerpflegungsArtDataSetKUERZEL.BeforeLoadDFMValues;
  VariantenSource.BeforeLoadDFMValues;
  VariantenDataSet.BeforeLoadDFMValues;
  IntegerField1.BeforeLoadDFMValues;
  IntegerField2.BeforeLoadDFMValues;
  IntegerField3.BeforeLoadDFMValues;
  IntegerField4.BeforeLoadDFMValues;
  StringField1.BeforeLoadDFMValues;
  IntegerField5.BeforeLoadDFMValues;
  StringField2.BeforeLoadDFMValues;
  XDataWebClient2.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  SpeiseplanSource.BeforeLoadDFMValues;
  Speiseplandataset.BeforeLoadDFMValues;
  SpeiseplandatasetTID.BeforeLoadDFMValues;
  SpeiseplandatasetDATUM.BeforeLoadDFMValues;
  SpeiseplandatasetSPTID.BeforeLoadDFMValues;
  SpeiseplandatasetARTIKEL.BeforeLoadDFMValues;
  SpeiseplandatasetBEZEICHNUNG.BeforeLoadDFMValues;
  SpeiseplandatasetBEZEICHNUNG2.BeforeLoadDFMValues;
  SpeiseplandatasetKZ_BESTELLT.BeforeLoadDFMValues;
  SpeiseplandatasetBESTELLT.BeforeLoadDFMValues;
  SpeiseplandatasetAUSGABE.BeforeLoadDFMValues;
  SpeiseplandatasetKZ_AUSGABE.BeforeLoadDFMValues;
  SpeiseplandatasetNL_KEY.BeforeLoadDFMValues;
  SpeiseplandatasetEID.BeforeLoadDFMValues;
  SpeiseplandatasetENO.BeforeLoadDFMValues;
  SpeiseplandatasetSTORNO.BeforeLoadDFMValues;
  SpeiseplandatasetSTORNO_KZ.BeforeLoadDFMValues;
  SpeiseplandatasetDatumD.BeforeLoadDFMValues;
  SpeiseplandatasetDatumD2.BeforeLoadDFMValues;
  SpeiseplandatasetStatus.BeforeLoadDFMValues;
  SpeiseplandatasetGRUPPE.BeforeLoadDFMValues;
  SpeiseplandatasetKZ_AUSWAHL.BeforeLoadDFMValues;
  SpeiseplandatasetARTIKELID.BeforeLoadDFMValues;
  SpeiseplandatasetBEWERTUNG1.BeforeLoadDFMValues;
  SpeiseplandatasetBEWERTUNG2.BeforeLoadDFMValues;
  SpeiseplandatasetBEWERTUNG3.BeforeLoadDFMValues;
  SpeiseplandatasetBEMERKUNG.BeforeLoadDFMValues;
  SpeiseplandatasetBezGes.BeforeLoadDFMValues;
  SpeiseplandatasetBildUrl.BeforeLoadDFMValues;
  XDataWebClientAuth.BeforeLoadDFMValues;
  XDataWebConnection2.BeforeLoadDFMValues;
  SicherheitsfragenDataSet.BeforeLoadDFMValues;
  SicherheitsfragenDataSetID.BeforeLoadDFMValues;
  SicherheitsfragenDataSetNUMMER.BeforeLoadDFMValues;
  SicherheitsfragenDataSetFRAGE.BeforeLoadDFMValues;
  XDataWebConnection3.BeforeLoadDFMValues;
  XDataWebClientApiService.BeforeLoadDFMValues;
  EInrichtungSummenSource.BeforeLoadDFMValues;
  EinrichtungsummenDataSet.BeforeLoadDFMValues;
  EinrichtungsummenDataSetID.BeforeLoadDFMValues;
  EinrichtungsummenDataSetDATUM.BeforeLoadDFMValues;
  EinrichtungsummenDataSetARTIKEL.BeforeLoadDFMValues;
  EinrichtungsummenDataSetBEZEICHNUNG.BeforeLoadDFMValues;
  EinrichtungsummenDataSetBESTELLT.BeforeLoadDFMValues;
  EinrichtungsummenDataSetGELIEFERT.BeforeLoadDFMValues;
  EinrichtungsummenDataSetAUSGABE.BeforeLoadDFMValues;
  ArtikelDataSource.BeforeLoadDFMValues;
  ArtikelDataSet.BeforeLoadDFMValues;
  ArtikelDataSetID.BeforeLoadDFMValues;
  ArtikelDataSetNL_KEY.BeforeLoadDFMValues;
  ArtikelDataSetNUMMER.BeforeLoadDFMValues;
  ArtikelDataSetBEZEICHNUNG.BeforeLoadDFMValues;
  ArtikelDataSetBEZEICHNUNG2.BeforeLoadDFMValues;
  ArtikelDataSetART.BeforeLoadDFMValues;
  ArtikelDataSetWG.BeforeLoadDFMValues;
  ArtikelDataSetME.BeforeLoadDFMValues;
  ArtikelDataSetKZ_KALK.BeforeLoadDFMValues;
  ArtikelDataSetMWST.BeforeLoadDFMValues;
  ArtikelDataSetBRUTTO.BeforeLoadDFMValues;
  ArtikelDataSetNETTO.BeforeLoadDFMValues;
  ArtikelDataSetMATPREIS.BeforeLoadDFMValues;
  ArtikelDataSetLOHNKOSTEN.BeforeLoadDFMValues;
  ArtikelDataSetSONSTIGEKOSTEN.BeforeLoadDFMValues;
  ArtikelDataSetKCAL.BeforeLoadDFMValues;
  ArtikelDataSetKJOULE.BeforeLoadDFMValues;
  ArtikelDataSetEIWEISS.BeforeLoadDFMValues;
  ArtikelDataSetKOHLENHYDRATE.BeforeLoadDFMValues;
  ArtikelDataSetZUCKER.BeforeLoadDFMValues;
  ArtikelDataSetFETT.BeforeLoadDFMValues;
  ArtikelDataSetFETTGESAETTIGT.BeforeLoadDFMValues;
  ArtikelDataSetSALZ.BeforeLoadDFMValues;
  ArtikelDataSetKZ_MINDESTABSTAND.BeforeLoadDFMValues;
  ArtikelDataSetBezGesamt.BeforeLoadDFMValues;
  BilderDataSet.BeforeLoadDFMValues;
  BilderDataSetID.BeforeLoadDFMValues;
  BilderDataSetART.BeforeLoadDFMValues;
  BilderDataSetID2.BeforeLoadDFMValues;
  BilderDataSetLFDNO.BeforeLoadDFMValues;
  BilderDataSetBILD.BeforeLoadDFMValues;
  BilderDataSetURL_PFAD.BeforeLoadDFMValues;
  BilderDataSetDATEITYP.BeforeLoadDFMValues;
  BilderDataSetBEMERKUNG.BeforeLoadDFMValues;
  BilderDataSource.BeforeLoadDFMValues;
  Artikel_LTDataSet.BeforeLoadDFMValues;
  Artikel_LTDataSetID.BeforeLoadDFMValues;
  Artikel_LTDataSetTXT.BeforeLoadDFMValues;
  Artikel_LTSource.BeforeLoadDFMValues;
  MWSTDataSet.BeforeLoadDFMValues;
  try
    Name := 'DataModule1';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 420;
    Width := 834;
    XDataWebConnection1.SetParentComponent(Self);
    XDataWebConnection1.Name := 'XDataWebConnection1';
    XDataWebConnection1.URL := 'https://kwspeiseplan.ddns.net:443/KWSpeiseplan/api';
    SetEvent(XDataWebConnection1, Self, 'OnError', 'XDataWebConnection1Error');
    SetEvent(XDataWebConnection1, Self, 'OnRequest', 'XDataWebConnection1Request');
    XDataWebConnection1.Left := 48;
    XDataWebConnection1.Top := 24;
    KundenDataSet.SetParentComponent(Self);
    KundenDataSet.Name := 'KundenDataSet';
    KundenDataSet.EntitySetName := 'KUNDEN';
    KundenDataSet.Connection := XDataWebConnection1;
    KundenDataSet.Left := 272;
    KundenDataSet.Top := 24;
    KundenDataSetID.SetParentComponent(KundenDataSet);
    KundenDataSetID.Name := 'KundenDataSetID';
    KundenDataSetID.FieldName := 'ID';
    KundenDataSetID.Required := True;
    KundenDataSetNL_KEY.SetParentComponent(KundenDataSet);
    KundenDataSetNL_KEY.Name := 'KundenDataSetNL_KEY';
    KundenDataSetNL_KEY.FieldName := 'NL_KEY';
    KundenDataSetNL_KEY.Required := True;
    KundenDataSetKDNO.SetParentComponent(KundenDataSet);
    KundenDataSetKDNO.Name := 'KundenDataSetKDNO';
    KundenDataSetKDNO.FieldName := 'KDNO';
    KundenDataSetKDNO.Required := True;
    KundenDataSetNAME.SetParentComponent(KundenDataSet);
    KundenDataSetNAME.Name := 'KundenDataSetNAME';
    KundenDataSetNAME.FieldName := 'NAME';
    KundenDataSetNAME.Size := 40;
    KundenDataSetNAME2.SetParentComponent(KundenDataSet);
    KundenDataSetNAME2.Name := 'KundenDataSetNAME2';
    KundenDataSetNAME2.FieldName := 'NAME2';
    KundenDataSetNAME2.Size := 40;
    KundenDataSetSTRASSE.SetParentComponent(KundenDataSet);
    KundenDataSetSTRASSE.Name := 'KundenDataSetSTRASSE';
    KundenDataSetSTRASSE.FieldName := 'STRASSE';
    KundenDataSetSTRASSE.Size := 40;
    KundenDataSetPLZ.SetParentComponent(KundenDataSet);
    KundenDataSetPLZ.Name := 'KundenDataSetPLZ';
    KundenDataSetPLZ.FieldName := 'PLZ';
    KundenDataSetPLZ.Size := 10;
    KundenDataSetORT.SetParentComponent(KundenDataSet);
    KundenDataSetORT.Name := 'KundenDataSetORT';
    KundenDataSetORT.FieldName := 'ORT';
    KundenDataSetORT.Size := 40;
    KundenDataSetTELEFON.SetParentComponent(KundenDataSet);
    KundenDataSetTELEFON.Name := 'KundenDataSetTELEFON';
    KundenDataSetTELEFON.FieldName := 'TELEFON';
    KundenDataSetTELEFON.Size := 30;
    KundenDataSetHANDY.SetParentComponent(KundenDataSet);
    KundenDataSetHANDY.Name := 'KundenDataSetHANDY';
    KundenDataSetHANDY.FieldName := 'HANDY';
    KundenDataSetHANDY.Size := 30;
    KundenDataSetEMAILADR.SetParentComponent(KundenDataSet);
    KundenDataSetEMAILADR.Name := 'KundenDataSetEMAILADR';
    KundenDataSetEMAILADR.FieldName := 'EMAILADR';
    KundenDataSetEMAILADR.Size := 60;
    KundenDataSetKENNWORT.SetParentComponent(KundenDataSet);
    KundenDataSetKENNWORT.Name := 'KundenDataSetKENNWORT';
    KundenDataSetKENNWORT.FieldName := 'KENNWORT';
    KundenDataSetDATUHR.SetParentComponent(KundenDataSet);
    KundenDataSetDATUHR.Name := 'KundenDataSetDATUHR';
    KundenDataSetDATUHR.FieldName := 'DATUHR';
    KundenDataSetCONFIRMED.SetParentComponent(KundenDataSet);
    KundenDataSetCONFIRMED.Name := 'KundenDataSetCONFIRMED';
    KundenDataSetCONFIRMED.FieldName := 'CONFIRMED';
    KundenDataSetUPC_EMAIL.SetParentComponent(KundenDataSet);
    KundenDataSetUPC_EMAIL.Name := 'KundenDataSetUPC_EMAIL';
    KundenDataSetUPC_EMAIL.FieldName := 'UPC_EMAIL';
    KundenDataSetUPC_EMAIL.Size := 60;
    KundenDataSetDATUHR2.SetParentComponent(KundenDataSet);
    KundenDataSetDATUHR2.Name := 'KundenDataSetDATUHR2';
    KundenDataSetDATUHR2.FieldName := 'DATUHR2';
    KundenDataSetBESTAETIGUNG.SetParentComponent(KundenDataSet);
    KundenDataSetBESTAETIGUNG.Name := 'KundenDataSetBESTAETIGUNG';
    KundenDataSetBESTAETIGUNG.FieldName := 'BESTAETIGUNG';
    KundenDataSetBESTAETIGUNG.Size := 6;
    KundenDataSetAKTIV.SetParentComponent(KundenDataSet);
    KundenDataSetAKTIV.Name := 'KundenDataSetAKTIV';
    KundenDataSetAKTIV.FieldName := 'AKTIV';
    KundenDataSetAKTIV.Size := 1;
    KundenDataSetSTATUS.SetParentComponent(KundenDataSet);
    KundenDataSetSTATUS.Name := 'KundenDataSetSTATUS';
    KundenDataSetSTATUS.FieldName := 'STATUS';
    KundenDataSetSTATUS.Required := True;
    KundenDataSetANREDE.SetParentComponent(KundenDataSet);
    KundenDataSetANREDE.Name := 'KundenDataSetANREDE';
    KundenDataSetANREDE.FieldName := 'ANREDE';
    KundenDataSetAKTITEL.SetParentComponent(KundenDataSet);
    KundenDataSetAKTITEL.Name := 'KundenDataSetAKTITEL';
    KundenDataSetAKTITEL.FieldName := 'AKTITEL';
    KundenDataSetIBAN.SetParentComponent(KundenDataSet);
    KundenDataSetIBAN.Name := 'KundenDataSetIBAN';
    KundenDataSetIBAN.FieldName := 'IBAN';
    KundenDataSetIBAN.Size := 30;
    KundenDataSetBIC.SetParentComponent(KundenDataSet);
    KundenDataSetBIC.Name := 'KundenDataSetBIC';
    KundenDataSetBIC.FieldName := 'BIC';
    KundenDataSetBANK.SetParentComponent(KundenDataSet);
    KundenDataSetBANK.Name := 'KundenDataSetBANK';
    KundenDataSetBANK.FieldName := 'BANK';
    KundenDataSetBANK.Size := 40;
    KundenDataSetKZBERECHTIGUNG.SetParentComponent(KundenDataSet);
    KundenDataSetKZBERECHTIGUNG.Name := 'KundenDataSetKZBERECHTIGUNG';
    KundenDataSetKZBERECHTIGUNG.FieldName := 'KZBERECHTIGUNG';
    KundenDataSetADMINLEVEL.SetParentComponent(KundenDataSet);
    KundenDataSetADMINLEVEL.Name := 'KundenDataSetADMINLEVEL';
    KundenDataSetADMINLEVEL.FieldName := 'ADMINLEVEL';
    KundenDataSetADMINLEVEL.Required := True;
    KundenDataSetKDGRUPPE.SetParentComponent(KundenDataSet);
    KundenDataSetKDGRUPPE.Name := 'KundenDataSetKDGRUPPE';
    KundenDataSetKDGRUPPE.FieldName := 'KDGRUPPE';
    KundenDataSetKDGRUPPE.Required := True;
    KundenDataSetZAHLBED.SetParentComponent(KundenDataSet);
    KundenDataSetZAHLBED.Name := 'KundenDataSetZAHLBED';
    KundenDataSetZAHLBED.FieldName := 'ZAHLBED';
    KundenDataSetZAHLBED.Required := True;
    KundenDataSetABRECHNUNGSART.SetParentComponent(KundenDataSet);
    KundenDataSetABRECHNUNGSART.Name := 'KundenDataSetABRECHNUNGSART';
    KundenDataSetABRECHNUNGSART.FieldName := 'ABRECHNUNGSART';
    KundenDataSetABRECHNUNGSART.Required := True;
    KundenDataSetCHIPID.SetParentComponent(KundenDataSet);
    KundenDataSetCHIPID.Name := 'KundenDataSetCHIPID';
    KundenDataSetCHIPID.FieldName := 'CHIPID';
    KundenDataSetPREISLISTE.SetParentComponent(KundenDataSet);
    KundenDataSetPREISLISTE.Name := 'KundenDataSetPREISLISTE';
    KundenDataSetPREISLISTE.FieldName := 'PREISLISTE';
    KundenDataSetPREISLISTE.Required := True;
    KundenDataSetKONTOINHABER.SetParentComponent(KundenDataSet);
    KundenDataSetKONTOINHABER.Name := 'KundenDataSetKONTOINHABER';
    KundenDataSetKONTOINHABER.FieldName := 'KONTOINHABER';
    KundenDataSetKONTOINHABER.Size := 60;
    KundenDataSetSF_1.SetParentComponent(KundenDataSet);
    KundenDataSetSF_1.Name := 'KundenDataSetSF_1';
    KundenDataSetSF_1.FieldName := 'SF_1';
    KundenDataSetSF_2.SetParentComponent(KundenDataSet);
    KundenDataSetSF_2.Name := 'KundenDataSetSF_2';
    KundenDataSetSF_2.FieldName := 'SF_2';
    KundenDataSetSF_3.SetParentComponent(KundenDataSet);
    KundenDataSetSF_3.Name := 'KundenDataSetSF_3';
    KundenDataSetSF_3.FieldName := 'SF_3';
    KundenDataSetESF.SetParentComponent(KundenDataSet);
    KundenDataSetESF.Name := 'KundenDataSetESF';
    KundenDataSetESF.FieldName := 'ESF';
    KundenDataSetESF.Size := 150;
    KundenDataSetSFA_1.SetParentComponent(KundenDataSet);
    KundenDataSetSFA_1.Name := 'KundenDataSetSFA_1';
    KundenDataSetSFA_1.FieldName := 'SFA_1';
    KundenDataSetSFA_1.Size := 40;
    KundenDataSetSFA_2.SetParentComponent(KundenDataSet);
    KundenDataSetSFA_2.Name := 'KundenDataSetSFA_2';
    KundenDataSetSFA_2.FieldName := 'SFA_2';
    KundenDataSetSFA_2.Size := 40;
    KundenDataSetSFA_3.SetParentComponent(KundenDataSet);
    KundenDataSetSFA_3.Name := 'KundenDataSetSFA_3';
    KundenDataSetSFA_3.FieldName := 'SFA_3';
    KundenDataSetSFA_3.Size := 40;
    KundenDataSource.SetParentComponent(Self);
    KundenDataSource.Name := 'KundenDataSource';
    KundenDataSource.DataSet := KundenDataSet;
    KundenDataSource.Left := 176;
    KundenDataSource.Top := 24;
    XDataClient.SetParentComponent(Self);
    XDataClient.Name := 'XDataClient';
    XDataClient.Connection := XDataWebConnection1;
    XDataClient.Left := 47;
    XDataClient.Top := 81;
    TeilnehmerDataSource.SetParentComponent(Self);
    TeilnehmerDataSource.Name := 'TeilnehmerDataSource';
    TeilnehmerDataSource.DataSet := TeilnehmerDataSet;
    TeilnehmerDataSource.Left := 176;
    TeilnehmerDataSource.Top := 88;
    TeilnehmerDataSet.SetParentComponent(Self);
    TeilnehmerDataSet.Name := 'TeilnehmerDataSet';
    TeilnehmerDataSet.EntitySetName := 'TEILNEHMER';
    TeilnehmerDataSet.Connection := XDataWebConnection1;
    TeilnehmerDataSet.ServerRecordCountMode := smInlineCount;
    TeilnehmerDataSet.Left := 264;
    TeilnehmerDataSet.Top := 88;
    TeilnehmerDataSetID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetID.Name := 'TeilnehmerDataSetID';
    TeilnehmerDataSetID.FieldName := 'ID';
    TeilnehmerDataSetID.Required := True;
    TeilnehmerDataSetNL_KEY.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetNL_KEY.Name := 'TeilnehmerDataSetNL_KEY';
    TeilnehmerDataSetNL_KEY.FieldName := 'NL_KEY';
    TeilnehmerDataSetNL_KEY.Required := True;
    TeilnehmerDataSetKDID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetKDID.Name := 'TeilnehmerDataSetKDID';
    TeilnehmerDataSetKDID.FieldName := 'KDID';
    TeilnehmerDataSetKDID.Required := True;
    TeilnehmerDataSetLFDNO.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetLFDNO.Name := 'TeilnehmerDataSetLFDNO';
    TeilnehmerDataSetLFDNO.FieldName := 'LFDNO';
    TeilnehmerDataSetLFDNO.Required := True;
    TeilnehmerDataSetNAME.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetNAME.Name := 'TeilnehmerDataSetNAME';
    TeilnehmerDataSetNAME.FieldName := 'NAME';
    TeilnehmerDataSetNAME.Size := 60;
    TeilnehmerDataSetEINRICHTUNG.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetEINRICHTUNG.Name := 'TeilnehmerDataSetEINRICHTUNG';
    TeilnehmerDataSetEINRICHTUNG.FieldName := 'EINRICHTUNG';
    TeilnehmerDataSetGESCHLECHT.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetGESCHLECHT.Name := 'TeilnehmerDataSetGESCHLECHT';
    TeilnehmerDataSetGESCHLECHT.FieldName := 'GESCHLECHT';
    TeilnehmerDataSetGEBURTSDATUM.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetGEBURTSDATUM.Name := 'TeilnehmerDataSetGEBURTSDATUM';
    TeilnehmerDataSetGEBURTSDATUM.FieldName := 'GEBURTSDATUM';
    TeilnehmerDataSetLKZ.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetLKZ.Name := 'TeilnehmerDataSetLKZ';
    TeilnehmerDataSetLKZ.FieldName := 'LKZ';
    TeilnehmerDataSetLKZ.Size := 1;
    TeilnehmerDataSetLOESCHUNG.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetLOESCHUNG.Name := 'TeilnehmerDataSetLOESCHUNG';
    TeilnehmerDataSetLOESCHUNG.FieldName := 'LOESCHUNG';
    TeilnehmerDataSetPLAN1.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN1.Name := 'TeilnehmerDataSetPLAN1';
    TeilnehmerDataSetPLAN1.FieldName := 'PLAN1';
    TeilnehmerDataSetPLAN2.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN2.Name := 'TeilnehmerDataSetPLAN2';
    TeilnehmerDataSetPLAN2.FieldName := 'PLAN2';
    TeilnehmerDataSetPLAN3.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN3.Name := 'TeilnehmerDataSetPLAN3';
    TeilnehmerDataSetPLAN3.FieldName := 'PLAN3';
    TeilnehmerDataSetPLAN4.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN4.Name := 'TeilnehmerDataSetPLAN4';
    TeilnehmerDataSetPLAN4.FieldName := 'PLAN4';
    TeilnehmerDataSetPLAN5.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN5.Name := 'TeilnehmerDataSetPLAN5';
    TeilnehmerDataSetPLAN5.FieldName := 'PLAN5';
    TeilnehmerDataSetPLAN6.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN6.Name := 'TeilnehmerDataSetPLAN6';
    TeilnehmerDataSetPLAN6.FieldName := 'PLAN6';
    TeilnehmerDataSetPLAN7.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetPLAN7.Name := 'TeilnehmerDataSetPLAN7';
    TeilnehmerDataSetPLAN7.FieldName := 'PLAN7';
    TeilnehmerDataSetEINRICHTUNGBEZ.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetEINRICHTUNGBEZ.Name := 'TeilnehmerDataSetEINRICHTUNGBEZ';
    TeilnehmerDataSetEINRICHTUNGBEZ.FieldName := 'EINRICHTUNGBEZ';
    TeilnehmerDataSetEINRICHTUNGBEZ.Size := 60;
    TeilnehmerDataSetTT.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetTT.Name := 'TeilnehmerDataSetTT';
    TeilnehmerDataSetTT.FieldName := 'TT';
    TeilnehmerDataSetMM.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetMM.Name := 'TeilnehmerDataSetMM';
    TeilnehmerDataSetMM.FieldName := 'MM';
    TeilnehmerDataSetJJ.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetJJ.Name := 'TeilnehmerDataSetJJ';
    TeilnehmerDataSetJJ.FieldName := 'JJ';
    TeilnehmerDataSetKZ_BERECHTIGUNG.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetKZ_BERECHTIGUNG.Name := 'TeilnehmerDataSetKZ_BERECHTIGUNG';
    TeilnehmerDataSetKZ_BERECHTIGUNG.FieldName := 'KZ_BERECHTIGUNG';
    TeilnehmerDataSetVARIANTE.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetVARIANTE.Name := 'TeilnehmerDataSetVARIANTE';
    TeilnehmerDataSetVARIANTE.FieldName := 'VARIANTE';
    TeilnehmerDataSetVARIANTE.Required := True;
    TeilnehmerDataSetTID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetTID.Name := 'TeilnehmerDataSetTID';
    TeilnehmerDataSetTID.FieldName := 'TID';
    TeilnehmerDataSetTID.Required := True;
    TeilnehmerDataSetCHIPID.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetCHIPID.Name := 'TeilnehmerDataSetCHIPID';
    TeilnehmerDataSetCHIPID.FieldName := 'CHIPID';
    TeilnehmerDataSetAKTIV.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetAKTIV.Name := 'TeilnehmerDataSetAKTIV';
    TeilnehmerDataSetAKTIV.FieldName := 'AKTIV';
    TeilnehmerDataSetAKTIV.Required := True;
    TeilnehmerDataSetKZ_SUBACCOUNT.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetKZ_SUBACCOUNT.Name := 'TeilnehmerDataSetKZ_SUBACCOUNT';
    TeilnehmerDataSetKZ_SUBACCOUNT.FieldName := 'KZ_SUBACCOUNT';
    TeilnehmerDataSetEMAILSUBACCOUNT.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetEMAILSUBACCOUNT.Name := 'TeilnehmerDataSetEMAILSUBACCOUNT';
    TeilnehmerDataSetEMAILSUBACCOUNT.FieldName := 'EMAILSUBACCOUNT';
    TeilnehmerDataSetEMAILSUBACCOUNT.Size := 100;
    TeilnehmerDataSetKENNWORT.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetKENNWORT.Name := 'TeilnehmerDataSetKENNWORT';
    TeilnehmerDataSetKENNWORT.FieldName := 'KENNWORT';
    TeilnehmerDataSetUPC_NAME.SetParentComponent(TeilnehmerDataSet);
    TeilnehmerDataSetUPC_NAME.Name := 'TeilnehmerDataSetUPC_NAME';
    TeilnehmerDataSetUPC_NAME.FieldName := 'UPC_NAME';
    TeilnehmerDataSetUPC_NAME.Size := 60;
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Connection := XDataWebConnection1;
    XDataWebClient1.Left := 400;
    XDataWebClient1.Top := 24;
    EinrichtungSource.SetParentComponent(Self);
    EinrichtungSource.Name := 'EinrichtungSource';
    EinrichtungSource.DataSet := EinrichtungDataSet;
    EinrichtungSource.Left := 160;
    EinrichtungSource.Top := 152;
    EinrichtungDataSet.SetParentComponent(Self);
    EinrichtungDataSet.Name := 'EinrichtungDataSet';
    EinrichtungDataSet.EntitySetName := 'EINRICHTUNGEN';
    EinrichtungDataSet.Connection := XDataWebConnection1;
    EinrichtungDataSet.ServerRecordCountMode := smInlineCount;
    EinrichtungDataSet.Left := 264;
    EinrichtungDataSet.Top := 152;
    EinrichtungDataSetID.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetID.Name := 'EinrichtungDataSetID';
    EinrichtungDataSetID.FieldName := 'ID';
    EinrichtungDataSetID.Required := True;
    EinrichtungDataSetNL_KEY.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetNL_KEY.Name := 'EinrichtungDataSetNL_KEY';
    EinrichtungDataSetNL_KEY.FieldName := 'NL_KEY';
    EinrichtungDataSetNL_KEY.Required := True;
    EinrichtungDataSetNUMMER.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetNUMMER.Name := 'EinrichtungDataSetNUMMER';
    EinrichtungDataSetNUMMER.FieldName := 'NUMMER';
    EinrichtungDataSetNUMMER.Required := True;
    EinrichtungDataSetBEZEICHNUNG.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetBEZEICHNUNG.Name := 'EinrichtungDataSetBEZEICHNUNG';
    EinrichtungDataSetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    EinrichtungDataSetBEZEICHNUNG.Size := 60;
    EinrichtungDataSetEMAIL.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetEMAIL.Name := 'EinrichtungDataSetEMAIL';
    EinrichtungDataSetEMAIL.FieldName := 'EMAIL';
    EinrichtungDataSetEMAIL.Size := 100;
    EinrichtungDataSetTELEFON.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetTELEFON.Name := 'EinrichtungDataSetTELEFON';
    EinrichtungDataSetTELEFON.FieldName := 'TELEFON';
    EinrichtungDataSetTELEFON.Size := 60;
    EinrichtungDataSetBESTELL_ENDE.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetBESTELL_ENDE.Name := 'EinrichtungDataSetBESTELL_ENDE';
    EinrichtungDataSetBESTELL_ENDE.FieldName := 'BESTELL_ENDE';
    EinrichtungDataSetBESTELL_ENDE_ART.SetParentComponent(EinrichtungDataSet);
    EinrichtungDataSetBESTELL_ENDE_ART.Name := 'EinrichtungDataSetBESTELL_ENDE_ART';
    EinrichtungDataSetBESTELL_ENDE_ART.FieldName := 'BESTELL_ENDE_ART';
    AllergieDataSet.SetParentComponent(Self);
    AllergieDataSet.Name := 'AllergieDataSet';
    AllergieDataSet.EntitySetName := 'TEILNEHMER_ALLERGIEN';
    AllergieDataSet.Connection := XDataWebConnection1;
    AllergieDataSet.ServerRecordCountMode := smInlineCount;
    AllergieDataSet.Left := 392;
    AllergieDataSet.Top := 88;
    AllergieDataSetTEILNEHMERID.SetParentComponent(AllergieDataSet);
    AllergieDataSetTEILNEHMERID.Name := 'AllergieDataSetTEILNEHMERID';
    AllergieDataSetTEILNEHMERID.FieldName := 'TEILNEHMERID';
    AllergieDataSetTEILNEHMERID.Required := True;
    AllergieDataSetALLERGENID.SetParentComponent(AllergieDataSet);
    AllergieDataSetALLERGENID.Name := 'AllergieDataSetALLERGENID';
    AllergieDataSetALLERGENID.FieldName := 'ALLERGENID';
    AllergieDataSetALLERGENID.Required := True;
    VerpflegungsartSource.SetParentComponent(Self);
    VerpflegungsartSource.Name := 'VerpflegungsartSource';
    VerpflegungsartSource.DataSet := VerpflegungsArtDataSet;
    VerpflegungsartSource.Left := 160;
    VerpflegungsartSource.Top := 216;
    VerpflegungsArtDataSet.SetParentComponent(Self);
    VerpflegungsArtDataSet.Name := 'VerpflegungsArtDataSet';
    VerpflegungsArtDataSet.EntitySetName := 'EINRICHTUNGENVERPFLEGUNG';
    VerpflegungsArtDataSet.Connection := XDataWebConnection1;
    VerpflegungsArtDataSet.Left := 264;
    VerpflegungsArtDataSet.Top := 216;
    VerpflegungsArtDataSetID.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetID.Name := 'VerpflegungsArtDataSetID';
    VerpflegungsArtDataSetID.FieldName := 'ID';
    VerpflegungsArtDataSetID.Required := True;
    VerpflegungsArtDataSetNL_KEY.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetNL_KEY.Name := 'VerpflegungsArtDataSetNL_KEY';
    VerpflegungsArtDataSetNL_KEY.FieldName := 'NL_KEY';
    VerpflegungsArtDataSetNL_KEY.Required := True;
    VerpflegungsArtDataSetEID.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetEID.Name := 'VerpflegungsArtDataSetEID';
    VerpflegungsArtDataSetEID.FieldName := 'EID';
    VerpflegungsArtDataSetEID.Required := True;
    VerpflegungsArtDataSetLFDNO.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetLFDNO.Name := 'VerpflegungsArtDataSetLFDNO';
    VerpflegungsArtDataSetLFDNO.FieldName := 'LFDNO';
    VerpflegungsArtDataSetLFDNO.Required := True;
    VerpflegungsArtDataSetBEZEICHNUNG.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetBEZEICHNUNG.Name := 'VerpflegungsArtDataSetBEZEICHNUNG';
    VerpflegungsArtDataSetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    VerpflegungsArtDataSetBEZEICHNUNG.Size := 60;
    VerpflegungsArtDataSetREIHENFOLGE.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetREIHENFOLGE.Name := 'VerpflegungsArtDataSetREIHENFOLGE';
    VerpflegungsArtDataSetREIHENFOLGE.FieldName := 'REIHENFOLGE';
    VerpflegungsArtDataSetREIHENFOLGE.Required := True;
    VerpflegungsArtDataSetKUERZEL.SetParentComponent(VerpflegungsArtDataSet);
    VerpflegungsArtDataSetKUERZEL.Name := 'VerpflegungsArtDataSetKUERZEL';
    VerpflegungsArtDataSetKUERZEL.FieldName := 'KUERZEL';
    VerpflegungsArtDataSetKUERZEL.Size := 30;
    VariantenSource.SetParentComponent(Self);
    VariantenSource.Name := 'VariantenSource';
    VariantenSource.DataSet := VariantenDataSet;
    VariantenSource.Left := 160;
    VariantenSource.Top := 272;
    VariantenDataSet.SetParentComponent(Self);
    VariantenDataSet.Name := 'VariantenDataSet';
    VariantenDataSet.EntitySetName := 'ESSENSVARIANTEN';
    VariantenDataSet.Connection := XDataWebConnection1;
    VariantenDataSet.Left := 264;
    VariantenDataSet.Top := 272;
    IntegerField1.SetParentComponent(VariantenDataSet);
    IntegerField1.Name := 'IntegerField1';
    IntegerField1.FieldName := 'ID';
    IntegerField1.Required := True;
    IntegerField2.SetParentComponent(VariantenDataSet);
    IntegerField2.Name := 'IntegerField2';
    IntegerField2.FieldName := 'NL_KEY';
    IntegerField2.Required := True;
    IntegerField3.SetParentComponent(VariantenDataSet);
    IntegerField3.Name := 'IntegerField3';
    IntegerField3.FieldName := 'EID';
    IntegerField3.Required := True;
    IntegerField4.SetParentComponent(VariantenDataSet);
    IntegerField4.Name := 'IntegerField4';
    IntegerField4.FieldName := 'LFDNO';
    IntegerField4.Required := True;
    StringField1.SetParentComponent(VariantenDataSet);
    StringField1.Name := 'StringField1';
    StringField1.FieldName := 'BEZEICHNUNG';
    StringField1.Size := 60;
    IntegerField5.SetParentComponent(VariantenDataSet);
    IntegerField5.Name := 'IntegerField5';
    IntegerField5.FieldName := 'REIHENFOLGE';
    IntegerField5.Required := True;
    StringField2.SetParentComponent(VariantenDataSet);
    StringField2.Name := 'StringField2';
    StringField2.FieldName := 'KUERZEL';
    StringField2.Size := 30;
    XDataWebClient2.SetParentComponent(Self);
    XDataWebClient2.Name := 'XDataWebClient2';
    XDataWebClient2.Connection := XDataWebConnection1;
    XDataWebClient2.Left := 47;
    XDataWebClient2.Top := 153;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.Left := 536;
    WebClientConnection1.Top := 32;
    SpeiseplanSource.SetParentComponent(Self);
    SpeiseplanSource.Name := 'SpeiseplanSource';
    SpeiseplanSource.DataSet := Speiseplandataset;
    SpeiseplanSource.Left := 160;
    SpeiseplanSource.Top := 328;
    Speiseplandataset.SetParentComponent(Self);
    Speiseplandataset.Name := 'Speiseplandataset';
    SetEvent(Speiseplandataset, Self, 'OnCalcFields', 'SpeiseplandatasetCalcFields');
    Speiseplandataset.EntitySetName := 'TEILNEHMERSPEISEPLAN';
    Speiseplandataset.Connection := XDataWebConnection1;
    Speiseplandataset.ServerRecordCountMode := smInlineCount;
    Speiseplandataset.Left := 264;
    Speiseplandataset.Top := 328;
    SpeiseplandatasetTID.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetTID.Name := 'SpeiseplandatasetTID';
    SpeiseplandatasetTID.FieldName := 'TID';
    SpeiseplandatasetTID.Required := True;
    SpeiseplandatasetDATUM.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetDATUM.Name := 'SpeiseplandatasetDATUM';
    SpeiseplandatasetDATUM.FieldName := 'DATUM';
    SpeiseplandatasetDATUM.Required := True;
    SpeiseplandatasetSPTID.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetSPTID.Name := 'SpeiseplandatasetSPTID';
    SpeiseplandatasetSPTID.FieldName := 'SPTID';
    SpeiseplandatasetSPTID.Required := True;
    SpeiseplandatasetARTIKEL.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetARTIKEL.Name := 'SpeiseplandatasetARTIKEL';
    SpeiseplandatasetARTIKEL.FieldName := 'ARTIKEL';
    SpeiseplandatasetARTIKEL.Required := True;
    SpeiseplandatasetBEZEICHNUNG.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEZEICHNUNG.Name := 'SpeiseplandatasetBEZEICHNUNG';
    SpeiseplandatasetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    SpeiseplandatasetBEZEICHNUNG.Size := 60;
    SpeiseplandatasetBEZEICHNUNG2.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEZEICHNUNG2.Name := 'SpeiseplandatasetBEZEICHNUNG2';
    SpeiseplandatasetBEZEICHNUNG2.FieldName := 'BEZEICHNUNG2';
    SpeiseplandatasetBEZEICHNUNG2.Size := 60;
    SpeiseplandatasetKZ_BESTELLT.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetKZ_BESTELLT.Name := 'SpeiseplandatasetKZ_BESTELLT';
    SpeiseplandatasetKZ_BESTELLT.FieldName := 'KZ_BESTELLT';
    SpeiseplandatasetBESTELLT.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBESTELLT.Name := 'SpeiseplandatasetBESTELLT';
    SpeiseplandatasetBESTELLT.FieldName := 'BESTELLT';
    SpeiseplandatasetAUSGABE.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetAUSGABE.Name := 'SpeiseplandatasetAUSGABE';
    SpeiseplandatasetAUSGABE.FieldName := 'AUSGABE';
    SpeiseplandatasetKZ_AUSGABE.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetKZ_AUSGABE.Name := 'SpeiseplandatasetKZ_AUSGABE';
    SpeiseplandatasetKZ_AUSGABE.FieldName := 'KZ_AUSGABE';
    SpeiseplandatasetNL_KEY.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetNL_KEY.Name := 'SpeiseplandatasetNL_KEY';
    SpeiseplandatasetNL_KEY.FieldName := 'NL_KEY';
    SpeiseplandatasetNL_KEY.Required := True;
    SpeiseplandatasetEID.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetEID.Name := 'SpeiseplandatasetEID';
    SpeiseplandatasetEID.FieldName := 'EID';
    SpeiseplandatasetENO.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetENO.Name := 'SpeiseplandatasetENO';
    SpeiseplandatasetENO.FieldName := 'ENO';
    SpeiseplandatasetSTORNO.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetSTORNO.Name := 'SpeiseplandatasetSTORNO';
    SpeiseplandatasetSTORNO.FieldName := 'STORNO';
    SpeiseplandatasetSTORNO_KZ.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetSTORNO_KZ.Name := 'SpeiseplandatasetSTORNO_KZ';
    SpeiseplandatasetSTORNO_KZ.FieldName := 'STORNO_KZ';
    SpeiseplandatasetDatumD.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetDatumD.Name := 'SpeiseplandatasetDatumD';
    SpeiseplandatasetDatumD.FieldKind := fkCalculated;
    SpeiseplandatasetDatumD.FieldName := 'DatumD';
    SpeiseplandatasetDatumD.Size := 10;
    SpeiseplandatasetDatumD.Calculated := True;
    SpeiseplandatasetDatumD2.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetDatumD2.Name := 'SpeiseplandatasetDatumD2';
    SpeiseplandatasetDatumD2.FieldKind := fkCalculated;
    SpeiseplandatasetDatumD2.FieldName := 'DatumD2';
    SpeiseplandatasetDatumD2.Size := 10;
    SpeiseplandatasetDatumD2.Calculated := True;
    SpeiseplandatasetStatus.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetStatus.Name := 'SpeiseplandatasetStatus';
    SpeiseplandatasetStatus.FieldKind := fkCalculated;
    SpeiseplandatasetStatus.FieldName := 'Status';
    SpeiseplandatasetStatus.Calculated := True;
    SpeiseplandatasetGRUPPE.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetGRUPPE.Name := 'SpeiseplandatasetGRUPPE';
    SpeiseplandatasetGRUPPE.FieldName := 'GRUPPE';
    SpeiseplandatasetKZ_AUSWAHL.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetKZ_AUSWAHL.Name := 'SpeiseplandatasetKZ_AUSWAHL';
    SpeiseplandatasetKZ_AUSWAHL.FieldName := 'KZ_AUSWAHL';
    SpeiseplandatasetARTIKELID.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetARTIKELID.Name := 'SpeiseplandatasetARTIKELID';
    SpeiseplandatasetARTIKELID.FieldName := 'ARTIKELID';
    SpeiseplandatasetBEWERTUNG1.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEWERTUNG1.Name := 'SpeiseplandatasetBEWERTUNG1';
    SpeiseplandatasetBEWERTUNG1.FieldName := 'BEWERTUNG1';
    SpeiseplandatasetBEWERTUNG2.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEWERTUNG2.Name := 'SpeiseplandatasetBEWERTUNG2';
    SpeiseplandatasetBEWERTUNG2.FieldName := 'BEWERTUNG2';
    SpeiseplandatasetBEWERTUNG3.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEWERTUNG3.Name := 'SpeiseplandatasetBEWERTUNG3';
    SpeiseplandatasetBEWERTUNG3.FieldName := 'BEWERTUNG3';
    SpeiseplandatasetBEMERKUNG.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBEMERKUNG.Name := 'SpeiseplandatasetBEMERKUNG';
    SpeiseplandatasetBEMERKUNG.FieldName := 'BEMERKUNG';
    SpeiseplandatasetBEMERKUNG.Size := 512;
    SpeiseplandatasetBezGes.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBezGes.Name := 'SpeiseplandatasetBezGes';
    SpeiseplandatasetBezGes.FieldKind := fkCalculated;
    SpeiseplandatasetBezGes.FieldName := 'BezGes';
    SpeiseplandatasetBezGes.Size := 120;
    SpeiseplandatasetBezGes.Calculated := True;
    SpeiseplandatasetBildUrl.SetParentComponent(Speiseplandataset);
    SpeiseplandatasetBildUrl.Name := 'SpeiseplandatasetBildUrl';
    SpeiseplandatasetBildUrl.FieldKind := fkCalculated;
    SpeiseplandatasetBildUrl.FieldName := 'BildUrl';
    SpeiseplandatasetBildUrl.Size := 100;
    SpeiseplandatasetBildUrl.Calculated := True;
    XDataWebClientAuth.SetParentComponent(Self);
    XDataWebClientAuth.Name := 'XDataWebClientAuth';
    XDataWebClientAuth.Connection := XDataWebConnection2;
    XDataWebClientAuth.Left := 624;
    XDataWebClientAuth.Top := 184;
    XDataWebConnection2.SetParentComponent(Self);
    XDataWebConnection2.Name := 'XDataWebConnection2';
    XDataWebConnection2.URL := 'https://kwspeiseplan.ddns.net/KWSpeiseplan/auth';
    XDataWebConnection2.Left := 632;
    XDataWebConnection2.Top := 112;
    SicherheitsfragenDataSet.SetParentComponent(Self);
    SicherheitsfragenDataSet.Name := 'SicherheitsfragenDataSet';
    SicherheitsfragenDataSet.EntitySetName := 'Sicherheitsfragen';
    SicherheitsfragenDataSet.Connection := XDataWebConnection1;
    SicherheitsfragenDataSet.Left := 384;
    SicherheitsfragenDataSet.Top := 152;
    SicherheitsfragenDataSetID.SetParentComponent(SicherheitsfragenDataSet);
    SicherheitsfragenDataSetID.Name := 'SicherheitsfragenDataSetID';
    SicherheitsfragenDataSetID.FieldName := 'ID';
    SicherheitsfragenDataSetID.Required := True;
    SicherheitsfragenDataSetNUMMER.SetParentComponent(SicherheitsfragenDataSet);
    SicherheitsfragenDataSetNUMMER.Name := 'SicherheitsfragenDataSetNUMMER';
    SicherheitsfragenDataSetNUMMER.FieldName := 'NUMMER';
    SicherheitsfragenDataSetNUMMER.Required := True;
    SicherheitsfragenDataSetFRAGE.SetParentComponent(SicherheitsfragenDataSet);
    SicherheitsfragenDataSetFRAGE.Name := 'SicherheitsfragenDataSetFRAGE';
    SicherheitsfragenDataSetFRAGE.FieldName := 'FRAGE';
    SicherheitsfragenDataSetFRAGE.Size := 150;
    XDataWebConnection3.SetParentComponent(Self);
    XDataWebConnection3.Name := 'XDataWebConnection3';
    XDataWebConnection3.URL := 'https://kwspeiseplan.ddns.net/KWSpeiseplan/api2';
    SetEvent(XDataWebConnection3, Self, 'OnError', 'XDataWebConnection3Error');
    SetEvent(XDataWebConnection3, Self, 'OnRequest', 'XDataWebConnection3Request');
    XDataWebConnection3.Left := 512;
    XDataWebConnection3.Top := 112;
    XDataWebClientApiService.SetParentComponent(Self);
    XDataWebClientApiService.Name := 'XDataWebClientApiService';
    XDataWebClientApiService.Connection := XDataWebConnection3;
    XDataWebClientApiService.Left := 504;
    XDataWebClientApiService.Top := 184;
    EInrichtungSummenSource.SetParentComponent(Self);
    EInrichtungSummenSource.Name := 'EInrichtungSummenSource';
    EInrichtungSummenSource.DataSet := EinrichtungsummenDataSet;
    EInrichtungSummenSource.Left := 376;
    EInrichtungSummenSource.Top := 248;
    EinrichtungsummenDataSet.SetParentComponent(Self);
    EinrichtungsummenDataSet.Name := 'EinrichtungsummenDataSet';
    EinrichtungsummenDataSet.EntitySetName := 'EINRICHTUNG_SUMMEN';
    EinrichtungsummenDataSet.Connection := XDataWebConnection1;
    EinrichtungsummenDataSet.Left := 488;
    EinrichtungsummenDataSet.Top := 248;
    EinrichtungsummenDataSetID.SetParentComponent(EinrichtungsummenDataSet);
    EinrichtungsummenDataSetID.Name := 'EinrichtungsummenDataSetID';
    EinrichtungsummenDataSetID.FieldName := 'ID';
    EinrichtungsummenDataSetID.Required := True;
    EinrichtungsummenDataSetDATUM.SetParentComponent(EinrichtungsummenDataSet);
    EinrichtungsummenDataSetDATUM.Name := 'EinrichtungsummenDataSetDATUM';
    EinrichtungsummenDataSetDATUM.FieldName := 'DATUM';
    EinrichtungsummenDataSetDATUM.Required := True;
    EinrichtungsummenDataSetARTIKEL.SetParentComponent(EinrichtungsummenDataSet);
    EinrichtungsummenDataSetARTIKEL.Name := 'EinrichtungsummenDataSetARTIKEL';
    EinrichtungsummenDataSetARTIKEL.FieldName := 'ARTIKEL';
    EinrichtungsummenDataSetARTIKEL.Required := True;
    EinrichtungsummenDataSetBEZEICHNUNG.SetParentComponent(EinrichtungsummenDataSet);
    EinrichtungsummenDataSetBEZEICHNUNG.Name := 'EinrichtungsummenDataSetBEZEICHNUNG';
    EinrichtungsummenDataSetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    EinrichtungsummenDataSetBEZEICHNUNG.Size := 60;
    EinrichtungsummenDataSetBESTELLT.SetParentComponent(EinrichtungsummenDataSet);
    EinrichtungsummenDataSetBESTELLT.Name := 'EinrichtungsummenDataSetBESTELLT';
    EinrichtungsummenDataSetBESTELLT.FieldName := 'BESTELLT';
    EinrichtungsummenDataSetGELIEFERT.SetParentComponent(EinrichtungsummenDataSet);
    EinrichtungsummenDataSetGELIEFERT.Name := 'EinrichtungsummenDataSetGELIEFERT';
    EinrichtungsummenDataSetGELIEFERT.FieldName := 'GELIEFERT';
    EinrichtungsummenDataSetAUSGABE.SetParentComponent(EinrichtungsummenDataSet);
    EinrichtungsummenDataSetAUSGABE.Name := 'EinrichtungsummenDataSetAUSGABE';
    EinrichtungsummenDataSetAUSGABE.FieldName := 'AUSGABE';
    ArtikelDataSource.SetParentComponent(Self);
    ArtikelDataSource.Name := 'ArtikelDataSource';
    ArtikelDataSource.DataSet := ArtikelDataSet;
    ArtikelDataSource.Left := 376;
    ArtikelDataSource.Top := 312;
    ArtikelDataSet.SetParentComponent(Self);
    ArtikelDataSet.Name := 'ArtikelDataSet';
    SetEvent(ArtikelDataSet, Self, 'OnCalcFields', 'ArtikelDataSetCalcFields');
    ArtikelDataSet.EntitySetName := 'ARTIKEL';
    ArtikelDataSet.Connection := XDataWebConnection1;
    ArtikelDataSet.Left := 472;
    ArtikelDataSet.Top := 312;
    ArtikelDataSetID.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetID.Name := 'ArtikelDataSetID';
    ArtikelDataSetID.FieldName := 'ID';
    ArtikelDataSetID.Required := True;
    ArtikelDataSetNL_KEY.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetNL_KEY.Name := 'ArtikelDataSetNL_KEY';
    ArtikelDataSetNL_KEY.FieldName := 'NL_KEY';
    ArtikelDataSetNL_KEY.Required := True;
    ArtikelDataSetNUMMER.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetNUMMER.Name := 'ArtikelDataSetNUMMER';
    ArtikelDataSetNUMMER.FieldName := 'NUMMER';
    ArtikelDataSetNUMMER.Required := True;
    ArtikelDataSetBEZEICHNUNG.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetBEZEICHNUNG.Name := 'ArtikelDataSetBEZEICHNUNG';
    ArtikelDataSetBEZEICHNUNG.FieldName := 'BEZEICHNUNG';
    ArtikelDataSetBEZEICHNUNG.Size := 60;
    ArtikelDataSetBEZEICHNUNG2.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetBEZEICHNUNG2.Name := 'ArtikelDataSetBEZEICHNUNG2';
    ArtikelDataSetBEZEICHNUNG2.FieldName := 'BEZEICHNUNG2';
    ArtikelDataSetBEZEICHNUNG2.Size := 60;
    ArtikelDataSetART.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetART.Name := 'ArtikelDataSetART';
    ArtikelDataSetART.FieldName := 'ART';
    ArtikelDataSetART.Required := True;
    ArtikelDataSetWG.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetWG.Name := 'ArtikelDataSetWG';
    ArtikelDataSetWG.FieldName := 'WG';
    ArtikelDataSetWG.Required := True;
    ArtikelDataSetME.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetME.Name := 'ArtikelDataSetME';
    ArtikelDataSetME.FieldName := 'ME';
    ArtikelDataSetME.Required := True;
    ArtikelDataSetKZ_KALK.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetKZ_KALK.Name := 'ArtikelDataSetKZ_KALK';
    ArtikelDataSetKZ_KALK.FieldName := 'KZ_KALK';
    ArtikelDataSetKZ_KALK.Required := True;
    ArtikelDataSetMWST.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetMWST.Name := 'ArtikelDataSetMWST';
    ArtikelDataSetMWST.FieldName := 'MWST';
    ArtikelDataSetMWST.Required := True;
    ArtikelDataSetBRUTTO.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetBRUTTO.Name := 'ArtikelDataSetBRUTTO';
    ArtikelDataSetBRUTTO.FieldName := 'BRUTTO';
    ArtikelDataSetNETTO.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetNETTO.Name := 'ArtikelDataSetNETTO';
    ArtikelDataSetNETTO.FieldName := 'NETTO';
    ArtikelDataSetMATPREIS.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetMATPREIS.Name := 'ArtikelDataSetMATPREIS';
    ArtikelDataSetMATPREIS.FieldName := 'MATPREIS';
    ArtikelDataSetLOHNKOSTEN.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetLOHNKOSTEN.Name := 'ArtikelDataSetLOHNKOSTEN';
    ArtikelDataSetLOHNKOSTEN.FieldName := 'LOHNKOSTEN';
    ArtikelDataSetSONSTIGEKOSTEN.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetSONSTIGEKOSTEN.Name := 'ArtikelDataSetSONSTIGEKOSTEN';
    ArtikelDataSetSONSTIGEKOSTEN.FieldName := 'SONSTIGEKOSTEN';
    ArtikelDataSetKCAL.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetKCAL.Name := 'ArtikelDataSetKCAL';
    ArtikelDataSetKCAL.FieldName := 'KCAL';
    ArtikelDataSetKJOULE.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetKJOULE.Name := 'ArtikelDataSetKJOULE';
    ArtikelDataSetKJOULE.FieldName := 'KJOULE';
    ArtikelDataSetEIWEISS.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetEIWEISS.Name := 'ArtikelDataSetEIWEISS';
    ArtikelDataSetEIWEISS.FieldName := 'EIWEISS';
    ArtikelDataSetKOHLENHYDRATE.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetKOHLENHYDRATE.Name := 'ArtikelDataSetKOHLENHYDRATE';
    ArtikelDataSetKOHLENHYDRATE.FieldName := 'KOHLENHYDRATE';
    ArtikelDataSetZUCKER.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetZUCKER.Name := 'ArtikelDataSetZUCKER';
    ArtikelDataSetZUCKER.FieldName := 'ZUCKER';
    ArtikelDataSetFETT.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetFETT.Name := 'ArtikelDataSetFETT';
    ArtikelDataSetFETT.FieldName := 'FETT';
    ArtikelDataSetFETTGESAETTIGT.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetFETTGESAETTIGT.Name := 'ArtikelDataSetFETTGESAETTIGT';
    ArtikelDataSetFETTGESAETTIGT.FieldName := 'FETTGESAETTIGT';
    ArtikelDataSetSALZ.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetSALZ.Name := 'ArtikelDataSetSALZ';
    ArtikelDataSetSALZ.FieldName := 'SALZ';
    ArtikelDataSetKZ_MINDESTABSTAND.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetKZ_MINDESTABSTAND.Name := 'ArtikelDataSetKZ_MINDESTABSTAND';
    ArtikelDataSetKZ_MINDESTABSTAND.FieldName := 'KZ_MINDESTABSTAND';
    ArtikelDataSetKZ_MINDESTABSTAND.Required := True;
    ArtikelDataSetBezGesamt.SetParentComponent(ArtikelDataSet);
    ArtikelDataSetBezGesamt.Name := 'ArtikelDataSetBezGesamt';
    ArtikelDataSetBezGesamt.FieldKind := fkCalculated;
    ArtikelDataSetBezGesamt.FieldName := 'BezGesamt';
    ArtikelDataSetBezGesamt.Size := 120;
    ArtikelDataSetBezGesamt.Calculated := True;
    BilderDataSet.SetParentComponent(Self);
    BilderDataSet.Name := 'BilderDataSet';
    BilderDataSet.EntitySetName := 'BILDERTABELLE';
    BilderDataSet.Connection := XDataWebConnection1;
    BilderDataSet.Left := 472;
    BilderDataSet.Top := 368;
    BilderDataSetID.SetParentComponent(BilderDataSet);
    BilderDataSetID.Name := 'BilderDataSetID';
    BilderDataSetID.FieldName := 'ID';
    BilderDataSetID.Required := True;
    BilderDataSetART.SetParentComponent(BilderDataSet);
    BilderDataSetART.Name := 'BilderDataSetART';
    BilderDataSetART.FieldName := 'ART';
    BilderDataSetART.Required := True;
    BilderDataSetID2.SetParentComponent(BilderDataSet);
    BilderDataSetID2.Name := 'BilderDataSetID2';
    BilderDataSetID2.FieldName := 'ID2';
    BilderDataSetID2.Required := True;
    BilderDataSetLFDNO.SetParentComponent(BilderDataSet);
    BilderDataSetLFDNO.Name := 'BilderDataSetLFDNO';
    BilderDataSetLFDNO.FieldName := 'LFDNO';
    BilderDataSetLFDNO.Required := True;
    BilderDataSetBILD.SetParentComponent(BilderDataSet);
    BilderDataSetBILD.Name := 'BilderDataSetBILD';
    BilderDataSetBILD.FieldName := 'BILD';
    BilderDataSetURL_PFAD.SetParentComponent(BilderDataSet);
    BilderDataSetURL_PFAD.Name := 'BilderDataSetURL_PFAD';
    BilderDataSetURL_PFAD.FieldName := 'URL_PFAD';
    BilderDataSetURL_PFAD.Required := True;
    BilderDataSetURL_PFAD.Size := 255;
    BilderDataSetDATEITYP.SetParentComponent(BilderDataSet);
    BilderDataSetDATEITYP.Name := 'BilderDataSetDATEITYP';
    BilderDataSetDATEITYP.FieldName := 'DATEITYP';
    BilderDataSetDATEITYP.Required := True;
    BilderDataSetDATEITYP.Size := 10;
    BilderDataSetBEMERKUNG.SetParentComponent(BilderDataSet);
    BilderDataSetBEMERKUNG.Name := 'BilderDataSetBEMERKUNG';
    BilderDataSetBEMERKUNG.FieldName := 'BEMERKUNG';
    BilderDataSetBEMERKUNG.Size := 60;
    BilderDataSource.SetParentComponent(Self);
    BilderDataSource.Name := 'BilderDataSource';
    BilderDataSource.DataSet := BilderDataSet;
    BilderDataSource.Left := 376;
    BilderDataSource.Top := 368;
    Artikel_LTDataSet.SetParentComponent(Self);
    Artikel_LTDataSet.Name := 'Artikel_LTDataSet';
    Artikel_LTDataSet.EntitySetName := 'Artikel_Langtext';
    Artikel_LTDataSet.Connection := XDataWebConnection1;
    Artikel_LTDataSet.Left := 640;
    Artikel_LTDataSet.Top := 312;
    Artikel_LTDataSetID.SetParentComponent(Artikel_LTDataSet);
    Artikel_LTDataSetID.Name := 'Artikel_LTDataSetID';
    Artikel_LTDataSetID.FieldName := 'ID';
    Artikel_LTDataSetID.Required := True;
    Artikel_LTDataSetTXT.SetParentComponent(Artikel_LTDataSet);
    Artikel_LTDataSetTXT.Name := 'Artikel_LTDataSetTXT';
    Artikel_LTDataSetTXT.FieldName := 'TXT';
    Artikel_LTDataSetTXT.Size := 80;
    Artikel_LTSource.SetParentComponent(Self);
    Artikel_LTSource.Name := 'Artikel_LTSource';
    Artikel_LTSource.DataSet := Artikel_LTDataSet;
    Artikel_LTSource.Left := 552;
    Artikel_LTSource.Top := 320;
    MWSTDataSet.SetParentComponent(Self);
    MWSTDataSet.Name := 'MWSTDataSet';
    MWSTDataSet.EntitySetName := 'MWST';
    MWSTDataSet.Connection := XDataWebConnection1;
    MWSTDataSet.Left := 704;
    MWSTDataSet.Top := 256;
  finally
    XDataWebConnection1.AfterLoadDFMValues;
    KundenDataSet.AfterLoadDFMValues;
    KundenDataSetID.AfterLoadDFMValues;
    KundenDataSetNL_KEY.AfterLoadDFMValues;
    KundenDataSetKDNO.AfterLoadDFMValues;
    KundenDataSetNAME.AfterLoadDFMValues;
    KundenDataSetNAME2.AfterLoadDFMValues;
    KundenDataSetSTRASSE.AfterLoadDFMValues;
    KundenDataSetPLZ.AfterLoadDFMValues;
    KundenDataSetORT.AfterLoadDFMValues;
    KundenDataSetTELEFON.AfterLoadDFMValues;
    KundenDataSetHANDY.AfterLoadDFMValues;
    KundenDataSetEMAILADR.AfterLoadDFMValues;
    KundenDataSetKENNWORT.AfterLoadDFMValues;
    KundenDataSetDATUHR.AfterLoadDFMValues;
    KundenDataSetCONFIRMED.AfterLoadDFMValues;
    KundenDataSetUPC_EMAIL.AfterLoadDFMValues;
    KundenDataSetDATUHR2.AfterLoadDFMValues;
    KundenDataSetBESTAETIGUNG.AfterLoadDFMValues;
    KundenDataSetAKTIV.AfterLoadDFMValues;
    KundenDataSetSTATUS.AfterLoadDFMValues;
    KundenDataSetANREDE.AfterLoadDFMValues;
    KundenDataSetAKTITEL.AfterLoadDFMValues;
    KundenDataSetIBAN.AfterLoadDFMValues;
    KundenDataSetBIC.AfterLoadDFMValues;
    KundenDataSetBANK.AfterLoadDFMValues;
    KundenDataSetKZBERECHTIGUNG.AfterLoadDFMValues;
    KundenDataSetADMINLEVEL.AfterLoadDFMValues;
    KundenDataSetKDGRUPPE.AfterLoadDFMValues;
    KundenDataSetZAHLBED.AfterLoadDFMValues;
    KundenDataSetABRECHNUNGSART.AfterLoadDFMValues;
    KundenDataSetCHIPID.AfterLoadDFMValues;
    KundenDataSetPREISLISTE.AfterLoadDFMValues;
    KundenDataSetKONTOINHABER.AfterLoadDFMValues;
    KundenDataSetSF_1.AfterLoadDFMValues;
    KundenDataSetSF_2.AfterLoadDFMValues;
    KundenDataSetSF_3.AfterLoadDFMValues;
    KundenDataSetESF.AfterLoadDFMValues;
    KundenDataSetSFA_1.AfterLoadDFMValues;
    KundenDataSetSFA_2.AfterLoadDFMValues;
    KundenDataSetSFA_3.AfterLoadDFMValues;
    KundenDataSource.AfterLoadDFMValues;
    XDataClient.AfterLoadDFMValues;
    TeilnehmerDataSource.AfterLoadDFMValues;
    TeilnehmerDataSet.AfterLoadDFMValues;
    TeilnehmerDataSetID.AfterLoadDFMValues;
    TeilnehmerDataSetNL_KEY.AfterLoadDFMValues;
    TeilnehmerDataSetKDID.AfterLoadDFMValues;
    TeilnehmerDataSetLFDNO.AfterLoadDFMValues;
    TeilnehmerDataSetNAME.AfterLoadDFMValues;
    TeilnehmerDataSetEINRICHTUNG.AfterLoadDFMValues;
    TeilnehmerDataSetGESCHLECHT.AfterLoadDFMValues;
    TeilnehmerDataSetGEBURTSDATUM.AfterLoadDFMValues;
    TeilnehmerDataSetLKZ.AfterLoadDFMValues;
    TeilnehmerDataSetLOESCHUNG.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN1.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN2.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN3.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN4.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN5.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN6.AfterLoadDFMValues;
    TeilnehmerDataSetPLAN7.AfterLoadDFMValues;
    TeilnehmerDataSetEINRICHTUNGBEZ.AfterLoadDFMValues;
    TeilnehmerDataSetTT.AfterLoadDFMValues;
    TeilnehmerDataSetMM.AfterLoadDFMValues;
    TeilnehmerDataSetJJ.AfterLoadDFMValues;
    TeilnehmerDataSetKZ_BERECHTIGUNG.AfterLoadDFMValues;
    TeilnehmerDataSetVARIANTE.AfterLoadDFMValues;
    TeilnehmerDataSetTID.AfterLoadDFMValues;
    TeilnehmerDataSetCHIPID.AfterLoadDFMValues;
    TeilnehmerDataSetAKTIV.AfterLoadDFMValues;
    TeilnehmerDataSetKZ_SUBACCOUNT.AfterLoadDFMValues;
    TeilnehmerDataSetEMAILSUBACCOUNT.AfterLoadDFMValues;
    TeilnehmerDataSetKENNWORT.AfterLoadDFMValues;
    TeilnehmerDataSetUPC_NAME.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
    EinrichtungSource.AfterLoadDFMValues;
    EinrichtungDataSet.AfterLoadDFMValues;
    EinrichtungDataSetID.AfterLoadDFMValues;
    EinrichtungDataSetNL_KEY.AfterLoadDFMValues;
    EinrichtungDataSetNUMMER.AfterLoadDFMValues;
    EinrichtungDataSetBEZEICHNUNG.AfterLoadDFMValues;
    EinrichtungDataSetEMAIL.AfterLoadDFMValues;
    EinrichtungDataSetTELEFON.AfterLoadDFMValues;
    EinrichtungDataSetBESTELL_ENDE.AfterLoadDFMValues;
    EinrichtungDataSetBESTELL_ENDE_ART.AfterLoadDFMValues;
    AllergieDataSet.AfterLoadDFMValues;
    AllergieDataSetTEILNEHMERID.AfterLoadDFMValues;
    AllergieDataSetALLERGENID.AfterLoadDFMValues;
    VerpflegungsartSource.AfterLoadDFMValues;
    VerpflegungsArtDataSet.AfterLoadDFMValues;
    VerpflegungsArtDataSetID.AfterLoadDFMValues;
    VerpflegungsArtDataSetNL_KEY.AfterLoadDFMValues;
    VerpflegungsArtDataSetEID.AfterLoadDFMValues;
    VerpflegungsArtDataSetLFDNO.AfterLoadDFMValues;
    VerpflegungsArtDataSetBEZEICHNUNG.AfterLoadDFMValues;
    VerpflegungsArtDataSetREIHENFOLGE.AfterLoadDFMValues;
    VerpflegungsArtDataSetKUERZEL.AfterLoadDFMValues;
    VariantenSource.AfterLoadDFMValues;
    VariantenDataSet.AfterLoadDFMValues;
    IntegerField1.AfterLoadDFMValues;
    IntegerField2.AfterLoadDFMValues;
    IntegerField3.AfterLoadDFMValues;
    IntegerField4.AfterLoadDFMValues;
    StringField1.AfterLoadDFMValues;
    IntegerField5.AfterLoadDFMValues;
    StringField2.AfterLoadDFMValues;
    XDataWebClient2.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
    SpeiseplanSource.AfterLoadDFMValues;
    Speiseplandataset.AfterLoadDFMValues;
    SpeiseplandatasetTID.AfterLoadDFMValues;
    SpeiseplandatasetDATUM.AfterLoadDFMValues;
    SpeiseplandatasetSPTID.AfterLoadDFMValues;
    SpeiseplandatasetARTIKEL.AfterLoadDFMValues;
    SpeiseplandatasetBEZEICHNUNG.AfterLoadDFMValues;
    SpeiseplandatasetBEZEICHNUNG2.AfterLoadDFMValues;
    SpeiseplandatasetKZ_BESTELLT.AfterLoadDFMValues;
    SpeiseplandatasetBESTELLT.AfterLoadDFMValues;
    SpeiseplandatasetAUSGABE.AfterLoadDFMValues;
    SpeiseplandatasetKZ_AUSGABE.AfterLoadDFMValues;
    SpeiseplandatasetNL_KEY.AfterLoadDFMValues;
    SpeiseplandatasetEID.AfterLoadDFMValues;
    SpeiseplandatasetENO.AfterLoadDFMValues;
    SpeiseplandatasetSTORNO.AfterLoadDFMValues;
    SpeiseplandatasetSTORNO_KZ.AfterLoadDFMValues;
    SpeiseplandatasetDatumD.AfterLoadDFMValues;
    SpeiseplandatasetDatumD2.AfterLoadDFMValues;
    SpeiseplandatasetStatus.AfterLoadDFMValues;
    SpeiseplandatasetGRUPPE.AfterLoadDFMValues;
    SpeiseplandatasetKZ_AUSWAHL.AfterLoadDFMValues;
    SpeiseplandatasetARTIKELID.AfterLoadDFMValues;
    SpeiseplandatasetBEWERTUNG1.AfterLoadDFMValues;
    SpeiseplandatasetBEWERTUNG2.AfterLoadDFMValues;
    SpeiseplandatasetBEWERTUNG3.AfterLoadDFMValues;
    SpeiseplandatasetBEMERKUNG.AfterLoadDFMValues;
    SpeiseplandatasetBezGes.AfterLoadDFMValues;
    SpeiseplandatasetBildUrl.AfterLoadDFMValues;
    XDataWebClientAuth.AfterLoadDFMValues;
    XDataWebConnection2.AfterLoadDFMValues;
    SicherheitsfragenDataSet.AfterLoadDFMValues;
    SicherheitsfragenDataSetID.AfterLoadDFMValues;
    SicherheitsfragenDataSetNUMMER.AfterLoadDFMValues;
    SicherheitsfragenDataSetFRAGE.AfterLoadDFMValues;
    XDataWebConnection3.AfterLoadDFMValues;
    XDataWebClientApiService.AfterLoadDFMValues;
    EInrichtungSummenSource.AfterLoadDFMValues;
    EinrichtungsummenDataSet.AfterLoadDFMValues;
    EinrichtungsummenDataSetID.AfterLoadDFMValues;
    EinrichtungsummenDataSetDATUM.AfterLoadDFMValues;
    EinrichtungsummenDataSetARTIKEL.AfterLoadDFMValues;
    EinrichtungsummenDataSetBEZEICHNUNG.AfterLoadDFMValues;
    EinrichtungsummenDataSetBESTELLT.AfterLoadDFMValues;
    EinrichtungsummenDataSetGELIEFERT.AfterLoadDFMValues;
    EinrichtungsummenDataSetAUSGABE.AfterLoadDFMValues;
    ArtikelDataSource.AfterLoadDFMValues;
    ArtikelDataSet.AfterLoadDFMValues;
    ArtikelDataSetID.AfterLoadDFMValues;
    ArtikelDataSetNL_KEY.AfterLoadDFMValues;
    ArtikelDataSetNUMMER.AfterLoadDFMValues;
    ArtikelDataSetBEZEICHNUNG.AfterLoadDFMValues;
    ArtikelDataSetBEZEICHNUNG2.AfterLoadDFMValues;
    ArtikelDataSetART.AfterLoadDFMValues;
    ArtikelDataSetWG.AfterLoadDFMValues;
    ArtikelDataSetME.AfterLoadDFMValues;
    ArtikelDataSetKZ_KALK.AfterLoadDFMValues;
    ArtikelDataSetMWST.AfterLoadDFMValues;
    ArtikelDataSetBRUTTO.AfterLoadDFMValues;
    ArtikelDataSetNETTO.AfterLoadDFMValues;
    ArtikelDataSetMATPREIS.AfterLoadDFMValues;
    ArtikelDataSetLOHNKOSTEN.AfterLoadDFMValues;
    ArtikelDataSetSONSTIGEKOSTEN.AfterLoadDFMValues;
    ArtikelDataSetKCAL.AfterLoadDFMValues;
    ArtikelDataSetKJOULE.AfterLoadDFMValues;
    ArtikelDataSetEIWEISS.AfterLoadDFMValues;
    ArtikelDataSetKOHLENHYDRATE.AfterLoadDFMValues;
    ArtikelDataSetZUCKER.AfterLoadDFMValues;
    ArtikelDataSetFETT.AfterLoadDFMValues;
    ArtikelDataSetFETTGESAETTIGT.AfterLoadDFMValues;
    ArtikelDataSetSALZ.AfterLoadDFMValues;
    ArtikelDataSetKZ_MINDESTABSTAND.AfterLoadDFMValues;
    ArtikelDataSetBezGesamt.AfterLoadDFMValues;
    BilderDataSet.AfterLoadDFMValues;
    BilderDataSetID.AfterLoadDFMValues;
    BilderDataSetART.AfterLoadDFMValues;
    BilderDataSetID2.AfterLoadDFMValues;
    BilderDataSetLFDNO.AfterLoadDFMValues;
    BilderDataSetBILD.AfterLoadDFMValues;
    BilderDataSetURL_PFAD.AfterLoadDFMValues;
    BilderDataSetDATEITYP.AfterLoadDFMValues;
    BilderDataSetBEMERKUNG.AfterLoadDFMValues;
    BilderDataSource.AfterLoadDFMValues;
    Artikel_LTDataSet.AfterLoadDFMValues;
    Artikel_LTDataSetID.AfterLoadDFMValues;
    Artikel_LTDataSetTXT.AfterLoadDFMValues;
    Artikel_LTSource.AfterLoadDFMValues;
    MWSTDataSet.AfterLoadDFMValues;
  end;
end;

end.
