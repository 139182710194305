unit UVWAllergien;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  XData.Web.Client,
  UDatamodule,
  UGlobvar;

type
  TVWAllergien = class(TForm)
    WebPanel1: TPanel;
    WebCheckBox_1: TCheckBox;
    WebCheckBox_2: TCheckBox;
    WebCheckBox_3: TCheckBox;
    WebCheckBox_6: TCheckBox;
    WebCheckBox_5: TCheckBox;
    WebCheckBox_4: TCheckBox;
    WebCheckBox_9: TCheckBox;
    WebCheckBox_8: TCheckBox;
    WebCheckBox_7: TCheckBox;
    WebCheckBox_12: TCheckBox;
    WebCheckBox_11: TCheckBox;
    WebCheckBox_10: TCheckBox;
    WebCheckBox_14: TCheckBox;
    WebCheckBox_13: TCheckBox;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    PushC_Liste : TStringList;

  end;

var
  VWAllergien: TVWAllergien;

implementation


procedure TVWAllergien.WebButton1Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  Close;
end;

procedure TVWAllergien.WebButton2Click(Sender: TObject);
var hst,
    hst2 : String;
begin
  Push_Controls(self,PushC_Liste);

  hst2 := '';
  if WebCheckBox_1.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[1]);
  end;
  if WebCheckBox_2.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[2]);
  end;
  if WebCheckBox_3.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[3]);
  end;
  if WebCheckBox_4.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[4]);
  end;
  if WebCheckBox_5.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[5]);
  end;
  if WebCheckBox_6.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[6]);
  end;
  if WebCheckBox_7.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[7]);
  end;
  if WebCheckBox_8.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[8]);
  end;
  if WebCheckBox_9.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[9]);
  end;
  if WebCheckBox_10.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[10]);
  end;
  if WebCheckBox_11.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[11]);
  end;
  if WebCheckBox_12.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[12]);
  end;
  if WebCheckBox_13.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[13]);
  end;
  if WebCheckBox_14.Checked then begin
    if hst2 <> '' then hst2 := hst2 + ';';
    hst2 := hst2 + IntToStr(AllergenID[14]);
  end;
  hst := 'SELECT p.ERRNO FROM SET_ALLERGIEN2 ('+IntToStr(AktTeilnehmer)+','+''''+hst2+''''+') p;';

  DataModule1.XDataWebClientApiService.RawInvoke('IApiService.Do_Transaction', [0, hst],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i = -1 then begin
          Pop_Controls(self,PushC_Liste);
          MessageDlg('Daten nicht gespeichert', mtError, [], nil);
        end
        else begin
          Close;
        end;
      end
      else begin
        Pop_Controls(self,PushC_Liste);

        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;
    end
  );

end;


procedure TVWAllergien.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;

end;

procedure TVWAllergien.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  PushC_Liste := TStringList.Create;

end;

procedure TVWAllergien.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TVWAllergien.WebFormShow(Sender: TObject);
begin
  Resize_Top_Panel_3(self,WebPanel1,'Akt. Benutzer: '+ AktKDName,'Allergiezuordnung für '+AktTeilnehmerName);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_3,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_4,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_5,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_6,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_7,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_8,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_9,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_10,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_11,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_12,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_13,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox_14,nil);
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  Form_Aktiv := true;



  WebCheckBox_1.Caption := AllergenBez[1];
  WebCheckBox_2.Caption := AllergenBez[2];
  WebCheckBox_3.Caption := AllergenBez[3];
  WebCheckBox_4.Caption := AllergenBez[4];
  WebCheckBox_5.Caption := AllergenBez[5];
  WebCheckBox_6.Caption := AllergenBez[6];
  WebCheckBox_7.Caption := AllergenBez[7];
  WebCheckBox_8.Caption := AllergenBez[8];
  WebCheckBox_9.Caption := AllergenBez[9];
  WebCheckBox_10.Caption := AllergenBez[10];
  WebCheckBox_11.Caption := AllergenBez[11];
  WebCheckBox_12.Caption := AllergenBez[12];
  WebCheckBox_13.Caption := AllergenBez[13];
  WebCheckBox_14.Caption := AllergenBez[14];
  WebCheckBox_1.Checked := false;
  WebCheckBox_2.Checked := false;
  WebCheckBox_3.Checked := false;
  WebCheckBox_4.Checked := false;
  WebCheckBox_5.Checked := false;
  WebCheckBox_6.Checked := false;
  WebCheckBox_7.Checked := false;
  WebCheckBox_8.Checked := false;
  WebCheckBox_9.Checked := false;
  WebCheckBox_10.Checked := false;
  WebCheckBox_11.Checked := false;
  WebCheckBox_12.Checked := false;
  WebCheckBox_13.Checked := false;
  WebCheckBox_14.Checked := false;
  Datamodule1.AllergieDataSet.First;
  while not Datamodule1.AllergieDataSet.EOF do begin
    case Datamodule1.AllergieDataSet.FieldByName('AllergenID').AsInteger of
      1 : WebCheckBox_1.Checked := true;
      2 : WebCheckBox_2.Checked := true;
      3 : WebCheckBox_3.Checked := true;
      4 : WebCheckBox_4.Checked := true;
      5 : WebCheckBox_5.Checked := true;
      6 : WebCheckBox_6.Checked := true;
      7 : WebCheckBox_7.Checked := true;
      8 : WebCheckBox_8.Checked := true;
      9 : WebCheckBox_9.Checked := true;
      10 : WebCheckBox_10.Checked := true;
      11 : WebCheckBox_11.Checked := true;
      12 : WebCheckBox_12.Checked := true;
      13 : WebCheckBox_13.Checked := true;
      14 : WebCheckBox_14.Checked := true;
    end;
    Datamodule1.AllergieDataSet.Next;
  end;



end;

procedure TVWAllergien.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebCheckBox_1 := TCheckBox.Create(Self);
  WebCheckBox_2 := TCheckBox.Create(Self);
  WebCheckBox_3 := TCheckBox.Create(Self);
  WebCheckBox_6 := TCheckBox.Create(Self);
  WebCheckBox_5 := TCheckBox.Create(Self);
  WebCheckBox_4 := TCheckBox.Create(Self);
  WebCheckBox_9 := TCheckBox.Create(Self);
  WebCheckBox_8 := TCheckBox.Create(Self);
  WebCheckBox_7 := TCheckBox.Create(Self);
  WebCheckBox_12 := TCheckBox.Create(Self);
  WebCheckBox_11 := TCheckBox.Create(Self);
  WebCheckBox_10 := TCheckBox.Create(Self);
  WebCheckBox_14 := TCheckBox.Create(Self);
  WebCheckBox_13 := TCheckBox.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebCheckBox_1.BeforeLoadDFMValues;
  WebCheckBox_2.BeforeLoadDFMValues;
  WebCheckBox_3.BeforeLoadDFMValues;
  WebCheckBox_6.BeforeLoadDFMValues;
  WebCheckBox_5.BeforeLoadDFMValues;
  WebCheckBox_4.BeforeLoadDFMValues;
  WebCheckBox_9.BeforeLoadDFMValues;
  WebCheckBox_8.BeforeLoadDFMValues;
  WebCheckBox_7.BeforeLoadDFMValues;
  WebCheckBox_12.BeforeLoadDFMValues;
  WebCheckBox_11.BeforeLoadDFMValues;
  WebCheckBox_10.BeforeLoadDFMValues;
  WebCheckBox_14.BeforeLoadDFMValues;
  WebCheckBox_13.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'VWAllergien';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 73;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebCheckBox_1.SetParentComponent(Self);
    WebCheckBox_1.Name := 'WebCheckBox_1';
    WebCheckBox_1.Left := 16;
    WebCheckBox_1.Top := 104;
    WebCheckBox_1.Width := 449;
    WebCheckBox_1.Height := 22;
    WebCheckBox_1.Caption := 'WebCheckBox_1';
    WebCheckBox_1.ChildOrder := 4;
    WebCheckBox_1.Color := clNone;
    WebCheckBox_1.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_1.Font.Color := clWindowText;
    WebCheckBox_1.Font.Height := -13;
    WebCheckBox_1.Font.Name := 'Arial';
    WebCheckBox_1.Font.Style := [];
    WebCheckBox_1.ParentFont := False;
    WebCheckBox_1.State := cbUnchecked;
    WebCheckBox_2.SetParentComponent(Self);
    WebCheckBox_2.Name := 'WebCheckBox_2';
    WebCheckBox_2.Left := 16;
    WebCheckBox_2.Top := 127;
    WebCheckBox_2.Width := 449;
    WebCheckBox_2.Height := 22;
    WebCheckBox_2.Caption := 'WebCheckBox1';
    WebCheckBox_2.ChildOrder := 4;
    WebCheckBox_2.Color := clNone;
    WebCheckBox_2.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_2.Font.Color := clWindowText;
    WebCheckBox_2.Font.Height := -13;
    WebCheckBox_2.Font.Name := 'Arial';
    WebCheckBox_2.Font.Style := [];
    WebCheckBox_2.ParentFont := False;
    WebCheckBox_2.State := cbUnchecked;
    WebCheckBox_3.SetParentComponent(Self);
    WebCheckBox_3.Name := 'WebCheckBox_3';
    WebCheckBox_3.Left := 16;
    WebCheckBox_3.Top := 150;
    WebCheckBox_3.Width := 449;
    WebCheckBox_3.Height := 22;
    WebCheckBox_3.Caption := 'WebCheckBox1';
    WebCheckBox_3.ChildOrder := 4;
    WebCheckBox_3.Color := clNone;
    WebCheckBox_3.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_3.Font.Color := clWindowText;
    WebCheckBox_3.Font.Height := -13;
    WebCheckBox_3.Font.Name := 'Arial';
    WebCheckBox_3.Font.Style := [];
    WebCheckBox_3.ParentFont := False;
    WebCheckBox_3.State := cbUnchecked;
    WebCheckBox_6.SetParentComponent(Self);
    WebCheckBox_6.Name := 'WebCheckBox_6';
    WebCheckBox_6.Left := 16;
    WebCheckBox_6.Top := 219;
    WebCheckBox_6.Width := 449;
    WebCheckBox_6.Height := 22;
    WebCheckBox_6.Caption := 'WebCheckBox1';
    WebCheckBox_6.ChildOrder := 4;
    WebCheckBox_6.Color := clNone;
    WebCheckBox_6.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_6.Font.Color := clWindowText;
    WebCheckBox_6.Font.Height := -13;
    WebCheckBox_6.Font.Name := 'Arial';
    WebCheckBox_6.Font.Style := [];
    WebCheckBox_6.ParentFont := False;
    WebCheckBox_6.State := cbUnchecked;
    WebCheckBox_5.SetParentComponent(Self);
    WebCheckBox_5.Name := 'WebCheckBox_5';
    WebCheckBox_5.Left := 16;
    WebCheckBox_5.Top := 196;
    WebCheckBox_5.Width := 449;
    WebCheckBox_5.Height := 22;
    WebCheckBox_5.Caption := 'WebCheckBox1';
    WebCheckBox_5.ChildOrder := 4;
    WebCheckBox_5.Color := clNone;
    WebCheckBox_5.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_5.Font.Color := clWindowText;
    WebCheckBox_5.Font.Height := -13;
    WebCheckBox_5.Font.Name := 'Arial';
    WebCheckBox_5.Font.Style := [];
    WebCheckBox_5.ParentFont := False;
    WebCheckBox_5.State := cbUnchecked;
    WebCheckBox_4.SetParentComponent(Self);
    WebCheckBox_4.Name := 'WebCheckBox_4';
    WebCheckBox_4.Left := 16;
    WebCheckBox_4.Top := 173;
    WebCheckBox_4.Width := 449;
    WebCheckBox_4.Height := 22;
    WebCheckBox_4.Caption := 'WebCheckBox1';
    WebCheckBox_4.ChildOrder := 4;
    WebCheckBox_4.Color := clNone;
    WebCheckBox_4.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_4.Font.Color := clWindowText;
    WebCheckBox_4.Font.Height := -13;
    WebCheckBox_4.Font.Name := 'Arial';
    WebCheckBox_4.Font.Style := [];
    WebCheckBox_4.ParentFont := False;
    WebCheckBox_4.State := cbUnchecked;
    WebCheckBox_9.SetParentComponent(Self);
    WebCheckBox_9.Name := 'WebCheckBox_9';
    WebCheckBox_9.Left := 16;
    WebCheckBox_9.Top := 288;
    WebCheckBox_9.Width := 449;
    WebCheckBox_9.Height := 22;
    WebCheckBox_9.Caption := 'WebCheckBox1';
    WebCheckBox_9.ChildOrder := 4;
    WebCheckBox_9.Color := clNone;
    WebCheckBox_9.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_9.Font.Color := clWindowText;
    WebCheckBox_9.Font.Height := -13;
    WebCheckBox_9.Font.Name := 'Arial';
    WebCheckBox_9.Font.Style := [];
    WebCheckBox_9.ParentFont := False;
    WebCheckBox_9.State := cbUnchecked;
    WebCheckBox_8.SetParentComponent(Self);
    WebCheckBox_8.Name := 'WebCheckBox_8';
    WebCheckBox_8.Left := 16;
    WebCheckBox_8.Top := 265;
    WebCheckBox_8.Width := 449;
    WebCheckBox_8.Height := 22;
    WebCheckBox_8.Caption := 'WebCheckBox1';
    WebCheckBox_8.ChildOrder := 4;
    WebCheckBox_8.Color := clNone;
    WebCheckBox_8.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_8.Font.Color := clWindowText;
    WebCheckBox_8.Font.Height := -13;
    WebCheckBox_8.Font.Name := 'Arial';
    WebCheckBox_8.Font.Style := [];
    WebCheckBox_8.ParentFont := False;
    WebCheckBox_8.State := cbUnchecked;
    WebCheckBox_7.SetParentComponent(Self);
    WebCheckBox_7.Name := 'WebCheckBox_7';
    WebCheckBox_7.Left := 16;
    WebCheckBox_7.Top := 242;
    WebCheckBox_7.Width := 449;
    WebCheckBox_7.Height := 22;
    WebCheckBox_7.Caption := 'WebCheckBox1';
    WebCheckBox_7.ChildOrder := 4;
    WebCheckBox_7.Color := clNone;
    WebCheckBox_7.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_7.Font.Color := clWindowText;
    WebCheckBox_7.Font.Height := -13;
    WebCheckBox_7.Font.Name := 'Arial';
    WebCheckBox_7.Font.Style := [];
    WebCheckBox_7.ParentFont := False;
    WebCheckBox_7.State := cbUnchecked;
    WebCheckBox_12.SetParentComponent(Self);
    WebCheckBox_12.Name := 'WebCheckBox_12';
    WebCheckBox_12.Left := 16;
    WebCheckBox_12.Top := 357;
    WebCheckBox_12.Width := 449;
    WebCheckBox_12.Height := 22;
    WebCheckBox_12.Caption := 'WebCheckBox1';
    WebCheckBox_12.ChildOrder := 4;
    WebCheckBox_12.Color := clNone;
    WebCheckBox_12.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_12.Font.Color := clWindowText;
    WebCheckBox_12.Font.Height := -13;
    WebCheckBox_12.Font.Name := 'Arial';
    WebCheckBox_12.Font.Style := [];
    WebCheckBox_12.ParentFont := False;
    WebCheckBox_12.State := cbUnchecked;
    WebCheckBox_11.SetParentComponent(Self);
    WebCheckBox_11.Name := 'WebCheckBox_11';
    WebCheckBox_11.Left := 16;
    WebCheckBox_11.Top := 334;
    WebCheckBox_11.Width := 449;
    WebCheckBox_11.Height := 22;
    WebCheckBox_11.Caption := 'WebCheckBox1';
    WebCheckBox_11.ChildOrder := 4;
    WebCheckBox_11.Color := clNone;
    WebCheckBox_11.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_11.Font.Color := clWindowText;
    WebCheckBox_11.Font.Height := -13;
    WebCheckBox_11.Font.Name := 'Arial';
    WebCheckBox_11.Font.Style := [];
    WebCheckBox_11.ParentFont := False;
    WebCheckBox_11.State := cbUnchecked;
    WebCheckBox_10.SetParentComponent(Self);
    WebCheckBox_10.Name := 'WebCheckBox_10';
    WebCheckBox_10.Left := 16;
    WebCheckBox_10.Top := 311;
    WebCheckBox_10.Width := 449;
    WebCheckBox_10.Height := 22;
    WebCheckBox_10.Caption := 'WebCheckBox1';
    WebCheckBox_10.ChildOrder := 4;
    WebCheckBox_10.Color := clNone;
    WebCheckBox_10.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_10.Font.Color := clWindowText;
    WebCheckBox_10.Font.Height := -13;
    WebCheckBox_10.Font.Name := 'Arial';
    WebCheckBox_10.Font.Style := [];
    WebCheckBox_10.ParentFont := False;
    WebCheckBox_10.State := cbUnchecked;
    WebCheckBox_14.SetParentComponent(Self);
    WebCheckBox_14.Name := 'WebCheckBox_14';
    WebCheckBox_14.Left := 16;
    WebCheckBox_14.Top := 404;
    WebCheckBox_14.Width := 449;
    WebCheckBox_14.Height := 22;
    WebCheckBox_14.Caption := 'WebCheckBox1';
    WebCheckBox_14.ChildOrder := 4;
    WebCheckBox_14.Color := clNone;
    WebCheckBox_14.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_14.Font.Color := clWindowText;
    WebCheckBox_14.Font.Height := -13;
    WebCheckBox_14.Font.Name := 'Arial';
    WebCheckBox_14.Font.Style := [];
    WebCheckBox_14.ParentFont := False;
    WebCheckBox_14.State := cbUnchecked;
    WebCheckBox_13.SetParentComponent(Self);
    WebCheckBox_13.Name := 'WebCheckBox_13';
    WebCheckBox_13.Left := 16;
    WebCheckBox_13.Top := 380;
    WebCheckBox_13.Width := 449;
    WebCheckBox_13.Height := 22;
    WebCheckBox_13.Caption := 'WebCheckBox1';
    WebCheckBox_13.ChildOrder := 4;
    WebCheckBox_13.Color := clNone;
    WebCheckBox_13.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox_13.Font.Color := clWindowText;
    WebCheckBox_13.Font.Height := -13;
    WebCheckBox_13.Font.Name := 'Arial';
    WebCheckBox_13.Font.Style := [];
    WebCheckBox_13.ParentFont := False;
    WebCheckBox_13.State := cbUnchecked;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 17;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 19;
    WebButton2.Width := 153;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 185;
    WebButton1.Top := 19;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.TabOrder := 30;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebPanel1.AfterLoadDFMValues;
    WebCheckBox_1.AfterLoadDFMValues;
    WebCheckBox_2.AfterLoadDFMValues;
    WebCheckBox_3.AfterLoadDFMValues;
    WebCheckBox_6.AfterLoadDFMValues;
    WebCheckBox_5.AfterLoadDFMValues;
    WebCheckBox_4.AfterLoadDFMValues;
    WebCheckBox_9.AfterLoadDFMValues;
    WebCheckBox_8.AfterLoadDFMValues;
    WebCheckBox_7.AfterLoadDFMValues;
    WebCheckBox_12.AfterLoadDFMValues;
    WebCheckBox_11.AfterLoadDFMValues;
    WebCheckBox_10.AfterLoadDFMValues;
    WebCheckBox_14.AfterLoadDFMValues;
    WebCheckBox_13.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.