unit ULogin;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.Crypto,
  WEBLib.Storage,
  DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.DB,

  WEBLib.ExtCtrls,
  UDataModule, WEBLib.DBCtrls,
  Types,
  UGlobvar,
  UNeuesKonto,
  UNeuesKonto2,
//  UMain_form,
  UHMenu,
  UHMenu2,
  UResetPassword,
  UCheckSichFrage,
  UWaitConnect,
  UWaitConnect2,
  UWaitConnect3,
  UEditStrTouchPW,
  UAnzPDF,
  App.Config, WEBLib.Imaging.pngimage;



type

  TLoginProc = reference to function(const UserName, Password: string): Boolean;


  TLoginForm = class(TForm)
    Login_WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebEdit2: TEdit;
    Login_WebImageControl1: TImageControl;
    WebButton3: TButton;
    WebPanel1: TPanel;
    Anmelde_Button1: TButton;
    Neues_KontoButton2: TButton;
    WebTimer1: TTimer;
    WebCheckBox1: TCheckBox;
    WebCheckBox2: TCheckBox;
    WebTimer2: TTimer;
    procedure Anmelde_Button1Click(Sender: TObject);
    procedure Neues_KontoButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
    function Check_Email_Reset : boolean;
    procedure Zeige_Haupt_Menu(DataSet: TDataSet);
    procedure Zeige_Haupt_Menu_2(DataSet: TDataSet);
    procedure Connected_to_Server(Sender: TObject);
    procedure Connected_to_Server_1(Sender: TObject);
    procedure Connected_to_Server_3(Sender: TObject);
    procedure Connect_APIServer;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure ServerError_1(Error: TXDataWebConnectionError);
    procedure ServerError_2(Error: TXDataWebConnectionError);
    procedure ServerError_3(Error: TXDataWebConnectionError);
    procedure WebFormResize(Sender: TObject);
    procedure WebLabel8Click(Sender: TObject);
    procedure WebEdit1Exit(Sender: TObject);
    procedure WebEdit2Exit(Sender: TObject);
    procedure WebEdit1Click(Sender: TObject);
    procedure WebEdit2Click(Sender: TObject);
    procedure Check_User_Password;
    procedure Connect_Service_Server;
    procedure Forgotten_Password;
//    procedure XDataWebConnection1_Error(Error: TXDataWebConnectionError);
    procedure WebTimer1Timer(Sender: TObject);
    procedure WebCheckBox2Click(Sender: TObject);
    procedure WebTimer2Timer(Sender: TObject);

  private
    { Private-Deklarationen }
    FOnLogin: TLoginProc;
    FOnCreateAccount: TLoginProc;

    procedure CheckLogin;
    procedure DoLogout;
    procedure UpdateButtons;
    procedure DisableTasks;
    procedure EnableTasks;
    function GetToken: string;
    procedure MessageEncrypted(ADataAsArrayBuffer: TJSArrayBuffer);
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen
    class function DoLogin(LoginProc, CreateAccountProc: TLoginProc): Boolean;
    property OnLogin: TLoginProc read FOnLogin write FOnLogin;
    property OnCreateAccount: TLoginProc read FOnCreateAccount write FOnCreateAccount;
}
    KDID,
    TNID : integer;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    Org_Height : integer;
    Akt_EADR,
    Akt_KW : String;
    HM_Aufgerufen : boolean;

    PushC_Liste : TStringList;

  end;

var
  LoginForm: TLoginForm;

implementation





procedure TLoginForm.DisableTasks;
begin
end;

procedure TLoginForm.DoLogout;
begin
end;

procedure TLoginForm.EnableTasks;
begin
end;

function TLoginForm.GetToken: string;
begin
  if not JS.IsNull(window.localStorage.getItem(cStorageTokenName)) then
    Result := window.localStorage.getItem(cStorageTokenName)
  else
    Result := '';
end;



procedure TLoginForm.UpdateButtons;
begin
{
  if XDataWebDataset.Active then
  begin
    btUpdateTask.Enabled := XDataWebDataset.State in [dsEdit, dsInsert];
    btCancel.Enabled := XDataWebDataset.State in [dsInsert, dsEdit];
    btDelete.Enabled := not XDataWebDataset.IsEmpty;
    btNewTask.Enabled := XDataWebDataset.State in [dsBrowse];
  end
  else
  begin
    btUpdateTask.Enabled := false;
    btCancel.Enabled := false;
    btDelete.Enabled := false;
    btNewTask.Enabled := false;
  end;
}
end;



procedure TLoginForm.Anmelde_Button1Click(Sender: TObject);
var NLK,hst,
    hst2,hst3 : String;
    Values: TJSArray;
//    Values2: TJSValueDynArray;
    Menuform: THMenu;
    i,j,UKA: integer;
begin
  UnterKonto_Aktiv := false;
  UK_EMail := '';
  UnterKonto_ID := 0;
  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
    exit;
  end;

  hst2 := Trim(WebEdit2.Text);
  WebEdit2.Text := hst2;
  if hst2 = '' then begin
    MessageDlg('Kennwort muß erfasst werden', mtError, [], nil);
    exit;
  end;
  Akt_EADR := WebEdit1.Text;
  repeat
    i := pos('ü',Akt_EADR);
    if i > 0 then begin
      system.delete(Akt_EADR,i,1);
      system.Insert('ue',Akt_EADR,i);
    end;
  until i = 0;
  repeat
    i := pos('ö',Akt_EADR);
    if i > 0 then begin
      system.delete(Akt_EADR,i,1);
      system.Insert('oe',Akt_EADR,i);
    end;
  until i = 0;
  repeat
    i := pos('ä',Akt_EADR);
    if i > 0 then begin
      system.delete(Akt_EADR,i,1);
      system.Insert('ae',Akt_EADR,i);
    end;
  until i = 0;
  repeat
    i := pos('Ü',Akt_EADR);
    if i > 0 then begin
      system.delete(Akt_EADR,i,1);
      system.Insert('UE',Akt_EADR,i);
    end;
  until i = 0;
  repeat
    i := pos('Ö',Akt_EADR);
    if i > 0 then begin
      system.delete(Akt_EADR,i,1);
      system.Insert('OE',Akt_EADR,i);
    end;
  until i = 0;
  repeat
    i := pos('Ä',Akt_EADR);
    if i > 0 then begin
      system.delete(Akt_EADR,i,1);
      system.Insert('AE',Akt_EADR,i);
    end;
  until i = 0;



  asm debugger; end;
  WebEdit1.Text := Akt_EADR;



  Akt_KW := WebEdit2.Text;
  Push_Controls(self,PushC_Liste);

  Check_User_Password;
end;

procedure TLoginForm.Neues_KontoButton2Click(Sender: TObject);
var
  newform: TNeuesKonto2;

begin
  Push_Controls(self,PushC_Liste);

//  if Create_Form(TNeuesKonto2,newForm) then ;

  newform := TNeuesKonto2.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TNeuesKonto2).Caption := 'Neues Konto';
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    Pop_Controls(self,PushC_Liste);

//
  end
  );


end;


procedure TLoginForm.WebButton3Click(Sender: TObject);
var hst : String;
begin
  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Email-Adresse muß erfasst werden', mtError, [], nil);
    exit;
  end;
  Push_Controls(self,PushC_Liste);
  asm debugger; end;
  Forgotten_Password;
end;

procedure TLoginForm.WebCheckBox2Click(Sender: TObject);
begin
  if WebCheckBox2.Checked then begin
    WebEdit2.PasswordChar := #0;
    Password_Anzeigen := true;
  end
  else begin
    WebEdit2.PasswordChar := '*';
    Password_Anzeigen := false;
  end;
end;

procedure TLoginForm.WebEdit1Click(Sender: TObject);
var newform : TEditStrTouchPW;
begin
  if not Device_Ist_Handy then exit;

  UES_HeaderPW := WebLabel2.Caption;
  UES_StringPW := WebEdit2.Text;
  UES_Username := WebEdit1.Text;
  newform := TEditStrTouchPW.CreateNew(procedure(AForm: TObject)
    begin

    end
  );
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    WebEdit2.Text := Trim(newform.WebEdit1.Text);
    WebEdit1.Text := Trim(newform.WebEdit2.Text);
    if Password_Anzeigen then begin
      WebEdit2.PasswordChar := #0;
      WebCheckBox2.Checked := true;
    end
    else begin
      WebEdit2.PasswordChar := '*';
      WebCheckBox2.Checked := false;
    end;

    if (WebEdit2.Text <> '') and (WebEdit1.Text <> '') then begin
      WebTimer1.Enabled := true;
    end;
  end
  );


//  GetEditStrTouchPW(WebLabel2.Caption,WebEdit2.Text,WebEdit1.Text,WebEdit2,WebEdit1);

end;

procedure TLoginForm.WebEdit1Exit(Sender: TObject);
begin
  exit;
  if Device_Ist_Handy then begin
    if Height < Org_Height then Height := Org_Height;
    WebPanel1.Visible := true;
    WebPanel1.Top := WebButton3.Top + WebButton3.Height + 30;
    WebPanel1.Align := alBottom;
  end;
end;

procedure TLoginForm.WebEdit2Click(Sender: TObject);
var newform : TEditStrTouchPW;
begin

  if not Device_Ist_Handy then exit;

  UES_HeaderPW := WebLabel2.Caption;
  UES_StringPW := WebEdit2.Text;
  UES_Username := WebEdit1.Text;
  newform := TEditStrTouchPW.CreateNew(procedure(AForm: TObject)
    begin

    end
  );
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    WebEdit2.Text := Trim(newform.WebEdit1.Text);
    WebEdit1.Text := Trim(newform.WebEdit2.Text);
    if (WebEdit2.Text <> '') and (WebEdit1.Text <> '') then begin
      WebTimer1.Enabled := true;
    end;
  end
  );


//  GetEditStrTouchPW(WebLabel2.Caption,WebEdit2.Text,WebEdit1.Text,WebEdit2,WebEdit1);

end;

procedure TLoginForm.WebEdit2Exit(Sender: TObject);
begin
  exit;
  if Device_Ist_Handy then begin
    if Height < Org_Height then Height := Org_Height;

    WebPanel1.Visible := true;
    WebPanel1.Top := WebButton3.Top + WebButton3.Height + 30;
    WebPanel1.Align := alBottom;
  end;
end;

procedure TLoginForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
//  Datamodule1.XDataWebConnection1.Connected := false;
//  Datamodule1.XDataWebConnection2.Connected := false;
//  Datamodule1.XDataWebConnection3.Connected := false;
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;


{
  asm
    window.location.href = 'http://www.google.de'; // call any JS here
  end;
}
end;

procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
//  Application.OnImageCacheReady := nil;
  Akt_JWToken := '';
  Form_Aktiv := false;
  Org_Height := 500;
  HM_Aufgerufen := false;
  PushC_Liste := TStringList.Create;


end;

procedure TLoginForm.WebFormResize(Sender: TObject);
begin
//  WebLabel1.caption := window.navigator.userAgent;
//  WebLabel3.caption := string(window.screen['width'])+':'+string(window.screen['height']);
  if not Form_Aktiv then exit;
  exit;
  if Height < Org_Height then Height := Org_Height;

  Resize_Edit_Controls(WF_Login,self,Zeilen_Liste,Login_WebImageControl1,WebPanel1);
//  WebPanel1.Visible := true;
  WebPanel1.Align := alBottom;


end;

procedure TLoginForm.WebFormShow(Sender: TObject);
var hst : String;
    newform: TWaitConnect;
    LS : TLocalStorage;
begin
  asm debugger; end;
  HauptFenster := self;
  Akt_JWToken := '';
  Login_WebImageControl1.Url := LogoURL; // 'images/haustein2.bmp';
  WebCheckBox1.Checked := false;
  try
    LS := TLocalStorage.Create;
    hst := LS.Getvalue(cStorageEmailAdresse);
  finally
    LS.Free;
  end;
  if hst <> '' then begin
    WebEdit1.Text := hst;
    WebCheckBox1.Checked := true;
  end;
  if Datamodule1.CD_Ergebnis <> '' then begin
    WebEdit2.Text := Datamodule1.CD_Ergebnis;
    WebCheckBox1.Checked := true;
  end;


//  WebLabel1.caption := window.navigator.userAgent;
//  WebLabel3.caption := string(window.screen['width'])+':'+string(window.screen['height']);

  Email_OK := false;

  Device_Ist_Handy := true;


  Anmelde_Button1.Enabled := true;
  Neues_KontoButton2.Enabled := true;
  WebButton3.Enabled := true;
  Anmelde_Button1.Visible := true;
  Neues_KontoButton2.Visible := true;
  WebButton3.Visible := true;

  Resize_Top_Panel_6(self,Login_WebImageControl1);

//  Resize_Bottom_Panel(self,WebPanel1);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;

//  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel1,nil,nil);
//  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel3,nil,nil);


  Mit_On_Click := false;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,Login_WebLabel1,WebEdit1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebEdit2,nil);
  Zeilen_Liste_Set(Zeilen_Liste,WebEdit2,WebButton3);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox2,nil);

  Mit_On_Click := true;

  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Login,self,Zeilen_Liste,Login_WebImageControl1,WebPanel1);

  WebPanel1.Visible := true;
  WebPanel1.Align := alBottom;


//  WebLabel1.caption := window.navigator.userAgent;
//  WebLabel3.caption := string(window.screen['width'])+':'+string(window.screen['height']);

{  Datamodule1.XDataWebConnection2.OnConnect := Connected_to_Server;
  Datamodule1.XDataWebConnection2.OnError := ServerError_2;
  Datamodule1.XDataWebConnection2.Connected := True;

  newform := TWaitConnect.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );
}

  Org_Height := Height;

end;

procedure TLoginForm.WebLabel8Click(Sender: TObject);
begin
  Anzeige_PDF('Datenschutzerklärung','Datenschutzerklaerung.pdf');
end;

procedure TLoginForm.WebTimer1Timer(Sender: TObject);
begin
  WebTimer1.Enabled := false;
  Anmelde_Button1Click(Self);
end;

procedure TLoginForm.WebTimer2Timer(Sender: TObject);
begin
  WebTimer2.Enabled := false;
  if Email_OK then begin
    Close;
    exit;
  end;
  WebTimer2.Enabled := true;
end;

procedure TLoginForm.CheckLogin;
begin
  if GetToken = '' then
    DisableTasks
  else begin

  end;
end;

procedure TLoginForm.XDataWebConnection1Request(Args: TXDataWebConnectionRequest);
begin
  if GetToken <> '' then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + GetToken);
end;






procedure TLoginForm.Zeige_Haupt_Menu(DataSet: TDataSet);
var Menuform: THMenu;
    NewForm : THMenu2;
    i : integer;
begin
  if HM_Aufgerufen then exit;
  HM_Aufgerufen := true;
//  if Akt_JWToken = '' then exit;
//  i := DataModule1.KundenDataSet.ServerRecordCount;
  asm debugger; end;
  if DataModule1.KundenDataSet.RecordCount = 0 then exit;
  AktKDID := DataModule1.KundenDataset.Fieldbyname('ID').AsInteger;
  if UnterKonto_Aktiv then begin
    AktKDName := DataModule1.KundenDataset.Fieldbyname('EMailAdr').AsString;
  end;
//  ShowMessage(IntToStr(AktKDID));

  if DataModule1.KundenDataSet.Fieldbyname('AdminLevel').AsInteger > 0 then begin
//    WebEdit1.Text := '';
//    WebEdit2.Text := '';

//    if Create_Form(THMenu2,NewForm) then ;

    Newform := THMenu2.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu2).Caption := 'Hauptmenü';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
      HM_Aufgerufen := false;
      Close;
    end
    );


  end
  else begin

//    WebEdit1.Text := '';
//    WebEdit2.Text := '';

//    if Create_Form(THMenu,MenuForm) then ;

    Menuform := THMenu.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu).Caption := 'Hauptmenü';
      end
    );
    Menuform.Border := fbSingle;

    Menuform.ShowModal(procedure(AValue: TModalResult)
    begin
      HM_Aufgerufen := false;
  asm debugger; end;
      WebEdit1.Text := Akt_EADR;
      WebEdit2.Text := Akt_KW;

      Close;
    end
    );

  end;
end;



procedure TLoginForm.Zeige_Haupt_Menu_2(DataSet: TDataSet);
var Newform: THMenu2;
    i : integer;
begin
//  if Akt_JWToken = '' then exit;
//  i := DataModule1.KundenDataSet.ServerRecordCount;
  if DataModule1.KundenDataSet.RecordCount = 0 then exit;

//    WebEdit1.Text := '';
//    WebEdit2.Text := '';

//    if Create_Form(THMenu2,NewForm) then ;

    Newform := THMenu2.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as THMenu2).Caption := 'Hauptmenü';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
      Close;
    end
    );

end;



function TLoginForm.Check_Email_Reset : boolean;
var newForm : TCheckSichFrage;
begin
  newform := TCheckSichFrage.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TCheckSichFrage).Caption := 'Sicherheitsfrage';
      end
    );
    newform.Border := fbSingle;

    newform.ShowModal(procedure(AValue: TModalResult)
    begin
      Pop_Controls(self,PushC_Liste);
    end
    );
end;

procedure TLoginForm.Connected_to_Server(Sender: TObject);
begin
  Anmelde_Button1.Enabled := true;
  Neues_KontoButton2.Enabled := true;
  WebButton3.Enabled := true;
  Anmelde_Button1.Visible := true;
  Neues_KontoButton2.Visible := true;
  WebButton3.Visible := true;

end;


procedure TLoginForm.Connected_to_Server_1(Sender: TObject);
var hst : String;
    newform : TWaitConnect3;
begin
//  Datamodule1.XDataWebConnection3.Connected := True;
//  Connected_to_Server_3(Self);
//  exit;
    Datamodule1.XDataWebConnection3.OnConnect := Connected_to_Server_3;
    Datamodule1.XDataWebConnection3.OnError := ServerError_3;
    Datamodule1.XDataWebConnection3.Connected := True;


  asm debugger; end;
  newform := TWaitConnect3.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    

  end
  );


end;

procedure TLoginForm.Connected_to_Server_3(Sender: TObject);
var hst : String;
begin
  DataModule1.KundenDataset.Close;
  DataModule1.KundenDataset.AfterOpen := Zeige_Haupt_Menu;
  hst := Trim(Uppercase(WebEdit1.Text));
  if UnterKonto_Aktiv then begin
    DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (ID eq '+IntToStr(KDID)+')';
    UnterKonto_ID := TNID;
  end
  else begin
    DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
  end;
  DataModule1.KundenDataset.Load;

end;

procedure TLoginForm.Connect_APIServer;
var
  newform : TWaitConnect2;


  procedure OnConnect;
  begin
    newform.WebTimer1.Enabled := false;
    newform.Close;
//    Datamodule1.XDataWebConnection1.OnError := XDataWebConnection1_Error;
    Connect_Service_Server;

  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    newform.WebTimer1.Enabled := false;
    newform.Close;

    hst := 'API-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;




begin
  newform := TWaitConnect2.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );
  Datamodule1.XDataWebConnection1.Open(@OnConnect, @OnError);

{
  Datamodule1.XDataWebConnection1.OnConnect := Connected_to_Server_1;
  Datamodule1.XDataWebConnection1.OnError := ServerError_1;
  Datamodule1.XDataWebConnection1.Connected := true;

  newform := TWaitConnect2.CreateNew(procedure(AForm: TObject)
    begin

    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );
}

end;



procedure TLoginForm.ServerError_3(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 3 Fehler: '+Error.ErrorMessage;
  MessageDlg(hst, mtError, [], nil);
end;


procedure TLoginForm.ServerError_2(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 2 Fehler: '+Error.ErrorMessage;
  MessageDlg(hst, mtError, [], nil);
end;

procedure TLoginForm.ServerError_1(Error: TXDataWebConnectionError);
var hst : String;
begin
  hst := 'Server 1 Fehler: '+Error.ErrorMessage;
  MessageDlg(hst, mtError, [], nil);
end;


procedure TLoginForm.Check_User_Password;
var User_Name,
    Kennwort : String;
    NLK : String;
    i,j,UKA: integer;
    newform: TWaitConnect;

  procedure OnConnect;
  begin
    newform.WebTimer1.Enabled := false;
    newform.Close;

    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Login', [NLK,User_Name, Kennwort],
      procedure(Response: TXDataClientResponse)
      var
        Token,
        hst3: string;
      begin
        Token := JS.ToString(JS.ToObject(Response.Result)['value']);
        if Token <> '' then begin
          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          UKA := StrToInt(hst3);
          system.Delete(Token,1,i);

          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          KDID := StrToInt(hst3);
          system.Delete(Token,1,i);

          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          TNID := StrToInt(hst3);
          system.Delete(Token,1,i);

          if UKA = 1 then begin
            UnterKonto_Aktiv := true;
          end;
  asm debugger; end;
          Akt_JWToken := Token;
          AktKDName := WebEdit1.Text;
          if WebCheckBox1.Checked then begin
            Datamodule1.aesGCM.OnEncrypted := MessageEncrypted;
            Datamodule1.aesGCM.Encrypt(WebEdit2.Text);
          end;


          Datamodule1.XDataWebConnection2.Close;

  asm debugger; end;
          Connect_APIServer;
        end;
      end,

      procedure(Error: TXDataClientError)
      begin
        Pop_Controls(self,PushC_Liste);
        MessageDlg('Email-Adresse oder Kennwort falsch oder keine Internet-Verbindung', mtError, [], nil);
      end
     );
  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    newform.WebTimer1.Enabled := false;
    newform.Close;
    Pop_Controls(self,PushC_Liste);

    hst := 'Auth-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;


begin
  Datamodule1.XDataWebConnection2.Close;
  User_Name := Uppercase(WebEdit1.Text);
  repeat
    i := pos('ü',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('UE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('ö',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('OE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('ä',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('AE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('Ü',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('UE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('Ö',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('OE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('Ä',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('AE',User_name,i);
    end;
  until i = 0;




//  WebEdit1.Text := User_name;


  asm debugger; end;
  Kennwort := WebEdit2.Text;
  NLK := IntToStr(AktFirmaNo);
  UnterKonto_Aktiv := false;
  Akt_JWToken := '';
  AktKDName := '';
  KDID := 0;
  TNID := 0;
  newform := TWaitConnect.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );

  Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);

end;



procedure TLoginForm.Connect_Service_Server;
var
  newform : TWaitConnect3;


  procedure OnConnect;
  var hst : String;
  begin
  asm debugger; end;
    newform.WebTimer1.Enabled := false;
    newform.Close;
    DataModule1.KundenDataset.Close;
    DataModule1.KundenDataset.AfterOpen := Zeige_Haupt_Menu;
    hst := Trim(Uppercase(WebEdit1.Text));
    if UnterKonto_Aktiv then begin
      DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (ID eq '+IntToStr(KDID)+')';
      UnterKonto_ID := TNID;
    end
    else begin
      DataModule1.KundenDataset.QueryString := '$filter=(NL_Key eq '+IntToStr(AktFirmaNo)+') AND (UPC_EMAIL eq '+''''+hst+''''+')';
    end;
    DataModule1.KundenDataset.Load;
  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    newform.WebTimer1.Enabled := false;
    newform.Close;

    hst := 'Service-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;




begin
  newform := TWaitConnect3.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );
  Datamodule1.XDataWebConnection3.Open(@OnConnect, @OnError);
end;


procedure TLoginForm.Forgotten_Password;
var User_Name,
    Kennwort,hst : String;
    NLK : String;
    i,j,UKA: integer;
    newform: TWaitConnect;

  procedure OnConnect;
  begin
    newform.WebTimer1.Enabled := false;
    newform.Close;

    NLK := IntToStr(AktFirmaNo);
    Akt_SFrage := '';
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.GetSicherheitsfrage', [NLK,User_Name],
        procedure(Response: TXDataClientResponse)
        var
          F: string;
        begin
          F := JS.ToString(JS.ToObject(Response.Result)['value']);
          if F <> '' then begin
            Akt_SFrage := F;
            AktKDName := WebEdit1.Text;
            if Check_Email_Reset then ;

          end
          else begin
            Pop_Controls(self,PushC_Liste);
            MessageDlg('Email-Adresse ungültig oder keine Sicherheitsfragen erfasst', mtError, [], nil);
          end;
        end,

        procedure(Error: TXDataClientError)
        begin
          Pop_Controls(self,PushC_Liste);
          MessageDlg('Keine Verbindung zum Server', mtError, [], nil);

        end
       );

  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    newform.WebTimer1.Enabled := false;
    newform.Close;

    hst := 'Auth-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;


begin
  asm debugger; end;
  Datamodule1.XDataWebConnection2.Close;
  User_Name := Uppercase(WebEdit1.Text);
  Kennwort := WebEdit2.Text;
  NLK := IntToStr(AktFirmaNo);
  UnterKonto_Aktiv := false;
  Akt_JWToken := '';
  AktKDName := '';
  KDID := 0;
  TNID := 0;
  newform := TWaitConnect.CreateNew(procedure(AForm: TObject)
    begin
    end
  );
  newform.Border := fbSingle;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );

  Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);

end;

{
procedure TLoginForm.XDataWebConnection1_Error(Error: TXDataWebConnectionError);
var hst : String;
begin
  if PushC_Liste.Count > 0 then begin
    Pop_Controls(self,PushC_Liste);
  end;
  hst := 'API-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
  MessageDlg(hst, mtError, [], nil);

end;

}


procedure TLoginForm.MessageEncrypted(ADataAsArrayBuffer: TJSArrayBuffer);
var
  s: string;
  LS : TLocalStorage;
begin
  s := ABToBinaryString(ADataAsArrayBuffer);
  try
    LS := TLocalStorage.Create;
    LS.Setvalue(cStoragePW, s);
    LS.Setvalue(cStorageEmailAdresse, AktKDName);
  finally
    LS.Free;
  end;
end;



procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Login_WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  Login_WebImageControl1 := TImageControl.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  Anmelde_Button1 := TButton.Create(Self);
  Neues_KontoButton2 := TButton.Create(Self);
  WebCheckBox1 := TCheckBox.Create(Self);
  WebCheckBox2 := TCheckBox.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  WebTimer2 := TTimer.Create(Self);

  Login_WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  Login_WebImageControl1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  Anmelde_Button1.BeforeLoadDFMValues;
  Neues_KontoButton2.BeforeLoadDFMValues;
  WebCheckBox1.BeforeLoadDFMValues;
  WebCheckBox2.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  WebTimer2.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Left := 0;
    Top := 0;
    Width := 320;
    Height := 489;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Login_WebLabel1.SetParentComponent(Self);
    Login_WebLabel1.Name := 'Login_WebLabel1';
    Login_WebLabel1.Left := 16;
    Login_WebLabel1.Top := 239;
    Login_WebLabel1.Width := 83;
    Login_WebLabel1.Height := 16;
    Login_WebLabel1.Caption := 'eMail-Adresse';
    Login_WebLabel1.Font.Charset := DEFAULT_CHARSET;
    Login_WebLabel1.Font.Color := clWindowText;
    Login_WebLabel1.Font.Height := -13;
    Login_WebLabel1.Font.Name := 'Arial';
    Login_WebLabel1.Font.Style := [];
    Login_WebLabel1.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 289;
    WebLabel2.Width := 54;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Kennwort';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    Login_WebImageControl1.SetParentComponent(Self);
    Login_WebImageControl1.Name := 'Login_WebImageControl1';
    Login_WebImageControl1.Left := 7;
    Login_WebImageControl1.Top := 8;
    Login_WebImageControl1.Width := 305;
    Login_WebImageControl1.Height := 185;
    Login_WebImageControl1.ChildOrder := 5;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 8;
    WebEdit1.Top := 261;
    WebEdit1.Width := 289;
    WebEdit1.Height := 22;
    WebEdit1.AutoCompletion := acUserName;
    WebEdit1.ChildOrder := 1;
    WebEdit1.Font.Charset := DEFAULT_CHARSET;
    WebEdit1.Font.Color := clWindowText;
    WebEdit1.Font.Height := -16;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Style := [];
    WebEdit1.ParentFont := False;
    SetEvent(WebEdit1, Self, 'OnClick', 'WebEdit1Click');
    SetEvent(WebEdit1, Self, 'OnExit', 'WebEdit1Exit');
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 311;
    WebEdit2.Width := 129;
    WebEdit2.Height := 22;
    WebEdit2.AutoCompletion := acCurrentPassword;
    WebEdit2.ChildOrder := 3;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.Font.Charset := DEFAULT_CHARSET;
    WebEdit2.Font.Color := clWindowText;
    WebEdit2.Font.Height := -16;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Style := [];
    WebEdit2.ParentFont := False;
    WebEdit2.PasswordChar := '*';
    SetEvent(WebEdit2, Self, 'OnClick', 'WebEdit2Click');
    SetEvent(WebEdit2, Self, 'OnExit', 'WebEdit2Exit');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 151;
    WebButton3.Top := 306;
    WebButton3.Width := 154;
    WebButton3.Height := 30;
    WebButton3.Caption := 'Kennwort vergessen';
    WebButton3.ChildOrder := 8;
    WebButton3.ElementClassName := 'btn-secondary';
    WebButton3.Enabled := False;
    WebButton3.Font.Charset := DEFAULT_CHARSET;
    WebButton3.Font.Color := clWindowText;
    WebButton3.Font.Height := -15;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Style := [];
    WebButton3.ParentFont := False;
    WebButton3.Visible := False;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 424;
    WebPanel1.Width := 320;
    WebPanel1.Height := 65;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 8;
    Anmelde_Button1.SetParentComponent(WebPanel1);
    Anmelde_Button1.Name := 'Anmelde_Button1';
    Anmelde_Button1.Left := 8;
    Anmelde_Button1.Top := 7;
    Anmelde_Button1.Width := 121;
    Anmelde_Button1.Height := 41;
    Anmelde_Button1.Caption := 'Anmelden';
    Anmelde_Button1.ChildOrder := 4;
    Anmelde_Button1.ElementClassName := 'btn btn-primary';
    Anmelde_Button1.Enabled := False;
    Anmelde_Button1.Font.Charset := DEFAULT_CHARSET;
    Anmelde_Button1.Font.Color := clWindowText;
    Anmelde_Button1.Font.Height := -15;
    Anmelde_Button1.Font.Name := 'Arial';
    Anmelde_Button1.Font.Style := [];
    Anmelde_Button1.ParentFont := False;
    Anmelde_Button1.Visible := False;
    SetEvent(Anmelde_Button1, Self, 'OnClick', 'Anmelde_Button1Click');
    Neues_KontoButton2.SetParentComponent(WebPanel1);
    Neues_KontoButton2.Name := 'Neues_KontoButton2';
    Neues_KontoButton2.Left := 135;
    Neues_KontoButton2.Top := 7;
    Neues_KontoButton2.Width := 170;
    Neues_KontoButton2.Height := 41;
    Neues_KontoButton2.Caption := 'Neues Konto erstellen';
    Neues_KontoButton2.ChildOrder := 7;
    Neues_KontoButton2.ElementClassName := 'btn-secondary';
    Neues_KontoButton2.Enabled := False;
    Neues_KontoButton2.Font.Charset := DEFAULT_CHARSET;
    Neues_KontoButton2.Font.Color := clWindowText;
    Neues_KontoButton2.Font.Height := -15;
    Neues_KontoButton2.Font.Name := 'Arial';
    Neues_KontoButton2.Font.Style := [];
    Neues_KontoButton2.ParentFont := False;
    Neues_KontoButton2.Visible := False;
    SetEvent(Neues_KontoButton2, Self, 'OnClick', 'Neues_KontoButton2Click');
    WebCheckBox1.SetParentComponent(Self);
    WebCheckBox1.Name := 'WebCheckBox1';
    WebCheckBox1.Left := 8;
    WebCheckBox1.Top := 375;
    WebCheckBox1.Width := 233;
    WebCheckBox1.Height := 22;
    WebCheckBox1.Caption := 'Anmeldedaten speichern';
    WebCheckBox1.ChildOrder := 7;
    WebCheckBox1.Color := clNone;
    WebCheckBox1.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox1.Font.Color := clWindowText;
    WebCheckBox1.Font.Height := -16;
    WebCheckBox1.Font.Name := 'Arial';
    WebCheckBox1.Font.Style := [];
    WebCheckBox1.ParentFont := False;
    WebCheckBox1.State := cbUnchecked;
    WebCheckBox2.SetParentComponent(Self);
    WebCheckBox2.Name := 'WebCheckBox2';
    WebCheckBox2.Left := 8;
    WebCheckBox2.Top := 344;
    WebCheckBox2.Width := 249;
    WebCheckBox2.Height := 22;
    WebCheckBox2.Caption := 'Kennwort anzeigen';
    WebCheckBox2.ChildOrder := 8;
    WebCheckBox2.Color := clNone;
    WebCheckBox2.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox2.Font.Color := clWindowText;
    WebCheckBox2.Font.Height := -16;
    WebCheckBox2.Font.Name := 'Arial';
    WebCheckBox2.Font.Style := [];
    WebCheckBox2.ParentFont := False;
    WebCheckBox2.State := cbUnchecked;
    SetEvent(WebCheckBox2, Self, 'OnClick', 'WebCheckBox2Click');
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 100;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 144;
    WebTimer1.Top := 208;
    WebTimer2.SetParentComponent(Self);
    WebTimer2.Name := 'WebTimer2';
    WebTimer2.Enabled := False;
    WebTimer2.Interval := 100;
    SetEvent(WebTimer2, Self, 'OnTimer', 'WebTimer2Timer');
    WebTimer2.Left := 216;
    WebTimer2.Top := 208;
  finally
    Login_WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    Login_WebImageControl1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    Anmelde_Button1.AfterLoadDFMValues;
    Neues_KontoButton2.AfterLoadDFMValues;
    WebCheckBox1.AfterLoadDFMValues;
    WebCheckBox2.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    WebTimer2.AfterLoadDFMValues;
  end;
end;

end.
