unit UEditPasswort;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, UDatamodule,
  UGlobvar, WEBLib.DBCtrls, WEBLib.ExtCtrls;

type
  TEditPasswort = class(TForm)
    WebLabel2: TLabel;
    WebLabel4: TLabel;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebEdit4: TEdit;
    WebLabel3: TLabel;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    PushC_Liste : TStringList;

  end;

var
  EditPasswort: TEditPasswort;

implementation


procedure TEditPasswort.WebButton1Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  Close;
end;

procedure TEditPasswort.WebButton2Click(Sender: TObject);
var hst,hst2 : String;
begin
  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Altes Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;
  hst := Trim(WebEdit3.Text);
  WebEdit3.Text := hst;
  if hst = '' then begin
    MessageDlg('Neues Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  if pos(' ',hst) > 0 then begin
    MessageDlg('Leerzeichen im Kennwort nicht erlaubt', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;

  if length(hst) < 6 then begin
    MessageDlg('Kennwort muß mindestens 6 Zeichen lang sein', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;



  hst := Trim(WebEdit4.Text);
  WebEdit4.Text := hst;
  if hst = '' then begin
    MessageDlg('Wiederholung neues Kennwort muß erfasst werden', mtError, [], nil);
    WebEdit4.SetFocus;
    exit;
  end;

  if WebEdit3.Text <> WebEdit4.Text then begin
    MessageDlg('Neues Kennwort und Wiederholung neues Kennwort nicht identisch', mtError, [], nil);
    WebEdit4.SetFocus;
    exit;
  end;

  if WebEdit2.Text <> DataModule1.KundenDataSet.FieldByName('Kennwort').AsString then begin
    MessageDlg('Altes Kennwort ist ungültig', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;

  Push_Controls(self,PushC_Liste);

  try
    DataModule1.KundenDataSet.Edit;
    DataModule1.KundenDataSet.FieldByName('Kennwort').AsString := WebEdit3.Text;
    DataModule1.KundenDataSet.Post;
    DataModule1.KundenDataSet.ApplyUpdates;
  except
    Pop_Controls(self,PushC_Liste);

    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;

end;

procedure TEditPasswort.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;

end;

procedure TEditPasswort.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  PushC_Liste := TStringList.Create;


end;

procedure TEditPasswort.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEditPasswort.WebFormShow(Sender: TObject);
begin
  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebEdit2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebEdit3,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel3,WebEdit4,nil);
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  Form_Aktiv := true;

end;

procedure TEditPasswort.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'EditPasswort';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 112;
    WebLabel2.Width := 112;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Aktuelles Kennwort';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 167;
    WebLabel4.Width := 95;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Neues Kennwort';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 219;
    WebLabel3.Width := 176;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Wiederholung neues Kennwort';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 136;
    WebEdit2.Width := 177;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 8;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.PasswordChar := '*';
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 8;
    WebEdit3.Top := 191;
    WebEdit3.Width := 177;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 8;
    WebEdit3.ElementClassName := 'form-control';
    WebEdit3.PasswordChar := '*';
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 8;
    WebEdit4.Top := 243;
    WebEdit4.Width := 177;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 8;
    WebEdit4.ElementClassName := 'form-control';
    WebEdit4.PasswordChar := '*';
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 7;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 0;
    WebButton2.Top := 16;
    WebButton2.Width := 169;
    WebButton2.Height := 41;
    WebButton2.Caption := #196'nderungen speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 185;
    WebButton1.Top := 16;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.