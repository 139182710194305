unit UGlobvar;

interface

uses
  SysUtils, Classes,  WEBLib.StdCtrls, WEBLib.Controls,JS, Web, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs;

type

  PPushControl_Stack_Rec = ^TPushControl_Stack_Rec;
  TPushControl_Stack_Rec = record
                             f : TForm;
                             TS : TStringList;
                           end;



  TGlobvar = class(TForm)
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
  end;



const
  cStorageTokenName = 'KWSpeiseplan-token';
  cStorageEmailAdresse = 'KWSpeiseplan_Email';
  cStoragePW = 'KWSpeiseplan_PW';



      maxAllergene = 14;

      Action_New = 1;
      Action_Edit = 2;
      Action_Delete = 3;
      Action_First = 4;
      Action_Last = 5;
      Action_Next = 6;
      Action_Prior = 7;
      Action_PgUp = 8;
      Action_PgDn = 9;
      Action_Scan = 10;
      Action_Home = 11;
      Action_Copy = 12;
      Action_Close = 13;
      Action_Cancel = 14;
      Action_Save = 15;
      Action_Print = 16;
      Action_Filter = 17;
      Action_Help = 18;
      Action_Tools = 19;
      Action_Menu = 20;
      Action_Refresh = 21;

      Action_Detail_Plus = 22;
      Action_Detail_Minus = 23;
      Action_Detail_Search = 24;
      Action_Detail_OK = 25;
      Action_Detail_Cancel = 26;
      Action_Search = 27;
      Action_SelectAll = 28;
      Action_UnSelectAll = 29;
      Action_DeleteAll = 30;
      Action_Detail_Menu = 31;
      Action_Einfuegen_Vor = 32;
      Action_Einfuegen_Nach = 33;
      Action_Anhaengen = 34;
      Action_Ersetzen = 35;
      Action_Kein_Bild = 36;
      Action_Ganz_Hoch = 37;
      Action_Hoch = 38;
      Action_Runter = 39;
      Action_Ganz_Runter = 40;
      Action_Verlauf = 41;
      Action_Add_Detail_Plus = 42;
      Action_Add_Detail_Minus = 43;
      Action_Add_Detail_Edit = 44;
      Action_Kalender = 45;
      Action_JahrMinus = 46;
      Action_JahrPlus = 47;
      Action_MonatPlus = 48;
      Action_MonatMinus = 49;
      Action_WocheMinus = 50;
      Action_WochePlus = 51;
      Action_KalenderEdit = 52;
      Action_KalenderLoeschen = 53;
      Action_Publish = 54;

      OS_Unknown = -1;
      OS_IOS = 1;
      OS_OSX = 2;
      OS_Android = 3;
      OS_Windows = 4;

      Unknown_Device = -1;
      IPhone_4 = 1;
      IPhone_5 = 2;
      IPhone_7_8 = 3;
      IPhone_7_8_Plus = 4;
      IPhone_X = 5;
      IPhone_X_max = 6;
      IPhone_X_R = 7;

      Android_320_512 = 101;
      Android_undef = 199;

      Windows_1600_900 = 201;
      Windows_1280_800 = 202;
      Windows_undef = 299;


var
  HauptFenster : TForm;
  Globvar: TGlobvar;
  LogoURL,
  AktPrgName,
  AktFirmaName,
  Akt_JWToken,
  AktKDName : String;
  Laenge_ID_No,
  AktFirmaNo,
  AktKDID,
  AktTeilnehmerID,
  AktEinrichtungID : integer;
  AktTeilnehmer : integer;
  AktTeilnehmerName : String;

  AktEinrichtung : integer;
  AktEinrichtungName : String;
  Akt_Suchbegriff,
  UK_EMail : String;
  UnterKonto_ID,
  VBKDGruppe,
  VBPreisListe : integer;

  Akt_Device_Typ : integer;
  Akt_OS : integer;

  TeilnehmerMenuAuswahl,
  Auswahl_TID,
  Auswahl_TNo : integer;
  Auswahl_TName : String;
  Password_Anzeigen,
  Mit_On_Click,
  UnterKonto_Aktiv,
  Email_Geaendert : boolean;

  Caption_List1,
  Caption_List2 : TStringList;



  AllergenBez : array[1 .. maxAllergene] of String;
  AllergenID : array[1 .. maxAllergene] of integer;
  SFA : array[1 .. 10] of String;
  Bewertung1 : array[1 .. 6] of String;
  Bewertung2 : array[1 .. 6] of String;
  Bewertung3 : array[1 .. 6] of String;



  NeuKD_Anrede,
  NeuKD_Titel,
  NeuKD_Vname,
  NeuKD_Name,
  NeuKD_PLZ,
  NeuKD_Strasse,
  NeuKD_Ort,
  NeuKD_Kennwort,
  NeuKD_Kennwort2,
  NeuKD_email,
  NeuKD_email2,
  API_URL,
  AUTH_URL : String;
  Akt_SFrage : String;
  Device_Ist_Handy : boolean;
  Caption_Font_Size,
  Caption_Height,
  Edit_Font_Size,
  Edit_Height,
  Combo_Font_Size,
  Combo_Height     : integer;
  Dist_Cap_Edit : integer;
  Dist_Edit_Cap : integer;
  Bottom_Panel_Height,
  BP_Btn_Height,
  BP_Btn_Font_Size,

  Top_Panel1_Height,
  Top_Panel2_Height,
  Top_Panel3_Height,
  Top_Panel4_Height,
  Top_Panel5_Height,
  Firma_Font_Size,
  Kunden_Font_Size,
  Top_Panel_Zusatz_Font_Size : integer;
  Hinweis_Font_Size : integer;
  Grid_Font_Size : integer;
  Max_Menu_Height : integer;

  Mit_Hilfe_Button,
  Mit_AGB_etc,
  Email_OK : boolean;


  PushControl_Liste : TList;



procedure Init_Globvar;


function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);


procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);

function Get_Help_Context(AName : String) : integer;



implementation


function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;

 procedure DialogProc(AValue: TModalResult);
 begin
   Meldungs_Fenster := AValue;
 end;

begin
  case Art of
    mtWarning : MessageDlg(msgtxt, mtWarning, [], @DialogProc);
    mtError : MessageDlg(msgtxt, mtError, [], @DialogProc);
    mtInformation : MessageDlg(msgtxt, mtInformation, [], @DialogProc);
    mtConfirmation: MessageDlg(msgtxt, mtConfirmation, [mbYes, mbNo, mbCancel], @DialogProc);
    mtCustom : MessageDlg(msgtxt, mtCustom, [mbAbort, mbRetry, mbClose], @DialogProc);
  end;
end;




procedure Init_Globvar;
var hst : String;

begin
  AktPrgName := '';
  AktFirmaName := '';
  Akt_JWToken := '';
  AktKDName := '';
  AktFirmaNo := 0;
  AktKDID := 0;
  AktTeilnehmerID := 0;
  AktEinrichtungID := 0;
  AktTeilnehmer := 0;
  AktTeilnehmerName := '';
  AktEinrichtung := 0;
  AktEinrichtungName := '';
  LogoURL := '';
  VBKDGruppe := 0;
  VBPreisListe := 0;
  Laenge_ID_No := 10;

  Akt_Device_Typ := Unknown_Device;
  Device_Ist_Handy := false;

  Auswahl_TID := 0;
  Auswahl_TNo := 0;
  Auswahl_TName := '';



  AllergenBez[1] := 'Glutenhaltiges Getreide';
  AllergenBez[2] := 'Krebstiere und daraus gewonnene Erzeugnisse';
  AllergenBez[3] := 'Eier und daraus gewonnene Erzeugnisse';
  AllergenBez[4] := 'Fische und daraus gewonnene Erzeugnisse';
  AllergenBez[5] := 'Erdnüsse und daraus gewonnene Erzeugnisse';
  AllergenBez[6] := 'Sojabohnen und daraus gewonnene Erzeugnisse';
  AllergenBez[7] := 'Milch und daraus gewonnene Erzeugnisse (einschließlich Laktose)';
  AllergenBez[8] := 'Schalenfrüchte';
  AllergenBez[9] := 'Sellerie und daraus gewonnene Erzeugnisse';
  AllergenBez[10] := 'Senf und daraus gewonnene Erzeugnisse';
  AllergenBez[11] := 'Sesamsamen und daraus gewonnene Erzeugnisse';
  AllergenBez[12] := 'Schwefeldioxid und Sulfite';
  AllergenBez[13] := 'Lupinen und daraus gewonnene Erzeugnisse';
  AllergenBez[14] := 'Weichtiere und daraus gewonnene Erzeugnisse';
  AllergenID[1] := 1;
  AllergenID[2] := 2;
  AllergenID[3] := 3;
  AllergenID[4] := 4;
  AllergenID[5] := 5;
  AllergenID[6] := 6;
  AllergenID[7] := 7;
  AllergenID[8] := 8;
  AllergenID[9] := 9;
  AllergenID[10] := 10;
  AllergenID[11] := 11;
  AllergenID[12] := 12;
  AllergenID[13] := 13;
  AllergenID[14] := 14;


  SFA[1] := 'Wie war der Mädchenname Ihrer Mutter?';
  SFA[2] := 'Wie hieß Ihr erstes Haustier?';
  SFA[3] := 'Wie hieß Ihr Lieblingslehrer in der 1. Klasse?';
  SFA[4] := 'Wo verbrachten Sie Ihren ersten Urlaub?';
  SFA[5] := 'Wem wären Sie am liebsten nie begegnet?';
  SFA[6] := '';
  SFA[7] := '';
  SFA[8] := '';
  SFA[9] := '';
  SFA[10] := '';

  Bewertung1[1] := 'Hervorragend';
  Bewertung1[2] := 'Gut';
  Bewertung1[3] := 'Mittelmäßig';
  Bewertung1[4] := 'Nicht gut';
  Bewertung1[5] := 'Schlecht';
  Bewertung1[6] := '';

  Bewertung2[1] := 'Zuviel';
  Bewertung2[2] := 'Ausreichend';
  Bewertung2[3] := 'Genau richtig';
  Bewertung2[4] := 'Zu wenig';
  Bewertung2[5] := '';
  Bewertung2[6] := '';

  Bewertung3[1] := 'Ansprechend';
  Bewertung3[2] := 'In Ordnung';
  Bewertung3[3] := 'Geht so';
  Bewertung3[4] := 'Nicht gut';
  Bewertung3[5] := '';
  Bewertung3[6] := '';




  NeuKD_Anrede := '';
  NeuKD_Titel := '';
  NeuKD_Vname := '';
  NeuKD_Name := '';
  NeuKD_PLZ := '';
  NeuKD_Strasse := '';
  NeuKD_Ort := '';
  NeuKD_Kennwort := '';
  NeuKD_Kennwort2 := '';
  NeuKD_email := '';
  NeuKD_email2 := '';

  API_URL := '';
  AUTH_URL := '';
  Akt_SFrage := '';
  Email_Geaendert := false;
  UnterKonto_Aktiv := false;
  UnterKonto_ID := 0;
  UK_EMail := '';
  Akt_Suchbegriff := '';


  Caption_Font_Size := 10;
  Caption_Height := 16;
  Edit_Font_Size := 12;
  Edit_Height := 22;
  Combo_Font_Size := 12;
  Combo_Height     := 22;
  Dist_Cap_Edit := 3;
  Dist_Edit_Cap := 6;

  Bottom_Panel_Height := 120;
  BP_Btn_Height := 55;
  Top_Panel1_Height := 120;
  Top_Panel2_Height := 120;
  Top_Panel3_Height := 120;
  Top_Panel4_Height := 120;
  Top_Panel5_Height := 120;
  Firma_Font_Size := 14;
  Kunden_Font_Size := 13;
  Top_Panel_Zusatz_Font_Size := 12;
  BP_Btn_Font_Size := 12;
  Hinweis_Font_Size := 16;
  Grid_Font_Size := 16;
  Max_Menu_Height := 70;

  Email_OK := false;

  Caption_List1 := TStringList.Create;
  Caption_List2 := TStringList.Create;

  Mit_On_Click := true;
  Mit_AGB_etc := true;
  PushControl_Liste := TList.Create;
  Mit_Hilfe_Button := false;
  Password_Anzeigen := false;
end;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);
end;

procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);

  i := pos(';',AString);
  DT := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  RO := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  PF := Copy(AString,1,i-1);
  system.Delete(AString,1,i);


end;

function Get_Help_Context(AName : String) : integer;
var hst : String;
    i : integer;
begin
  hst := Uppercase(AName);
{
4.11.2020  08:26               619 Index.html
05.08.2020  15:51               619 PMainMenu.html
12.10.2020  20:00             1.395 PSpeiseplan_Client.html
26.07.2020  13:20               195 UanzArtBilder.html
26.07.2020  13:20               195 UAnzArtikel.html
26.07.2020  13:20               195 UanzPDF.html
25.07.2020  09:22               195 UAnzSpeiseplan.html
26.07.2020  18:17               195 uanzspeiseplantag.html
24.09.2020  08:38               195 UAnzSpeiseplanTag2.html
26.07.2020  13:59               195 UChangeEmail.html
26.07.2020  16:52               195 UConfirmEmail.html
26.07.2020  13:20               195 UEditBank.html
05.08.2020  20:24               195 UEditEinrichtung.html
21.07.2020  15:38               195 ueditkonto.html
25.07.2020  19:12               195 UEditKonto2.html
25.07.2020  19:22               195 UEditKonto3.html
25.07.2020  21:08               195 *UEditKonto4.html
26.07.2020  13:33               195 UEditPasswort.html
11.07.2020  13:15               195 ueditStrTouch.html
11.07.2020  13:15               195 ueditstrtouchpw.html
30.07.2020  16:36               195 ueditsubaccount.html
30.07.2020  16:36               195 UEditTeilnehmer.html
28.09.2020  18:50               195 UEditTelefon.html
05.08.2020  18:28               195 uessenausgabe.html
25.07.2020  10:02               195 UGetAuswahl.html
28.09.2020  18:50               195 ugetbewertung.html
25.07.2020  09:41               195 UGlobvar.html
25.07.2020  09:00               195 *UHMenu.html
05.08.2020  17:38               195 *UHMenu2.html
26.07.2020  13:04               195 *UKontoMenu.html
11.07.2020  13:15               195 ULogin.html
04.11.2020  08:26               195 UMainform.html
05.08.2020  15:51               195 UMain_Form.html
21.07.2020  15:38               195 UNeuesKonto.html
03.10.2020  11:38               195 UNeuesKonto2.html
25.07.2020  13:19               195 UResetPassword.html
11.07.2020  13:15               195 ustartseite.html
06.08.2020  16:34               195 UStd_Edit.html
07.08.2020  19:26               195 UStd_EditForm.html
08.08.2020  07:07               195 USTD_Suche.html
26.07.2020  18:17               195 USucheTeilnehmer.html
26.07.2020  18:17               195 UTeilnehmer.html
26.07.2020  13:04               195 *uteilnmenu.html
26.07.2020  13:20               195 uUebergabe_Chip.html
04.08.2020  14:18               195 UVWAllergien.html
05.08.2020  16:54               195 UVWEinrichtungen.html
05.08.2020  16:34               195 UVWVarianten.html
05.08.2020  18:28               195 UVW_Einrichtungen.html
25.07.2020  09:00               195 uWaitConnect.html
25.07.2020  09:00               195 uwaitconnect2.html
25.07.2020  09:00               195 uwaitconnect3.html
05.08.2020  09:23               195 UWochenplan.html
05.08.2020  18:28               195 uzeigeessen.html
26.07.2020  18:17               195 UZeigeSummen.html
}
  if hst = 'HMENU' then i := 1
  else if hst = 'HMENU2' then i := 2
  else if hst = 'KONTOMENU' then i := 3
  else if hst = 'TEILNMENU' then i := 4
  else if hst = 'EDITKONTO4' then i := 5

  ;
  Get_Help_Context := i;
end;

procedure TGlobvar.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'Globvar';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
  finally
  end;
end;

end.