unit UEditSichFragen;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,
  WEBLib.StdCtrls,
  XData.Web.Client,
  WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.ExtCtrls;

type
  TEditSichFragen = class(TForm)
    WebLabel3: TLabel;
    WebLabel2: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel7: TLabel;
    WebPanel1: TPanel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebEdit4: TEdit;
    WebComboBox1: TComboBox;
    WebComboBox2: TComboBox;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    F_ID : array[0 .. 100] of integer;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    PushC_Liste : TStringList;


  end;

var
  EditSichFragen: TEditSichFragen;
  SichFragen_Modus : integer;
  Neues_Konto_Erstellt : boolean;



implementation







procedure TEditSichFragen.WebButton1Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  if SichFragen_Modus = 1 then begin
    DataModule1.KundenDataSet.Cancel;
  end;
  Close;
end;

procedure TEditSichFragen.WebButton2Click(Sender: TObject);
var hst,hst2,NLK : String;
begin
  if WebCombobox1.ItemIndex <= 0 then begin
    MessageDlg('Frage 1 muß erfasst werden', mtError, [], nil);
    WebCombobox1.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit1.Text);
  WebEdit1.Text := hst;
  if hst = '' then begin
    MessageDlg('Antwort 1 muß erfasst werden', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;

  if pos(' ',hst) > 0 then begin
    MessageDlg('Antwort 1 muß darf kein Leerzeichen enthalten', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;


  if WebCombobox2.ItemIndex <= 0 then begin
    MessageDlg('Frage 2 muß erfasst werden', mtError, [], nil);
    WebCombobox2.SetFocus;
    exit;
  end;




  hst := Trim(WebEdit2.Text);
  WebEdit2.Text := hst;
  if hst = '' then begin
    MessageDlg('Antwort 2 muß erfasst werden', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;

  if pos(' ',hst) > 0 then begin
    MessageDlg('Antwort 2 muß darf kein Leerzeichen enthalten', mtError, [], nil);
    WebEdit2.SetFocus;
    exit;
  end;



  if WebCombobox1.ItemIndex = WebCombobox2.ItemIndex then begin
    MessageDlg('Sie haben gleiche Frage bei Frage 1 und Frage 2 gewählt', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;

  hst := Trim(WebEdit3.Text);
  WebEdit3.Text := hst;
  if pos(';',hst) > 0 then begin
    MessageDlg('Eigene Frage darf nicht das Zeichen ";" enthalten', mtError, [], nil);
    WebEdit3.SetFocus;
    exit;
  end;
  if hst <> '' then begin
    hst := Trim(WebEdit4.Text);
    WebEdit4.Text := hst;
    if hst = '' then begin
      MessageDlg('Antwort 3 muß erfasst werden, wenn Sie eigen Frage erfasst haben', mtError, [], nil);
      WebEdit4.SetFocus;
      exit;
    end;

    if pos(' ',hst) > 0 then begin
      MessageDlg('Antwort 3 muß darf kein Leerzeichen enthalten', mtError, [], nil);
      WebEdit4.SetFocus;
      exit;
    end;
  end;

  Push_Controls(self,PushC_Liste);



  if SichFragen_Modus = 0 then begin
    NLK := IntToStr(AktFirmaNo);
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.NewUser', [NLK,NeuKD_Email, NeuKD_Kennwort,
            IntToStr(WebCombobox1.ItemIndex),WebEdit1.Text,IntToStr(WebCombobox2.ItemIndex),WebEdit2.Text,
            WebEdit3.Text,WebEdit4.Text],
      procedure(Response: TXDataClientResponse)
      begin
        MessageDlg('Konto erfolgreich angelegt, bitte prüfen Sie ihre Emails, ggf. auch den Spam-Ordner', mtInformation, [], nil);
        Neues_Konto_Erstellt := true;
        Close;
      end,

      procedure(Error: TXDataClientError)
      begin
        Pop_Controls(self,PushC_Liste);
        MessageDlg('Email-Adresse bereits in Verwendung für anderes Kundenkonto oder keine Internet-Verbindung', mtError, [], nil);

      end
     );
  end
  else begin
    try
      DataModule1.KundenDataSet.FieldByName('SF_1').AsInteger := WebCombobox1.ItemIndex;
      DataModule1.KundenDataSet.FieldByName('SF_2').AsInteger := WebCombobox2.ItemIndex;
      DataModule1.KundenDataSet.FieldByName('SFA_1').AsString := WebEdit1.Text;
      DataModule1.KundenDataSet.FieldByName('SFA_2').AsString := WebEdit2.Text;
      DataModule1.KundenDataSet.FieldByName('ESF').AsString := WebEdit3.Text;
      DataModule1.KundenDataSet.FieldByName('SFA_3').AsString := WebEdit4.Text;


      DataModule1.KundenDataSet.Post;
      DataModule1.KundenDataSet.ApplyUpdates;
      Close;
    except
      Pop_Controls(self,PushC_Liste);

      MessageDlg('Fehler beim Speichern', mtError, [], nil);
      exit;
    end;
  end;




end;

procedure TEditSichFragen.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;


end;

procedure TEditSichFragen.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  PushC_Liste := TStringList.Create;


end;

procedure TEditSichFragen.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TEditSichFragen.WebFormShow(Sender: TObject);
var i,j,j2 : integer;
begin
  Resize_Top_Panel_2(self,WebPanel1,'Neues Kundenkonto, Sicherheitsfragen');
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel3,nil,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebComboBox1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel5,WebEdit1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel9,WebComboBox2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel10,WebEdit2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel2,WebEdit3,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel7,WebEdit4,nil);



  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);



  Neues_Konto_Erstellt := false;
  WebComboBox1.Items.Clear;
  WebComboBox1.Items.Add('-----------');
  WebComboBox2.Items.Clear;
  WebComboBox2.Items.Add('-----------');
  for i := 1 to 10 do begin
    if SFA[i] <> '' then begin
      WebComboBox1.Items.Add(SFA[i]);
      WebComboBox2.Items.Add(SFA[i]);
    end;
  end;

  for i := 1 to 100 do begin
    F_ID[i] := 0;
  end;
  i := 0;
  j := -1;
  j2 := -1;


  if SichFragen_Modus = 0 then begin
    WebEdit1.Text := '';
    WebEdit2.Text := '';
    WebEdit3.Text := '';
    WebEdit4.Text := '';
    WebComboBox1.ItemIndex := 0;
    WebComboBox2.ItemIndex := 0;

  end
  else begin
    WebEdit1.Text := DataModule1.KundenDataSet.FieldByName('SFA_1').AsString;
    WebEdit2.Text := DataModule1.KundenDataSet.FieldByName('SFA_2').AsString;
    WebEdit3.Text := DataModule1.KundenDataSet.FieldByName('ESF').AsString;
    WebEdit4.Text := DataModule1.KundenDataSet.FieldByName('SFA_3').AsString;
    WebComboBox1.ItemIndex := DataModule1.KundenDataSet.FieldByName('SF_1').AsInteger;
    WebComboBox2.ItemIndex := DataModule1.KundenDataSet.FieldByName('SF_2').AsInteger;
    DataModule1.KundenDataSet.Edit;
    WebButton2.Caption := 'Speichern';
  end;

end;

procedure TEditSichFragen.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel3 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebComboBox2 := TComboBox.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebComboBox2.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'EditSichFragen';
    Left := 0;
    Top := 0;
    Width := 301;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 99;
    WebLabel3.Width := 222;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Antworten  f'#252'r Kennwort-Restaurierung';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 332;
    WebLabel2.Width := 194;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Eigene Sicherheitsfrage, optional ';
    WebLabel2.Color := clBtnFace;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 140;
    WebLabel4.Width := 128;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Sicherheitsfrage Nr. 1 ';
    WebLabel4.Color := clBtnFace;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.ParentFont := False;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 8;
    WebLabel5.Top := 186;
    WebLabel5.Width := 193;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Antwort auf Sicherheitsfrage Nr. 1';
    WebLabel5.Color := clBtnFace;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 8;
    WebLabel7.Top := 380;
    WebLabel7.Width := 204;
    WebLabel7.Height := 16;
    WebLabel7.Caption := 'Antwort auf eigene Sicherheitsfrage';
    WebLabel7.Color := clBtnFace;
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -13;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [];
    WebLabel7.ParentFont := False;
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 8;
    WebLabel9.Top := 238;
    WebLabel9.Width := 124;
    WebLabel9.Height := 16;
    WebLabel9.Caption := 'Sicherheitsfrage Nr. 2';
    WebLabel9.Color := clBtnFace;
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -13;
    WebLabel9.Font.Name := 'Arial';
    WebLabel9.Font.Style := [];
    WebLabel9.ParentFont := False;
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 8;
    WebLabel10.Top := 286;
    WebLabel10.Width := 193;
    WebLabel10.Height := 16;
    WebLabel10.Caption := 'Antwort auf Sicherheitsfrage Nr. 2';
    WebLabel10.Color := clBtnFace;
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clWindowText;
    WebLabel10.Font.Height := -13;
    WebLabel10.Font.Name := 'Arial';
    WebLabel10.Font.Style := [];
    WebLabel10.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 301;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 9;
    WebEdit1.Top := 208;
    WebEdit1.Width := 269;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 16;
    WebEdit1.Text := 'WebEdit1';
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 305;
    WebEdit2.Width := 269;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 16;
    WebEdit2.Text := 'WebEdit2';
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 8;
    WebEdit3.Top := 354;
    WebEdit3.Width := 270;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 16;
    WebEdit3.Text := 'WebEdit3';
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 8;
    WebEdit4.Top := 402;
    WebEdit4.Width := 269;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 16;
    WebEdit4.Text := 'WebEdit4';
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 8;
    WebComboBox1.Top := 162;
    WebComboBox1.Width := 270;
    WebComboBox1.Height := 22;
    WebComboBox1.ItemIndex := -1;
    WebComboBox2.SetParentComponent(Self);
    WebComboBox2.Name := 'WebComboBox2';
    WebComboBox2.Left := 8;
    WebComboBox2.Top := 258;
    WebComboBox2.Width := 270;
    WebComboBox2.Height := 22;
    WebComboBox2.ItemIndex := -1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 301;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 15;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 8;
    WebButton2.Top := 10;
    WebButton2.Width := 177;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Kunden-Konto erstellen';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 7;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 191;
    WebButton1.Top := 19;
    WebButton1.Width := 86;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.TabOrder := 8;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebComboBox2.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.