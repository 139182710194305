unit UWochenplan;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  UDatamodule,
  UGlobvar;

type
  TWochenplan = class(TForm)
    WebPanel1: TPanel;
    WebLabel4: TLabel;
    WebComboBox1: TComboBox;
    WebLabel5: TLabel;
    WebComboBox2: TComboBox;
    WebLabel6: TLabel;
    WebComboBox3: TComboBox;
    WebLabel7: TLabel;
    WebComboBox4: TComboBox;
    WebLabel8: TLabel;
    WebComboBox5: TComboBox;
    WebLabel9: TLabel;
    WebComboBox6: TComboBox;
    WebLabel_10: TLabel;
    WebComboBox7: TComboBox;
    WebPanel2: TPanel;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    A_ID : array[1 .. 100] of integer;
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    PushC_Liste : TStringList;

  end;

var
  Wochenplan: TWochenplan;

implementation


procedure TWochenplan.WebButton1Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  Close;
end;



procedure TWochenplan.WebButton2Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  try
    Datamodule1.TeilnehmerDataSet.Edit;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan1').AsInteger := WebComboBox1.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan2').AsInteger := WebComboBox2.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan3').AsInteger := WebComboBox3.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan4').AsInteger := WebComboBox4.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan5').AsInteger := WebComboBox5.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan6').AsInteger := WebComboBox6.ItemIndex;
    Datamodule1.TeilnehmerDataSet.FieldByName('Plan7').AsInteger := WebComboBox7.ItemIndex;
    DataModule1.TeilnehmerDataSet.Post;
    DataModule1.TeilnehmerDataSet.ApplyUpdates;
  except
    Pop_Controls(self,PushC_Liste);

    MessageDlg('Fehler beim Speichern', mtError, [], nil);
    exit;
  end;
  Close;

end;

procedure TWochenplan.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;


  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;

end;

procedure TWochenplan.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  PushC_Liste := TStringList.Create;


end;





procedure TWochenplan.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TWochenplan.WebFormShow(Sender: TObject);
var i,
    j1,j2,j3,j4,j5,j6,j7 : integer;

begin

  Resize_Top_Panel_3(self,WebPanel1,'Akt. Benutzer: '+ AktKDName,'Wochenplan für '+AktTeilnehmerName);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel4,WebComboBox1,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel5,WebComboBox2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel6,WebComboBox3,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel7,WebComboBox4,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel8,WebComboBox5,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel9,WebComboBox6,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel_10,WebComboBox7,nil);
  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);





  WebComboBox1.Items.Clear;
  WebComboBox1.Items.Add('----------------');
  WebComboBox2.Items.Clear;
  WebComboBox2.Items.Add('----------------');
  WebComboBox3.Items.Clear;
  WebComboBox3.Items.Add('----------------');
  WebComboBox4.Items.Clear;
  WebComboBox4.Items.Add('----------------');
  WebComboBox5.Items.Clear;
  WebComboBox5.Items.Add('----------------');
  WebComboBox6.Items.Clear;
  WebComboBox6.Items.Add('----------------');
  WebComboBox7.Items.Clear;
  WebComboBox7.Items.Add('----------------');


  for i := 1 to 100 do begin
    A_ID[i] := 0;
  end;
  i := 0;
  j1 := -1;
  j2 := -1;
  j3 := -1;
  j4 := -1;
  j5 := -1;
  j6 := -1;
  j7 := -1;

  while not Datamodule1.VerpflegungsArtDataSet.Eof do begin
    i := i + 1;
    A_ID[i] := Datamodule1.VerpflegungsArtDataSet.FieldByName('ID').AsInteger;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan1').AsInteger then j1 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan2').AsInteger then j2 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan3').AsInteger then j3 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan4').AsInteger then j4 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan5').AsInteger then j5 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan6').AsInteger then j6 := i;
    if A_ID[i] = Datamodule1.TeilnehmerDataSet.FieldByName('Plan7').AsInteger then j7 := i;
    WebComboBox1.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox2.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox3.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox4.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox5.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox6.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    WebComboBox7.Items.Add(Datamodule1.VerpflegungsArtDataSet.FieldByName('Bezeichnung').AsString);
    Datamodule1.VerpflegungsArtDataSet.Next;
  end;
  if j1 <> -1 then begin
    WebComboBox1.ItemIndex := j1;
  end;
  if j2 <> -1 then begin
    WebComboBox2.ItemIndex := j2;
  end;
  if j3 <> -1 then begin
    WebComboBox3.ItemIndex := j3;
  end;
  if j4 <> -1 then begin
    WebComboBox4.ItemIndex := j4;
  end;
  if j5 <> -1 then begin
    WebComboBox5.ItemIndex := j5;
  end;
  if j6 <> -1 then begin
    WebComboBox6.ItemIndex := j6;
  end;
  if j7 <> -1 then begin
    WebComboBox7.ItemIndex := j7;
  end;
end;

procedure TWochenplan.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel_10 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebComboBox2 := TComboBox.Create(Self);
  WebComboBox3 := TComboBox.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebComboBox5 := TComboBox.Create(Self);
  WebComboBox6 := TComboBox.Create(Self);
  WebComboBox7 := TComboBox.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel_10.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebComboBox2.BeforeLoadDFMValues;
  WebComboBox3.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebComboBox5.BeforeLoadDFMValues;
  WebComboBox6.BeforeLoadDFMValues;
  WebComboBox7.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'Wochenplan';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 16;
    WebLabel4.Top := 80;
    WebLabel4.Width := 35;
    WebLabel4.Height := 14;
    WebLabel4.Caption := 'Montag';
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 16;
    WebLabel5.Top := 126;
    WebLabel5.Width := 42;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'Dienstag';
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 16;
    WebLabel6.Top := 172;
    WebLabel6.Width := 44;
    WebLabel6.Height := 14;
    WebLabel6.Caption := 'Mittwoch';
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 16;
    WebLabel7.Top := 218;
    WebLabel7.Width := 56;
    WebLabel7.Height := 14;
    WebLabel7.Caption := 'Donnerstag';
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 16;
    WebLabel8.Top := 264;
    WebLabel8.Width := 33;
    WebLabel8.Height := 14;
    WebLabel8.Caption := 'Freitag';
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 16;
    WebLabel9.Top := 310;
    WebLabel9.Width := 42;
    WebLabel9.Height := 14;
    WebLabel9.Caption := 'Samstag';
    WebLabel_10.SetParentComponent(Self);
    WebLabel_10.Name := 'WebLabel_10';
    WebLabel_10.Left := 16;
    WebLabel_10.Top := 356;
    WebLabel_10.Width := 40;
    WebLabel_10.Height := 14;
    WebLabel_10.Caption := 'Sonntag';
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 73;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 16;
    WebComboBox1.Top := 103;
    WebComboBox1.Width := 247;
    WebComboBox1.Height := 22;
    WebComboBox1.Text := '--';
    WebComboBox1.ItemIndex := 0;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('--');
      WebComboBox1.Items.Add('Januar');
      WebComboBox1.Items.Add('Februar');
      WebComboBox1.Items.Add('M'#228'rz');
      WebComboBox1.Items.Add('April');
      WebComboBox1.Items.Add('Mai');
      WebComboBox1.Items.Add('Juni');
      WebComboBox1.Items.Add('Juli');
      WebComboBox1.Items.Add('August');
      WebComboBox1.Items.Add('September');
      WebComboBox1.Items.Add('Oktober');
      WebComboBox1.Items.Add('November');
      WebComboBox1.Items.Add('Dezember');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    WebComboBox2.SetParentComponent(Self);
    WebComboBox2.Name := 'WebComboBox2';
    WebComboBox2.Left := 16;
    WebComboBox2.Top := 149;
    WebComboBox2.Width := 247;
    WebComboBox2.Height := 22;
    WebComboBox2.Text := '--';
    WebComboBox2.ItemIndex := 0;
    WebComboBox2.Items.BeginUpdate;
    try
      WebComboBox2.Items.Clear;
      WebComboBox2.Items.Add('--');
      WebComboBox2.Items.Add('Januar');
      WebComboBox2.Items.Add('Februar');
      WebComboBox2.Items.Add('M'#228'rz');
      WebComboBox2.Items.Add('April');
      WebComboBox2.Items.Add('Mai');
      WebComboBox2.Items.Add('Juni');
      WebComboBox2.Items.Add('Juli');
      WebComboBox2.Items.Add('August');
      WebComboBox2.Items.Add('September');
      WebComboBox2.Items.Add('Oktober');
      WebComboBox2.Items.Add('November');
      WebComboBox2.Items.Add('Dezember');
    finally
      WebComboBox2.Items.EndUpdate;
    end;
    WebComboBox3.SetParentComponent(Self);
    WebComboBox3.Name := 'WebComboBox3';
    WebComboBox3.Left := 16;
    WebComboBox3.Top := 195;
    WebComboBox3.Width := 247;
    WebComboBox3.Height := 22;
    WebComboBox3.Text := '--';
    WebComboBox3.ItemIndex := 0;
    WebComboBox3.Items.BeginUpdate;
    try
      WebComboBox3.Items.Clear;
      WebComboBox3.Items.Add('--');
      WebComboBox3.Items.Add('Januar');
      WebComboBox3.Items.Add('Februar');
      WebComboBox3.Items.Add('M'#228'rz');
      WebComboBox3.Items.Add('April');
      WebComboBox3.Items.Add('Mai');
      WebComboBox3.Items.Add('Juni');
      WebComboBox3.Items.Add('Juli');
      WebComboBox3.Items.Add('August');
      WebComboBox3.Items.Add('September');
      WebComboBox3.Items.Add('Oktober');
      WebComboBox3.Items.Add('November');
      WebComboBox3.Items.Add('Dezember');
    finally
      WebComboBox3.Items.EndUpdate;
    end;
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 16;
    WebComboBox4.Top := 241;
    WebComboBox4.Width := 247;
    WebComboBox4.Height := 22;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
    WebComboBox5.SetParentComponent(Self);
    WebComboBox5.Name := 'WebComboBox5';
    WebComboBox5.Left := 16;
    WebComboBox5.Top := 287;
    WebComboBox5.Width := 247;
    WebComboBox5.Height := 22;
    WebComboBox5.Text := '--';
    WebComboBox5.ItemIndex := 0;
    WebComboBox5.Items.BeginUpdate;
    try
      WebComboBox5.Items.Clear;
      WebComboBox5.Items.Add('--');
      WebComboBox5.Items.Add('Januar');
      WebComboBox5.Items.Add('Februar');
      WebComboBox5.Items.Add('M'#228'rz');
      WebComboBox5.Items.Add('April');
      WebComboBox5.Items.Add('Mai');
      WebComboBox5.Items.Add('Juni');
      WebComboBox5.Items.Add('Juli');
      WebComboBox5.Items.Add('August');
      WebComboBox5.Items.Add('September');
      WebComboBox5.Items.Add('Oktober');
      WebComboBox5.Items.Add('November');
      WebComboBox5.Items.Add('Dezember');
    finally
      WebComboBox5.Items.EndUpdate;
    end;
    WebComboBox6.SetParentComponent(Self);
    WebComboBox6.Name := 'WebComboBox6';
    WebComboBox6.Left := 16;
    WebComboBox6.Top := 333;
    WebComboBox6.Width := 247;
    WebComboBox6.Height := 22;
    WebComboBox6.Text := '--';
    WebComboBox6.ItemIndex := 0;
    WebComboBox6.Items.BeginUpdate;
    try
      WebComboBox6.Items.Clear;
      WebComboBox6.Items.Add('--');
      WebComboBox6.Items.Add('Januar');
      WebComboBox6.Items.Add('Februar');
      WebComboBox6.Items.Add('M'#228'rz');
      WebComboBox6.Items.Add('April');
      WebComboBox6.Items.Add('Mai');
      WebComboBox6.Items.Add('Juni');
      WebComboBox6.Items.Add('Juli');
      WebComboBox6.Items.Add('August');
      WebComboBox6.Items.Add('September');
      WebComboBox6.Items.Add('Oktober');
      WebComboBox6.Items.Add('November');
      WebComboBox6.Items.Add('Dezember');
    finally
      WebComboBox6.Items.EndUpdate;
    end;
    WebComboBox7.SetParentComponent(Self);
    WebComboBox7.Name := 'WebComboBox7';
    WebComboBox7.Left := 16;
    WebComboBox7.Top := 380;
    WebComboBox7.Width := 247;
    WebComboBox7.Height := 22;
    WebComboBox7.Text := '--';
    WebComboBox7.ItemIndex := 0;
    WebComboBox7.Items.BeginUpdate;
    try
      WebComboBox7.Items.Clear;
      WebComboBox7.Items.Add('--');
      WebComboBox7.Items.Add('Januar');
      WebComboBox7.Items.Add('Februar');
      WebComboBox7.Items.Add('M'#228'rz');
      WebComboBox7.Items.Add('April');
      WebComboBox7.Items.Add('Mai');
      WebComboBox7.Items.Add('Juni');
      WebComboBox7.Items.Add('Juli');
      WebComboBox7.Items.Add('August');
      WebComboBox7.Items.Add('September');
      WebComboBox7.Items.Add('Oktober');
      WebComboBox7.Items.Add('November');
      WebComboBox7.Items.Add('Dezember');
    finally
      WebComboBox7.Items.EndUpdate;
    end;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 420;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 17;
    WebButton2.SetParentComponent(WebPanel2);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 3;
    WebButton2.Top := 19;
    WebButton2.Width := 158;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Speichern';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.TabOrder := 40;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 167;
    WebButton1.Top := 16;
    WebButton1.Width := 96;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.TabOrder := 30;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel_10.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebComboBox2.AfterLoadDFMValues;
    WebComboBox3.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebComboBox5.AfterLoadDFMValues;
    WebComboBox6.AfterLoadDFMValues;
    WebComboBox7.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.