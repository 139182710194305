unit UWaitConnect;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  UDatamodule,
  UGlobvar,
  UAnzSpeiseplan,
  UGetAuswahl,
  UKontoMenu,
  UConfirmEmail, WEBLib.ExtCtrls, WEBLib.ComCtrls;

type
  TWaitConnect = class(TForm)
    WebPanel1: TPanel;
    WebProgressBar1: TProgressBar;
    WebTimer1: TTimer;
    WConnect1Label1: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);

  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Form_Aktiv : boolean;

  end;

var
  WaitConnect: TWaitConnect;



// procedure Zeige_Speiseplan(DataSet: TDataSet);


implementation


procedure TWaitConnect.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  WebTimer1.Enabled := false;
  WebProgressBar1.Position := 0;
end;

procedure TWaitConnect.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

end;

procedure TWaitConnect.WebFormShow(Sender: TObject);
var b : boolean;
begin
  Mit_Hilfe_Button := false;
  Resize_Top_Panel_2(self,WebPanel1,'');
  Mit_Hilfe_Button := true;
  Form_Aktiv := true;
  WebProgressBar1.Position := 0;
  WebTimer1.Enabled := true;
end;

procedure TWaitConnect.WebTimer1Timer(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  WebTimer1.Enabled := false;

  if WebProgressBar1.Position > 95 then begin
    WebProgressBar1.Position := 0;
  end;
  WebProgressBar1.Position := WebProgressBar1.Position + 1;
  WebTimer1.Enabled := true;
end;

procedure TWaitConnect.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WConnect1Label1 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebProgressBar1 := TProgressBar.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  WConnect1Label1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebProgressBar1.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'WaitConnect';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WConnect1Label1.SetParentComponent(Self);
    WConnect1Label1.Name := 'WConnect1Label1';
    WConnect1Label1.Left := 8;
    WConnect1Label1.Top := 152;
    WConnect1Label1.Width := 278;
    WConnect1Label1.Height := 18;
    WConnect1Label1.Caption := 'Warten auf Verbindung zum Auth-Server ';
    WConnect1Label1.Font.Charset := DEFAULT_CHARSET;
    WConnect1Label1.Font.Color := clWindowText;
    WConnect1Label1.Font.Height := -16;
    WConnect1Label1.Font.Name := 'Arial';
    WConnect1Label1.Font.Style := [];
    WConnect1Label1.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebProgressBar1.SetParentComponent(Self);
    WebProgressBar1.Name := 'WebProgressBar1';
    WebProgressBar1.Left := 8;
    WebProgressBar1.Top := 200;
    WebProgressBar1.Width := 289;
    WebProgressBar1.Height := 24;
    WebProgressBar1.ChildOrder := 1;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 500;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 224;
    WebTimer1.Top := 32;
  finally
    WConnect1Label1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebProgressBar1.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.