unit UTeilnMenu;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,

  UDatamodule,
  UGlobvar,
  WEBLib.ExtCtrls;

type
  TTeilnMenu = class(TForm)
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton6: TButton;
    WebPanel1: TPanel;
    WebButton9: TButton;
    WebPanel2: TPanel;
    procedure WebButton7Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebButton9Click(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

    PushC_Liste : TStringList;

  end;

var
  TeilnMenu: TTeilnMenu;




implementation




procedure TTeilnMenu.WebButton1Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 8;
  Close;
end;

procedure TTeilnMenu.WebButton2Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 2;
  Close;

end;

procedure TTeilnMenu.WebButton3Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 3;
  Close;

end;

procedure TTeilnMenu.WebButton4Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 4;
  Close;

end;

procedure TTeilnMenu.WebButton5Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 5;
  Close;

end;

procedure TTeilnMenu.WebButton6Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 1;
  Close;

end;

procedure TTeilnMenu.WebButton7Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := -1;
  Close;
end;

procedure TTeilnMenu.WebButton8Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 6;
  Close;

end;

procedure TTeilnMenu.WebButton9Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);

  TeilnehmerMenuAuswahl := 7;
  Close;

end;

procedure TTeilnMenu.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;


  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;

end;

procedure TTeilnMenu.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  PushC_Liste := TStringList.Create;

end;

procedure TTeilnMenu.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TTeilnMenu.WebFormShow(Sender: TObject);
begin

  Resize_Top_Panel_3(self,WebPanel1,'Akt. Benutzer: '+ AktKDName,'Akt. Teilnehmer: '+AktTeilnehmerName);
  Resize_Bottom_Panel_2(self,WebPanel2);

  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton6);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton2);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton3);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton4);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton5);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton8);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton9);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton1);
  Zeilen_Liste_Add_Menu(Zeilen_Liste,Zeilen_Count,WebButton7);
  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Menu,self,Zeilen_Liste,WebPanel1,WebPanel2);
end;


procedure TTeilnMenu.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);

  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  try
    Name := 'TeilnMenu';
    Left := 0;
    Top := 0;
    Width := 315;
    Height := 560;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 32;
    WebButton1.Top := 429;
    WebButton1.Width := 273;
    WebButton1.Height := 48;
    WebButton1.Caption := 'Teilnehmer l'#246'schen';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 32;
    WebButton2.Top := 123;
    WebButton2.Width := 273;
    WebButton2.Height := 48;
    WebButton2.Caption := 'Einrichtung '#228'ndern';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn-primary';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 32;
    WebButton3.Top := 174;
    WebButton3.Width := 273;
    WebButton3.Height := 48;
    WebButton3.Caption := 'Aktivieren';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn btn-primary';
    WebButton3.Enabled := False;
    WebButton3.Font.Charset := DEFAULT_CHARSET;
    WebButton3.Font.Color := clWindowText;
    WebButton3.Font.Height := -19;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Style := [];
    WebButton3.ParentFont := False;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 32;
    WebButton4.Top := 225;
    WebButton4.Width := 273;
    WebButton4.Height := 48;
    WebButton4.Caption := 'Deaktivieren';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn btn-primary';
    WebButton4.Enabled := False;
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -19;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.ParentFont := False;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(Self);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 32;
    WebButton5.Top := 276;
    WebButton5.Width := 273;
    WebButton5.Height := 48;
    WebButton5.Caption := 'Allergien';
    WebButton5.ChildOrder := 4;
    WebButton5.ElementClassName := 'btn-primary';
    WebButton5.Enabled := False;
    WebButton5.Font.Charset := DEFAULT_CHARSET;
    WebButton5.Font.Color := clWindowText;
    WebButton5.Font.Height := -19;
    WebButton5.Font.Name := 'Arial';
    WebButton5.Font.Style := [];
    WebButton5.ParentFont := False;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 32;
    WebButton7.Top := 483;
    WebButton7.Width := 273;
    WebButton7.Height := 48;
    WebButton7.Caption := 'Zur'#252'ck';
    WebButton7.ChildOrder := 5;
    WebButton7.ElementClassName := 'btn-primary';
    WebButton7.Font.Charset := DEFAULT_CHARSET;
    WebButton7.Font.Color := clWindowText;
    WebButton7.Font.Height := -19;
    WebButton7.Font.Name := 'Arial';
    WebButton7.Font.Style := [];
    WebButton7.ParentFont := False;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton8.SetParentComponent(Self);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 32;
    WebButton8.Top := 327;
    WebButton8.Width := 273;
    WebButton8.Height := 48;
    WebButton8.Caption := 'Wochenplan';
    WebButton8.ChildOrder := 5;
    WebButton8.ElementClassName := 'btn-primary';
    WebButton8.Enabled := False;
    WebButton8.Font.Charset := DEFAULT_CHARSET;
    WebButton8.Font.Color := clWindowText;
    WebButton8.Font.Height := -19;
    WebButton8.Font.Name := 'Arial';
    WebButton8.Font.Style := [];
    WebButton8.ParentFont := False;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 32;
    WebButton6.Top := 73;
    WebButton6.Width := 273;
    WebButton6.Height := 48;
    WebButton6.Caption := 'Teilnehmerdaten '#228'ndern';
    WebButton6.ChildOrder := 3;
    WebButton6.ElementClassName := 'btn-primary';
    WebButton6.Font.Charset := DEFAULT_CHARSET;
    WebButton6.Font.Color := clWindowText;
    WebButton6.Font.Height := -19;
    WebButton6.Font.Name := 'Arial';
    WebButton6.Font.Style := [];
    WebButton6.ParentFont := False;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 315;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebButton9.SetParentComponent(Self);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 32;
    WebButton9.Top := 378;
    WebButton9.Width := 273;
    WebButton9.Height := 48;
    WebButton9.Caption := 'Unterkonto f'#252'r Teilnehmer';
    WebButton9.ChildOrder := 5;
    WebButton9.ElementClassName := 'btn-primary';
    WebButton9.Enabled := False;
    WebButton9.Font.Charset := DEFAULT_CHARSET;
    WebButton9.Font.Color := clWindowText;
    WebButton9.Font.Height := -19;
    WebButton9.Font.Name := 'Arial';
    WebButton9.Font.Style := [];
    WebButton9.ParentFont := False;
    SetEvent(WebButton9, Self, 'OnClick', 'WebButton9Click');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 528;
    WebPanel2.Width := 315;
    WebPanel2.Height := 32;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 10;
  finally
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
  end;
end;

end.
