unit UAnzArtikel;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  UDatamodule,
  UGlobvar, WEBLib.ExtCtrls, WEBLib.ComCtrls, WEBLib.Buttons, WEBLib.WebCtrls;

type
  TAnzArtikel = class(TForm)
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebButton1: TButton;
    WebBrowserControl1: TBrowserControl;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    PushC_Liste : TStringList;


  end;

var
  AnzArtikel: TAnzArtikel;

implementation


procedure TAnzArtikel.WebButton1Click(Sender: TObject);
begin
  Push_Controls(self,PushC_Liste);
  Close;

end;

procedure TAnzArtikel.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;


end;

procedure TAnzArtikel.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
  PushC_Liste := TStringList.Create;


end;

procedure TAnzArtikel.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TAnzArtikel.WebFormShow(Sender: TObject);

var
  xhr: TJSXmlHttpRequest;


 procedure _Load;
  begin
//    WebRichEdit1.PlainText := true;
//    WebRichEdit1.Lines.Text := xhr.responseText;
//    WebHTMLDIV1.HTML.Text := xhr.responseText;
      WebBrowserControl1.URL := '.\images\Dokument.html';
  end;


begin
  Mit_Hilfe_Button := false;
  Resize_Top_Panel_2(self,WebPanel1,'Artikel: ' + DataModule1.ArtikelDataSet.Fieldbyname('BezGesamt').AsString);
  Mit_Hilfe_Button := true;
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;



  Form_Aktiv := true;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);



  xhr := TJSXMLHttpRequest.new;
  xhr.open('GET',
    DataModule1.XDataWebConnection1.URL + '/' +
    string(TJSObject(DataModule1.Artikel_LTDataSet.CurrentData)['TXT@xdata.proxy'])
  );
//  xhr.overrideMimeType('text/plain; charset=utf-16');
  xhr.addEventListener('load', @_Load);
  xhr.send;
end;



procedure TAnzArtikel.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebBrowserControl1 := TBrowserControl.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebBrowserControl1.BeforeLoadDFMValues;
  try
    Name := 'AnzArtikel';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 439;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 129;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 379;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 175;
    WebButton1.Top := 8;
    WebButton1.Width := 90;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebBrowserControl1.SetParentComponent(Self);
    WebBrowserControl1.Name := 'WebBrowserControl1';
    WebBrowserControl1.Left := 0;
    WebBrowserControl1.Top := 129;
    WebBrowserControl1.Width := 640;
    WebBrowserControl1.Height := 250;
    WebBrowserControl1.Align := alClient;
    WebBrowserControl1.ChildOrder := 3;
    WebBrowserControl1.Sandbox := [];
  finally
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebBrowserControl1.AfterLoadDFMValues;
  end;
end;

end.