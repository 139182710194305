unit UVW_Einrichtungen;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  UDatamodule,
  UGlobvar, WEBLib.Grids, WEBLib.DBCtrls,
  UEssenausgabe;

type
  TVW_Einrichtungen = class(TForm)
    WebPanel1: TPanel;
    WebLabel5: TLabel;
    WebComboBox4: TComboBox;
    WebPanel2: TPanel;
    WebButton7: TButton;
    WebButton1: TButton;

    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton7Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;
  public
    { Public-Deklarationen }
    E_No,
    E_ID : array[1 .. 100] of integer;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;


  end;

var
  VW_Einrichtungen: TVW_Einrichtungen;

implementation


procedure TVW_Einrichtungen.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TVW_Einrichtungen.WebButton7Click(Sender: TObject);
var Newform: TEssensausgabe;

begin
  if WebComboBox4.ItemIndex = 0 then begin
    MessageDlg('Einrichtung/Schule muß erfasst werden', mtError, [], nil);
    WebComboBox4.SetFocus;
    exit;
  end;
  AktEinrichtungID := E_ID[WebComboBox4.ItemIndex];
  AktEinrichtung := E_No[WebComboBox4.ItemIndex];
  AktEinrichtungName := WebComboBox4.Text;
  Newform := TEssensausgabe.CreateNew(procedure(AForm: TObject)
      begin
        (AForm as TEssensausgabe).Caption := 'Essensausgabe';
      end
    );
    Newform.Border := fbSingle;

    Newform.ShowModal(procedure(AValue: TModalResult)
    begin
    end
    );




end;

procedure TVW_Einrichtungen.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TVW_Einrichtungen.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;
end;

procedure TVW_Einrichtungen.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;

  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TVW_Einrichtungen.WebFormShow(Sender: TObject);
var i,j : integer;
begin
  Resize_Top_Panel(self,WebPanel1);
  Resize_Bottom_Panel(self,WebPanel2);
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel5,WebComboBox4,nil);
  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);



  Datamodule1.EinrichtungDataSet.First;
  WebComboBox4.Items.Clear;
  WebComboBox4.Items.Add('-----------');
  for i := 1 to 100 do begin
    E_ID[i] := 0;
    E_No[i] := 0;
  end;
  i := 0;
  while not Datamodule1.EinrichtungDataSet.Eof do begin
    i := i + 1;
    E_ID[i] := Datamodule1.EinrichtungDataSet.FieldByName('ID').AsInteger;
    E_No[i] := Datamodule1.EinrichtungDataSet.FieldByName('Nummer').AsInteger;
    WebComboBox4.Items.Add(Datamodule1.EinrichtungDataSet.FieldByName('Bezeichnung').AsString);
    Datamodule1.EinrichtungDataSet.Next;
  end;
  WebComboBox4.ItemIndex := 0;

end;

procedure TVW_Einrichtungen.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel5 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebLabel5.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'VW_Einrichtungen';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 323;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 8;
    WebLabel5.Top := 111;
    WebLabel5.Width := 109;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Einrichtung/Schule';
    WebLabel5.Color := clBtnFace;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 89;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebComboBox4.SetParentComponent(Self);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 9;
    WebComboBox4.Top := 133;
    WebComboBox4.Width := 247;
    WebComboBox4.Height := 22;
    WebComboBox4.Text := '--';
    WebComboBox4.ItemIndex := 0;
    WebComboBox4.Items.BeginUpdate;
    try
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add('--');
      WebComboBox4.Items.Add('Januar');
      WebComboBox4.Items.Add('Februar');
      WebComboBox4.Items.Add('M'#228'rz');
      WebComboBox4.Items.Add('April');
      WebComboBox4.Items.Add('Mai');
      WebComboBox4.Items.Add('Juni');
      WebComboBox4.Items.Add('Juli');
      WebComboBox4.Items.Add('August');
      WebComboBox4.Items.Add('September');
      WebComboBox4.Items.Add('Oktober');
      WebComboBox4.Items.Add('November');
      WebComboBox4.Items.Add('Dezember');
    finally
      WebComboBox4.Items.EndUpdate;
    end;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 263;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 3;
    WebButton7.SetParentComponent(WebPanel2);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 11;
    WebButton7.Top := 14;
    WebButton7.Width := 113;
    WebButton7.Height := 39;
    WebButton7.Caption := 'Weiter';
    WebButton7.ChildOrder := 4;
    WebButton7.ElementClassName := 'btn-primary';
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 160;
    WebButton1.Top := 14;
    WebButton1.Width := 153;
    WebButton1.Height := 43;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebLabel5.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.
