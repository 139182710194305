unit UStartseite;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Crypto,
  WEBLib.Storage,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.DB,

  WEBLib.ExtCtrls,
  UDataModule, WEBLib.DBCtrls,
  Types,
  UGlobvar,
  ULogin,
  WEBLib.Imaging.pngimage;



type



  TStartseite = class(TForm)
    WebPanel1: TPanel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel9: TLabel;
    WebLabel8: TLabel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private-Deklarationen }
  protected
    procedure LoadDFMValues; override;

  public
    { Public-Deklarationen
    class function DoLogin(LoginProc, CreateAccountProc: TLoginProc): Boolean;
    property OnLogin: TLoginProc read FOnLogin write FOnLogin;
    property OnCreateAccount: TLoginProc read FOnCreateAccount write FOnCreateAccount;
}

    Startseite_WebImageControl1: TImageControl;
    WebButton1: TButton;
    L_Aufgerufen : boolean;
    Save_PW : String;

    PushC_Liste : TStringList;

    procedure MessageDecrypted(AData: string);

  end;

var
  Startseite: TStartseite;

implementation




procedure TStartseite.WebButton1Click(Sender: TObject);
begin
  if L_Aufgerufen then exit;
  Push_Controls(self,PushC_Liste);
  L_Aufgerufen := true;
  Akt_JWToken := '';
    LoginForm := TLoginForm.CreateNew(procedure(AForm: TObject)
      begin
      end
    );
    LoginForm.Border := fbSingle;

    LoginForm.ShowModal(procedure(AValue: TModalResult)
    begin
      L_Aufgerufen := false;
      Pop_Controls(self,PushC_Liste);
    end
    );
end;

procedure TStartseite.WebFormClose(Sender: TObject; var Action: TCloseAction);
var i : integer;
begin
  Caption_List1.Clear;
  Caption_List1.Free;
  Caption_List1 := nil;
  Caption_List2.Clear;
  Caption_List2.Free;
  Caption_List2 := nil;

  Pop_Controls(self,PushC_Liste);
  PushC_Liste.Clear;
  PushC_Liste.Free;
  PushC_Liste := nil;
  for i := 0 to PushControl_Liste.Count - 1 do begin
    dispose(PPushControl_Stack_Rec(PushControl_Liste[i]));
  end;
  PushControl_Liste.Clear;
  PushControl_Liste.Free;
  PushControl_Liste := nil;

end;

procedure TStartseite.WebFormCreate(Sender: TObject);
begin
  Application.OnImageCacheReady := WebFormShow;
  Akt_JWToken := '';
  L_Aufgerufen := false;
  PushC_Liste := TStringList.Create;




//  Startseite_WebImageControl1 := nil;
//  WebButton1 := nil;
end;

procedure TStartseite.WebFormShow(Sender: TObject);
var hst : String;
    LS : TLocalStorage;
    ab: TJSArrayBuffer;
begin
  if Startseite_WebImageControl1 = nil then begin
    Startseite_WebImageControl1 := TImageControl.Create(self);
    Startseite_WebImageControl1.Name := 'Startseite_WebImageControl1';
    Startseite_WebImageControl1.Parent := self;
    Startseite_WebImageControl1.Top := 8;
    Startseite_WebImageControl1.Left := 8;
    Startseite_WebImageControl1.Width := 305;
    Startseite_WebImageControl1.Height := 185;
    StartSeite_WebImageControl1.Url := LogoURL; // 'images/haustein2.bmp';
    WebButton1 := TButton.Create(self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Parent := self;
    WebButton1.Top := 280;
    WebButton1.Left := 80;
    WebButton1.Width := 120;
    WebButton1.Height := 55;
    WebButton1.Onclick := WebButton1Click;
    WebButton1.Visible := false;
    WebButton1.Caption := 'Anmelden';
    WebButton1.Font.Size := 12;
    Datamodule1.CD_Ergebnis := '';
    WebLabel8.Onclick := Datamodule1.DSE_Click;
    WebLabel2.Onclick := Datamodule1.AGB_Click;
    WebLabel1.Onclick := Datamodule1.Impressum_Click;

    Akt_JWToken := '';
    Resize_Top_Panel_6(self,StartSeite_WebImageControl1);
    Datamodule1.aesGCM.OnEncrypted := nil;// MessageEncrypted;
    Datamodule1.aesGCM.OnDecryptedString := MessageDecrypted;
    try
      LS := TLocalStorage.Create;
  asm debugger; end;
      Save_PW := LS.getValue(cStoragePW);
    finally
      LS.Free;
    end;
  asm debugger; end;
    if (Save_PW <> '') then begin
      ab := BinaryStringToAB(Save_PW);
      Datamodule1.aesGCM.Decrypt(ab, drtString);
    end
    else begin
      Datamodule1.CD_Ergebnis := '';
      WebButton1.Visible := true;
    end;
  end;

end;

procedure TStartseite.MessageDecrypted(AData: string);
begin
  Datamodule1.CD_Ergebnis := AData;
  WebButton1.Visible := true;
end;



procedure TStartseite.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  try
    Name := 'Startseite';
    Left := 0;
    Top := 0;
    Width := 331;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 320;
    WebPanel1.Width := 331;
    WebPanel1.Height := 160;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 8;
    WebLabel4.SetParentComponent(WebPanel1);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 9;
    WebLabel4.Top := 6;
    WebLabel4.Width := 281;
    WebLabel4.Height := 14;
    WebLabel4.Caption := 'Um unsere Webseite f'#252'r Sie optimal zu gestalten und fort- ';
    WebLabel5.SetParentComponent(WebPanel1);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 9;
    WebLabel5.Top := 21;
    WebLabel5.Width := 276;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'laufend verbessern zu k'#246'nnen, verwenden wir Cookies. ';
    WebLabel6.SetParentComponent(WebPanel1);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 9;
    WebLabel6.Top := 36;
    WebLabel6.Width := 275;
    WebLabel6.Height := 14;
    WebLabel6.Caption := 'Durch die weitere Nutzung der Webseite stimmen Sie der';
    WebLabel7.SetParentComponent(WebPanel1);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 9;
    WebLabel7.Top := 50;
    WebLabel7.Width := 269;
    WebLabel7.Height := 14;
    WebLabel7.Caption := 'Verwendung von Cookies zu. Weitere Informationen zu ';
    WebLabel9.SetParentComponent(WebPanel1);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 9;
    WebLabel9.Top := 65;
    WebLabel9.Width := 153;
    WebLabel9.Height := 14;
    WebLabel9.Caption := 'Cookies erhalten Sie in unserer ';
    WebLabel8.SetParentComponent(WebPanel1);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 168;
    WebLabel8.Top := 65;
    WebLabel8.Width := 125;
    WebLabel8.Height := 14;
    WebLabel8.Caption := 'Datenschutzerkl'#228'rung.';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clBlue;
    WebLabel8.Font.Height := -11;
    WebLabel8.Font.Name := 'Arial';
    WebLabel8.Font.Style := [fsBold,fsUnderline];
    WebLabel8.ParentFont := False;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 97;
    WebLabel1.Top := 105;
    WebLabel1.Width := 65;
    WebLabel1.Height := 14;
    WebLabel1.Caption := 'Impressum';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clBlue;
    WebLabel1.Font.Height := -11;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold,fsUnderline];
    WebLabel1.ParentFont := False;
    WebLabel2.SetParentComponent(WebPanel1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 25;
    WebLabel2.Top := 85;
    WebLabel2.Width := 229;
    WebLabel2.Height := 14;
    WebLabel2.Caption := 'Allgemeine Gesch'#228'ftsbedingungen (AGB)';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clBlue;
    WebLabel2.Font.Height := -11;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [fsBold,fsUnderline];
    WebLabel2.ParentFont := False;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
  end;
end;

end.
